import { ProgressBar } from 'primereact/progressbar';
import './Progress.scss';
import React from 'react';
import { getProgressTruncatedValue } from '../ResponsiveTable/tableUtils';
import { classNames } from 'primereact/utils';

export type ProgressColor = 'primary' | 'success' | 'error' | 'warning' | null;

interface Props {
  value: number | null;
  showValue?: boolean;
  truncate?: boolean;
  color?: ProgressColor;
}

export const Progress = ({ value, color = 'primary', showValue = false, truncate = false }: Props) => {
  if (value === null || isNaN(value)) return null;

  const displayValue = truncate ? getProgressTruncatedValue(value, truncate) : value;
  return (
    <div className={classNames('progress-container', color)}>
      {showValue && <p className='progress-value'>{displayValue}%</p>}
      <ProgressBar showValue={false} value={displayValue} />
    </div>
  );
};
