import { FormValidators, VALIDATION_MESSAGES } from '../../hooks/useForm';
import { BranchDisplay } from '../../types/branch';
import { ExternalUserShort } from '../../types/externalUser';
import { ReservationType } from '../../types/reservationType';
import { UserShort } from '../../types/user';

const { NOT_BRANCH, NOT_START_DATE, NOT_END_DATE, NOT_PRICE, EMPTY } = VALIDATION_MESSAGES;

export interface ReservationForm {
  selectedDay?: Date;

  localStartDate?: string;
  localEndDate?: string;

  price?: number;
  comment?: string;

  reservationTypeId?: string;
  user?: UserShort;
  externalUsers?: ExternalUserShort[];
  confirmedExternalUsers?: ExternalUserShort[];
  queuedExternalUsers?: ExternalUserShort[];
  branch?: BranchDisplay;

  couponId?: string;
  useCoupon?: boolean;

  // Campos para clientes no registrados
  unregisteredClientName?: string;
  unregisteredClientSurnames?: string;
  unregisteredClientPhone?: string;

  usersBeforeSave?: ExternalUserShort[];
  attendanceData?: Record<string, boolean>;
}

export const getReservationFormValidators = (reservationType: ReservationType | undefined): FormValidators<ReservationForm> => {
  const { priceRequired, hasAssignedUsers, hasUnregisteredClients } = reservationType ?? {};
  return {
    user: (reservation) => {
      if (!reservation.user && hasAssignedUsers) return 'Debes seleccionar un empleado';
    },
    externalUsers: (reservation) => {
      if (
        !hasUnregisteredClients &&
        (!reservation.externalUsers || !reservation.externalUsers.length) &&
        (!reservation.usersBeforeSave || !reservation.usersBeforeSave.length) &&
        (!reservation.confirmedExternalUsers || !reservation.confirmedExternalUsers.length)
      ) {
        return EMPTY;
      }
    },
    unregisteredClientName: (reservation) => {
      if (hasUnregisteredClients && !reservation.unregisteredClientName) return 'Debes introducir el nombre del cliente';
    },
    unregisteredClientSurnames: (reservation) => {
      if (hasUnregisteredClients && !reservation.unregisteredClientSurnames) return 'Debes introducir los apellidos del cliente';
    },
    branch: (reservation) => {
      if (!reservation.branch) return NOT_BRANCH;
    },
    localStartDate: (reservation) => {
      if (!reservation.localStartDate) return NOT_START_DATE;
    },
    localEndDate: (reservation) => {
      if (!reservation.localEndDate) return NOT_END_DATE;
    },
    selectedDay: (reservation) => {
      if (!reservation.selectedDay) return 'Debes seleccionar una fecha';
    },
    price: (reservation) => {
      if (
        priceRequired &&
        (hasUnregisteredClients ||
          (!hasUnregisteredClients && reservation.externalUsers && reservation.externalUsers.length > 0)) &&
        !reservation.price &&
        reservation.price !== 0
      )
        return NOT_PRICE;
    },
  };
};
