import React from 'react';
import { ExternalUserShort } from '../../types/externalUser';
import { ExternalUsersSelect } from '../ExternalUsersSelect/ExternalUsersSelect';
import { UserDisplay } from '../UserDisplay/UserDisplay';
import { FormFieldError } from '../../hooks/useForm';

interface Props {
  isModifying: boolean;
  user: ExternalUserShort | undefined;
  onChange: (externalUser: ExternalUserShort) => void;
  error: FormFieldError;
  withCouponData?: boolean;
}

export const ExternalUserField = ({ isModifying, user, onChange, error, withCouponData }: Props) => {
  return isModifying ? (
    <ExternalUsersSelect value={user?.id} isSingle onChange={onChange} error={error} withCouponData={withCouponData} />
  ) : (
    <UserDisplay name={user?.name} surnames={user?.surnames} avatarUrl={user?.avatarUrl} />
  );
};
