import { FormValidators, VALIDATION_MESSAGES } from '../../hooks/useForm';
import { ReservationHours } from '../../types/reservationType';
import { validateEmail } from '../../utils/validationUtils';

const { NOT_PROMOTIONS_EMAIL, NOT_PROMOTIONS_EMAIL_PASSWORD } = VALIDATION_MESSAGES;

export interface BranchReservationHours {
  hours: ReservationHours;
  closedDays: Date[];
}

export interface ReservationHoursForm {
  [key: string]: BranchReservationHours;
}

export interface PromotionSettingsForm {
  promotionsEmail?: string;
  promotionsEmailPassword?: string;
}

export const RESERVATION_HOURS_FORM_VALIDATORS: FormValidators<ReservationHoursForm> = {};

export const PROMOTION_SETTINGS_FORM_VALIDATORS: FormValidators<PromotionSettingsForm> = {
  promotionsEmail: (form) => {
    if (!form.promotionsEmail) return NOT_PROMOTIONS_EMAIL;
    if (!validateEmail(form.promotionsEmail)) return NOT_PROMOTIONS_EMAIL;
  },
  promotionsEmailPassword: (form) => {
    if (!form.promotionsEmailPassword) return NOT_PROMOTIONS_EMAIL_PASSWORD;
  },
};
