import React from 'react';
import './ExerciseList.scss';
import { Button } from 'primereact/button';
import { PrimeIcons } from 'primereact/api';
import { RoutineExercise } from '../../types/Fisiofit/routine';
import { EXERCISE_TYPE, ExerciseShort } from '../../types/Fisiofit/exercise';
import useSWR from 'swr';
import { useOrganizationContext } from '../../context/OrganizationContext';
import { useClient } from '../../hooks/useClient';
import { ExerciseListExercise } from './ExerciseListExercise';
import { Card } from 'primereact/card';
import { classNames } from 'primereact/utils';
import { FormElementError } from '../FormElementError/FormElementError';
import { ValidationErrors } from '../../hooks/useForm';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';

interface Props {
  exercises: RoutineExercise[];
  onExercisesChange: (exercises: RoutineExercise[]) => void;
  isEditing?: boolean;
  error?: string | ValidationErrors<RoutineExercise>[];
}

export const ExerciseList = ({ exercises, onExercisesChange, isEditing = false, error }: Props) => {
  const { organization } = useOrganizationContext() ?? {};
  const { get } = useClient();

  const isListError = typeof error === 'string';

  const { data: organizationExercises } = useSWR(
    isEditing && organization?.id ? `/organizations/${organization?.id}/exercises?short=true` : null,
    get<ExerciseShort[]>,
  );

  const handleInputChange = (index: number, changes: Partial<RoutineExercise>) => {
    const newExercises = exercises.map((exercise, i) => (i === index ? { ...exercise, ...changes } : exercise));
    onExercisesChange(newExercises);
  };

  const handleDelete = (index: number) => {
    const newExercises = exercises.filter((_, i) => i !== index);
    onExercisesChange(newExercises);
  };

  const addEmptyExercise = () => {
    const newExercise: RoutineExercise = {
      exerciseId: '',
      name: '',
      exerciseType: EXERCISE_TYPE.REPS,
    };

    onExercisesChange([...exercises, newExercise]);
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const items = Array.from(exercises);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    onExercisesChange(items);
  };

  const exerciseList = (
    <>
      {exercises.length ? (
        exercises.map((exercise, index) => (
          <ExerciseListExercise
            key={`exercise-${exercise.exerciseId}-${index}`}
            exercise={exercise}
            isEditing={isEditing}
            index={index}
            organizationExercises={organizationExercises}
            onDelete={handleDelete}
            onInputChange={handleInputChange}
            error={!isListError ? error?.[index] : undefined}
          />
        ))
      ) : (
        <p className='empty-exercise-list-message'>No hay ejercicios</p>
      )}
    </>
  );

  return (
    <>
      <Card className={classNames('exercise-list', { 'p-invalid': isListError })}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId='exercises'>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {exerciseList}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Card>
      <FormElementError position='absolute' className='exercise-list-card-error'>
        {isListError ? error : null}
      </FormElementError>
      {isEditing && (
        <Button
          text
          label='Añadir ejercicio'
          icon={PrimeIcons.PLUS}
          className='add-exercise-button'
          onClick={(e) => {
            e.preventDefault();
            addEmptyExercise();
          }}
        />
      )}
    </>
  );
};
