import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import './RoutinePanel.scss';

import { Button } from 'primereact/button';
import { useForm } from '../../hooks/useForm';
import { FormField } from '../FormField/FormField';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { NotificationMessage, RequestErrorMessages, useClient } from '../../hooks/useClient';
import { ExerciseList } from '../ExerciseList/ExerciseList';
import { Routine, ROUTINE_TYPES } from '../../types/Fisiofit/routine';
import { PrimeIcons } from 'primereact/api';
import { add, addDays, format } from 'date-fns';
import { InputNumber } from 'primereact/inputnumber';
import { mutate } from 'swr';
import { useOrganizationContext } from '../../context/OrganizationContext';
import { Panel, ResourcePanelImplProps } from '../Panel/Panel';
import { SelectButton } from 'primereact/selectbutton';
import { Calendar } from 'primereact/calendar';
import { ExternalUsersSelect } from '../ExternalUsersSelect/ExternalUsersSelect';
import { ExternalUserShort } from '../../types/externalUser';
import { CopyRoutineButton } from '../CopyRoutineButton/CopyRoutineButton';
import { ROUTINE_FORM_VALIDATORS, RoutineForm } from './validators';
import { Tag } from 'primereact/tag';
import { UserDisplay } from '../UserDisplay/UserDisplay';
import { confirmDialog } from 'primereact/confirmdialog';
import { getRoutineDays, getRoutineDaysSeverity } from '../../pages/Routines/Routines';
import { Progress } from '../Progress/Progress';
import { unCapitalize } from '../../utils/textUtils';

export const MAX_DURATION = 365;

const getToastMessages = (
  isEditing: boolean,
): {
  successMessage: NotificationMessage;
  errorMessages: RequestErrorMessages;
} => {
  return {
    successMessage: {
      summary: isEditing ? 'Rutina modificada' : 'Rutina creada',
      detail: isEditing ? 'La rutina se ha modificado correctamente' : 'Se ha creado la nueva rutina',
    },
    errorMessages: {
      summary: isEditing ? 'Error modificando la rutina' : 'Error al crear la rutina',
      defaultDetail: isEditing ? 'No se ha podido modificar la rutina' : 'No se ha podido crear la rutina',
    },
  };
};

const getRoutinePanelTitle = (isCreating: boolean, isEditing: boolean): string => {
  if (!isCreating && isEditing) {
    return 'Editar rutina';
  }
  if (!isCreating && !isEditing) {
    return 'Detalle de rutina';
  }
  if (isCreating && !isEditing) {
    return 'Crear nueva rutina';
  }
  return 'Rutina';
};

const getUserRoutineName = (externalUser: ExternalUserShort): string => {
  const fullName = `${externalUser.name} ${externalUser.surnames}`;
  return `Rutina de ${fullName}`;
};

const getRoutineDisplayName = (name: string | undefined, isNextRoutine = false): string => {
  if (!name) return '-';
  if (isNextRoutine) return `Próxima ${unCapitalize(name)}`;
  return name;
};

const getInitialRoutineForm = (routine?: Routine): RoutineForm => {
  const { name, exercises, localStartDate, duration, externalUser } = routine ?? {};
  return {
    name,
    exercises,
    duration: duration ?? null,
    localStartDate,
    externalUser,
    routineType: routine ? (externalUser ? ROUTINE_TYPES.USER : ROUTINE_TYPES.TEMPLATE) : ROUTINE_TYPES.USER,
  };
};

const ROUTINE_TYPE_OPTIONS = [
  { label: 'Rutina de usuario', value: ROUTINE_TYPES.USER },
  { label: 'Plantilla', value: ROUTINE_TYPES.TEMPLATE },
];

interface Props extends Omit<ResourcePanelImplProps<Routine>, 'onDeleteResource'> {
  onDeleteResource?: (id: string | undefined) => Promise<boolean>;
  overrideNextRoutine?: boolean;
  onSaveSuccess?: () => void;
  creatingFromUserProfile?: boolean;
}

export const RoutinePanel = ({
  resource,
  visible,
  onHide,
  onIsEditingChange,
  onDeleteResource,
  onSaveSuccess,
  isEditing = false,
  isDeleting = false,
  overrideNextRoutine = false,
  creatingFromUserProfile = false,
}: Props) => {
  const [currentRoutine, setCurrentRoutine] = useState<Routine | undefined>(resource);
  const [creatingNewRoutineForExpired, setCreatingNewRoutineForExpired] = useState(false);
  const { post, patch } = useClient();
  const { organization } = useOrganizationContext() ?? {};
  const isCreating = !currentRoutine?.id;
  const isNextRoutine = currentRoutine !== resource && !creatingNewRoutineForExpired;
  const hasNextRoutine = !!currentRoutine?.nextRoutine;

  const initialRoutineForm = useMemo(() => getInitialRoutineForm(currentRoutine), [currentRoutine]);
  const { form, setFormFields, setForm, isSaving, setIsSaving, hasChanged, validationErrors, resetForm, validate } =
    useForm<RoutineForm>(initialRoutineForm, !visible, ROUTINE_FORM_VALIDATORS);

  const { daysDifference, elapsedDays, hasStarted } = getRoutineDays(form?.localStartDate, form?.duration);
  const formattedStartDate = form?.localStartDate ? format(new Date(form.localStartDate), "d 'de' MMMM") : null;
  const formattedEndDate =
    form?.localStartDate && form?.duration
      ? format(add(new Date(form.localStartDate), { days: form.duration }), "d 'de' MMMM")
      : null;
  const hasEnded = !!currentRoutine?.duration && daysDifference !== null && daysDifference > currentRoutine?.duration;
  const canEditStartDate = !isNextRoutine && (isCreating || (isEditing && !hasStarted));
  const nextRoutineDate = resource?.localStartDate
    ? addDays(new Date(resource.localStartDate), resource.duration + 1)
    : undefined;
  const minDuration = isEditing && !isNextRoutine ? elapsedDays : 1;
  const daysRemainingSeverity =
    getRoutineDaysSeverity(form?.localStartDate, form?.duration, !!currentRoutine?.nextRoutine, !!currentRoutine?.isExpired) ??
    'success';
  const isEndingSoon = hasStarted && !hasEnded && daysRemainingSeverity !== 'success';

  const title = useMemo(() => getRoutinePanelTitle(isCreating, isEditing), [isCreating, isEditing]);

  useEffect(() => {
    if (visible) {
      if (overrideNextRoutine) {
        setCurrentRoutine(resource?.nextRoutine);
      } else {
        setCurrentRoutine(resource);
      }
    }
  }, [resource, visible, overrideNextRoutine]);

  const handleSave = async (e?: FormEvent): Promise<boolean> => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    const errors = validate();
    if (!form || !organization || errors) return false;
    setIsSaving(true);

    const { externalUser, exercises, routineType, ...restForm } = form;

    const body = {
      ...restForm,
      exercises: exercises?.map(({ name, exerciseType, ..._ }) => _),
      ...(externalUser ? { externalUserId: externalUser.id } : {}),
      ...(isCreating ? { organizationId: organization?.id } : {}),
      ...(isCreating && isNextRoutine ? { previousRoutineId: resource?.id } : {}),
    };

    const { successMessage, errorMessages } = getToastMessages(isEditing);
    const request = {
      body,
      successMessage,
      errorMessages,
    };
    const response =
      isEditing && currentRoutine
        ? await patch<Routine>(`/routines/${currentRoutine.id}`, request)
        : await post<Routine>(`/routines`, request);

    if (response) {
      mutate(`/organizations/${organization?.id}/routines`, undefined);
      onSaveSuccess?.();
    }

    setIsSaving(false);
    return !!response;
  };

  const handleCopyRoutine = (routine: Routine) => {
    setFormFields({ exercises: routine.exercises });
  };

  const handleExternalUserChange = (externalUser: ExternalUserShort) => {
    setFormFields({ externalUser, name: getUserRoutineName(externalUser) });
  };

  const goBack = () => {
    onIsEditingChange(false);
    setCurrentRoutine(resource);
    setCreatingNewRoutineForExpired(false);
  };

  const handleGoBack = () => {
    if (hasChanged) {
      confirmDialog({
        message: '¿Deseas regresar a la rutina anterior? Perderás los cambios no guardados.',
        header: 'Regresar a la rutina anterior',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Sí, regresar',
        rejectLabel: 'No, continuar editando',
        accept: goBack,
        acceptClassName: 'p-button p-button-text',
        rejectClassName: 'p-button-secondary p-button-text',
      });
    } else {
      goBack();
    }
  };

  const renderDaysElapsed = () => {
    if (!form?.duration || daysDifference === null) return <></>;
    if (!hasStarted) {
      return (
        <>
          <i className='pi pi-stopwatch' />
          Aún no ha comenzado
        </>
      );
    }

    if (currentRoutine?.isExpired) {
      return (
        <span className={classNames('days-elapsed-message', 'error')}>
          <i className={PrimeIcons.EXCLAMATION_CIRCLE} />
          Rutina caducada
        </span>
      );
    }

    if (hasEnded) {
      return (
        <>
          <i className='pi pi-trophy' />
          Rutina terminada
        </>
      );
    }

    return (
      <>
        <span className='days-elapsed'>
          {elapsedDays} de {form?.duration} días
        </span>
        {currentRoutine && (
          <Progress color={daysRemainingSeverity} value={(elapsedDays / form?.duration) * 100} showValue truncate />
        )}
      </>
    );
  };

  const renderContent = () => {
    return (
      <form
        className={classNames({
          'form-loading': isSaving,
        })}
      >
        <FormField elementId='routineType' labelTitle='Tipo de rutina' fullWidth>
          {isCreating && !isNextRoutine && !isEditing && !creatingFromUserProfile && !creatingNewRoutineForExpired ? (
            <SelectButton
              id='routineType'
              value={form?.routineType}
              options={ROUTINE_TYPE_OPTIONS}
              onChange={(e) => setFormFields({ routineType: e.value, name: undefined, externalUser: undefined })}
              disabled={isEditing}
            />
          ) : (
            <Tag
              value={form?.routineType === ROUTINE_TYPES.USER ? 'Rutina de usuario' : 'Plantilla'}
              {...(form?.routineType === ROUTINE_TYPES.USER ? {} : { severity: 'secondary' })}
            />
          )}
        </FormField>

        <FormField
          labelTitle='Nombre de la rutina'
          elementId='routine-name'
          fullWidth
          error={validationErrors?.name}
          required={(isCreating || isEditing) && form?.routineType === ROUTINE_TYPES.TEMPLATE}
        >
          {(isCreating || isEditing) && form?.routineType === ROUTINE_TYPES.TEMPLATE ? (
            <InputText
              id='routine-name'
              invalid={!!validationErrors?.name}
              placeholder={`Nombre de la plantilla`}
              value={form?.name ?? ''}
              onChange={(e) => setFormFields({ name: e.target.value })}
            />
          ) : (
            <p>{getRoutineDisplayName(form?.name, isNextRoutine)}</p>
          )}
        </FormField>

        {form?.routineType === ROUTINE_TYPES.USER && (
          <FormField
            labelTitle={
              isCreating && !creatingFromUserProfile && !creatingNewRoutineForExpired
                ? 'Elige un usuario sin rutina'
                : 'Usuario asignado'
            }
            elementId='routine-user'
            error={validationErrors?.externalUser}
            required={isCreating}
          >
            {isCreating && !isNextRoutine && !creatingFromUserProfile && !creatingNewRoutineForExpired ? (
              <ExternalUsersSelect
                id='routine-user'
                placeholder='Usuarios disponibles'
                searchPlaceholder='Busca usuarios sin rutina'
                value={form?.externalUser?.id}
                onChange={handleExternalUserChange}
                isSingle
                error={validationErrors?.externalUser}
                filterAvailableForNewRoutine
              />
            ) : (
              <div className='routine-user-container'>
                <UserDisplay
                  name={currentRoutine?.externalUser?.name}
                  surnames={currentRoutine?.externalUser?.surnames}
                  avatarUrl={currentRoutine?.externalUser?.avatarUrl}
                />
                {!isEditing && !isCreating && !isNextRoutine && !hasEnded && currentRoutine === resource && (
                  <Button
                    link
                    className='next-routine-button'
                    label={hasNextRoutine ? 'Ver próxima rutina' : 'Crear próxima rutina'}
                    onClick={() => {
                      if (!hasNextRoutine && currentRoutine?.externalUser) {
                        const partialRoutine = {
                          externalUser: currentRoutine?.externalUser,
                          name: getUserRoutineName(currentRoutine?.externalUser),
                          localStartDate: nextRoutineDate?.toISOString(),
                        } as Routine;
                        setCurrentRoutine(partialRoutine);
                        setForm(getInitialRoutineForm(partialRoutine));
                      } else {
                        setCurrentRoutine(currentRoutine.nextRoutine);
                        setForm(getInitialRoutineForm(currentRoutine.nextRoutine));
                      }
                    }}
                  />
                )}
                {currentRoutine?.isExpired && (
                  <Button
                    link
                    className='next-routine-button'
                    label='Asignar nueva rutina'
                    onClick={() => {
                      const { organizationId, externalUser, name } = currentRoutine ?? {};
                      const partialRoutine = { organizationId, externalUser, name } as Routine;
                      setCurrentRoutine(partialRoutine);
                      setForm(getInitialRoutineForm(partialRoutine));
                      setCreatingNewRoutineForExpired(true);
                    }}
                  />
                )}
              </div>
            )}
          </FormField>
        )}

        {form?.routineType === ROUTINE_TYPES.USER && (
          <>
            <FormField
              elementId='start-date'
              labelTitle='Fecha de inicio'
              fullWidth
              error={validationErrors?.localStartDate}
              required={canEditStartDate}
            >
              {canEditStartDate ? (
                <Calendar
                  id='start-date'
                  value={form?.localStartDate ? new Date(form.localStartDate) : undefined}
                  onChange={(e) => setFormFields({ localStartDate: e.value?.toISOString() ?? undefined })}
                  dateFormat='dd/mm/yy'
                  placeholder='dd/mm/aaaa'
                  minDate={new Date()}
                  showIcon
                  invalid={!!validationErrors?.localStartDate}
                />
              ) : (
                <p>{formattedStartDate}</p>
              )}
            </FormField>
          </>
        )}

        {form?.routineType === ROUTINE_TYPES.USER && (
          <FormField labelTitle='Duración (días)' fullWidth error={validationErrors?.duration} required={isCreating || isEditing}>
            {isCreating || isEditing ? (
              <InputNumber
                value={form?.duration}
                onChange={(e) => {
                  setFormFields({ duration: e.value !== null ? (e.value < minDuration ? minDuration : e.value) : null });
                }}
                allowEmpty={!isEditing}
                invalid={!!validationErrors?.duration}
                placeholder='Escribe la duración en días'
                showButtons
                min={minDuration}
                max={MAX_DURATION}
              />
            ) : (
              <p>{currentRoutine?.duration} días</p>
            )}
          </FormField>
        )}

        {form.routineType === ROUTINE_TYPES.USER && (
          <FormField labelTitle='Estado de la rutina' fullWidth>
            {daysDifference !== null && form?.duration ? (
              <>
                <div className='days-elapsed-container'>{renderDaysElapsed()}</div>
                {!isCreating && isEndingSoon && (
                  <div className={classNames('ending-soon-container', daysRemainingSeverity)}>
                    <i className={classNames('cell-severity-icon', PrimeIcons.EXCLAMATION_CIRCLE)} />
                    <small>
                      {form.duration - elapsedDays === 0
                        ? 'Termina hoy'
                        : form.duration - elapsedDays === 1
                          ? 'Termina mañana'
                          : `Termina en ${form.duration - elapsedDays} días`}
                    </small>
                  </div>
                )}
                <small className='routine-dates'>
                  <i className='pi pi-calendar' />
                  <span>
                    Desde el {formattedStartDate} hasta el {formattedEndDate}
                  </span>
                </small>
              </>
            ) : (
              <p>Introduce fecha y duración para ver el estado de la rutina</p>
            )}
          </FormField>
        )}

        {(isCreating || isEditing) && (
          <FormField labelTitle='Copiar ejercicios de otra rutina' fullWidth>
            <div className='copy-routine'>
              <small>Puedes copiar todos los ejercicios de otra rutina y usarlos como base para crear esta rutina.</small>
              <CopyRoutineButton onSelectRoutine={handleCopyRoutine} showConfirmation={isEditing} />
            </div>
          </FormField>
        )}

        <FormField labelTitle='Ejercicios' fullWidth required={isCreating || isEditing}>
          <ExerciseList
            exercises={isEditing || isCreating ? form?.exercises || [] : currentRoutine?.exercises || []}
            onExercisesChange={(exercises) => setFormFields({ exercises })}
            isEditing={isEditing || isCreating}
            error={validationErrors?.exercises}
          />
        </FormField>
      </form>
    );
  };

  const renderHeaderContent = () => (
    <div className='routine-panel-header'>
      {(isNextRoutine || creatingNewRoutineForExpired) && (
        <Button icon={PrimeIcons.ARROW_LEFT} className='p-button-text' onClick={handleGoBack} />
      )}
      <h4>{title}</h4>
      {hasEnded && !currentRoutine?.isExpired && <Tag value='Rutina pasada' severity='contrast' />}
      {isNextRoutine && <Tag value='Próxima rutina' severity='info' />}
      {!isCreating && isEndingSoon && (
        <Tag value='Termina pronto' severity={daysRemainingSeverity === 'error' ? 'danger' : daysRemainingSeverity} />
      )}
      {currentRoutine?.isExpired && <Tag value='Rutina caducada' severity='danger' />}
    </div>
  );

  return (
    <Panel
      panelType='resourcePanel'
      resourceName='rutina'
      HeaderComponent={renderHeaderContent()}
      visible={visible}
      onIsEditingChange={onIsEditingChange}
      onHide={onHide}
      onDelete={onDeleteResource ? () => onDeleteResource(currentRoutine?.id) : undefined}
      isDeleting={isDeleting}
      onSave={handleSave}
      hasChanged={hasChanged}
      isSaving={isSaving}
      isEditing={isEditing}
      isCreating={isCreating}
      className='routine-panel'
      resetForm={resetForm}
      disableButtons={hasEnded}
    >
      {renderContent()}
    </Panel>
  );
};
