import React, { useMemo } from 'react';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { User } from '../../types/user';
import { classNames } from 'primereact/utils';
import { Avatar } from 'primereact/avatar';
import { AVATAR_PLACEHOLDER_URL } from '../../const';
import useSWR from 'swr';
import { useClient } from '../../hooks/useClient';
import { useOrganizationContext } from '../../context/OrganizationContext';
import { ValidationErrors } from '../../hooks/useForm';
import { UserDisplay } from '../UserDisplay/UserDisplay';

interface EmployeeOption extends Partial<User> {
  label: string;
  value: string | null;
}

const ALL_EMPLOYEES_OPTION: EmployeeOption = {
  label: 'Todos los empleados',
  value: null,
};

interface Props {
  onChange: (value: User | null) => void;
  value: User | null;
  placeholder?: string;
  className?: string;
  appendTo?: HTMLElement | 'self' | (() => HTMLElement) | null;
  error?: string | ValidationErrors<User>;
}

export const EmployeeSelect = ({
  onChange,
  value,
  placeholder = 'Elige empleado',
  className,
  appendTo = 'self',
  error,
}: Props) => {
  const { get } = useClient();
  const { organization } = useOrganizationContext() ?? {};

  const { data: employees, isLoading: employeesLoading } = useSWR(
    organization ? `/organizations/${organization.id}/users` : null,
    get<User[]>,
  );

  const employeeOptions = useMemo(() => {
    const options =
      employees?.map((employee) => ({
        ...employee,
        label: `${employee.name} ${employee.surnames}`,
        value: employee.id,
      })) || [];

    return [ALL_EMPLOYEES_OPTION, ...options];
  }, [employees]);

  const employeeTemplate = (option: EmployeeOption | null) => {
    if (!option) {
      return <span>{placeholder}</span>;
    }

    if (option.value === null) {
      return <div>{option.label}</div>;
    }

    return <UserDisplay fullName={option.label} avatarUrl={option.avatarUrl} />;
  };

  return (
    <Dropdown
      filter
      value={value?.id ?? null}
      options={employeeOptions}
      onChange={(e: DropdownChangeEvent) => {
        if (e.value === null) {
          onChange(null);
          return;
        }
        const selectedEmployee = employees?.find((emp) => emp.id === e.value) ?? null;
        onChange(selectedEmployee);
      }}
      placeholder={placeholder}
      className={classNames(className, {
        'p-invalid': error,
      })}
      appendTo={appendTo}
      itemTemplate={employeeTemplate}
      valueTemplate={employeeTemplate}
      loading={employeesLoading}
      emptyMessage='No hay empleados'
      emptyFilterMessage='No se han encontrado empleados'
    />
  );
};
