import { UserCoupon } from './coupon';
import { RoutineDetail } from './Fisiofit/routine';
import { ItemDisplay } from './responses';
import { UserShort } from './user';

export enum EXTERNAL_USER_STATUSES {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum EXTERNAL_USER_GENDERS {
  MALE = 'male',
  FEMALE = 'female',
  OTHER = 'other',
}

export enum USER_ROUTINE_EVENTS {
  NEXT_ROUTINE = 'next-routine',
  CURRENT_ROUTINE = 'current-routine',
  CURRENT_ROUTINE_NOT_STARTED = 'current-routine-not-started',
  PAST_ROUTINE = 'past-routine',
  UPDATE = 'update',
}

export enum USER_CURRENT_ROUTINE_STATUS {
  NOT_STARTED = 'not-started',
  IN_PROGRESS = 'in-progress',
  EXPIRED = 'expired',
  NO_ROUTINE = 'no-routine',
}

export interface ExternalUserTableCurrentRoutine {
  id?: string;
  status: USER_CURRENT_ROUTINE_STATUS;
}

export interface ExternalUserShort {
  id: string;
  name: string;
  surnames: string;
  email: string;
  avatarUrl?: string;
  attendance?: boolean;
}

export interface ExternalUserTableEntry {
  id: string;
  name: string;
  surnames: string;
  joinedDate: string;
  status: EXTERNAL_USER_STATUSES;
  avatarUrl?: string;
  latestReservationDate?: string;
  branch: ItemDisplay;
  isInvitation?: boolean;
  supervisor?: UserShort;
  currentRoutine?: ExternalUserTableCurrentRoutine;
}

export interface UserRoutineHistory {
  date: string;
  eventType: USER_ROUTINE_EVENTS;
  routineId: string;
  fieldsUpdated?: string[];
}

export interface ExternalUser {
  id: string;
  name: string;
  surnames: string;
  email: string;

  joinedDate: string;
  status: EXTERNAL_USER_STATUSES;

  dateOfBirth?: string;
  gender?: EXTERNAL_USER_GENDERS;
  phone?: string;
  avatarUrl?: string;
  latestReservationDate?: string;
  age?: number;
  isInvitation?: boolean;

  currentRoutine?: RoutineDetail;
  routineHistory?: UserRoutineHistory[];

  branchId: string;
  organizationId: string;
  supervisor?: UserShort;
  userCoupon?: UserCoupon;
}
