import { useCallback, useMemo } from 'react';
import { useOrganizationContext } from '../../context/OrganizationContext';
import useSWR from 'swr';
import { getHashMap, HashMap } from '../../utils/objectTransformers';
import { ReservationType } from '../../types/reservationType';
import { useClient } from '../useClient';

export const RESERVATION_COLORS = ['primary', 'green', 'blue', 'yellow', 'orange', 'cyan', 'red'];

export interface ReservationTypeWithColor extends ReservationType {
  color: string;
  tagColor: string;
}
export type ReservationTypeHashMap = HashMap<'id', ReservationTypeWithColor>;

export const useReservationTypes = () => {
  const { organization, organizationLoading } = useOrganizationContext() ?? {};
  const { get } = useClient();

  const reservationTypesFetcher = useCallback(
    async (url: string): Promise<ReservationTypeWithColor[] | undefined> => {
      const reservationTypes = await get<ReservationType[] | undefined>(url);
      if (!reservationTypes) return;

      // Crear un mapa para asignar tagColors consistentes basados en el nombre
      const nameTagColorMap = new Map<string, string>();
      let tagColorIndex = 0;

      const reservationTypesWithColor = reservationTypes.map((type, index) => {
        // Para el tagColor, si ya existe uno para este nombre, usarlo
        let tagColor;
        if (nameTagColorMap.has(type.name)) {
          tagColor = nameTagColorMap.get(type.name)!;
        } else {
          tagColor = RESERVATION_COLORS[tagColorIndex % RESERVATION_COLORS.length];
          nameTagColorMap.set(type.name, tagColor);
          tagColorIndex++;
        }

        // El color del evento siempre es único
        const color = RESERVATION_COLORS[index % RESERVATION_COLORS.length];

        return {
          ...type,
          color,
          tagColor,
        };
      });

      return reservationTypesWithColor;
    },
    [get],
  );

  const {
    data: reservationTypes,
    isLoading: reservationTypesLoading,
    error: reservationTypesError,
    mutate: mutateReservationTypes,
  } = useSWR(organization ? `/organizations/${organization.id}/reservation-types` : null, reservationTypesFetcher, {
    revalidateIfStale: false,
    revalidateOnReconnect: false,
  });

  const reservationTypesHashMap: ReservationTypeHashMap | undefined = useMemo(
    () => (reservationTypes ? getHashMap(reservationTypes, 'id') : undefined),
    [reservationTypes],
  );

  const reservationTypeFromId = useCallback(
    (reservationTypeId: string) => {
      return reservationTypesHashMap?.[reservationTypeId as keyof ReservationTypeHashMap];
    },
    [reservationTypesHashMap],
  );

  const uniqueReservationTypes = useMemo(() => {
    if (!reservationTypes) return [];

    // Crear un Map usando el nombre como clave para eliminar duplicados
    const uniqueTypes = new Map();
    reservationTypes.forEach((type) => {
      if (!uniqueTypes.has(type.name)) {
        uniqueTypes.set(type.name, type);
      }
    });

    return Array.from(uniqueTypes.values());
  }, [reservationTypes]);

  const findReservationTypeByBranchAndName = useCallback(
    (branchId: string | undefined, name: string | undefined) => {
      if (!branchId || !name) return;
      return reservationTypes?.find((_) => _.branchId === branchId && _.name === name);
    },
    [reservationTypes],
  );

  return {
    reservationTypes,
    reservationTypesLoading: reservationTypesLoading || organizationLoading,
    reservationTypesError,
    uniqueReservationTypes,
    reservationTypeFromId,
    mutateReservationTypes,
    findReservationTypeByBranchAndName,
  };
};
