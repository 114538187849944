const css = `.user-panel .user-form .select-role-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.user-panel .user-form .select-role-row .form-field {
  width: 50%;
}
.user-panel .user-form .select-role-row .switch {
  align-items: start;
}
.user-panel .user-invited-tag {
  margin-top: var(--gap);
  margin-bottom: 8px;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9yZW5kZXIvcHJvamVjdC9zcmMvc3JjL2NvbXBvbmVudHMvVXNlclBhbmVsIiwic291cmNlcyI6WyJVc2VyUGFuZWwuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFSTtFQUNFO0VBQ0E7RUFDQTs7QUFDQTtFQUNFOztBQUVGO0VBQ0U7O0FBSU47RUFDRTtFQUNBIiwic291cmNlc0NvbnRlbnQiOlsiLnVzZXItcGFuZWwge1xuICAudXNlci1mb3JtIHtcbiAgICAuc2VsZWN0LXJvbGUtcm93IHtcbiAgICAgIHdpZHRoOiAxMDAlO1xuICAgICAgZGlzcGxheTogZmxleDtcbiAgICAgIGp1c3RpZnktY29udGVudDogc3BhY2UtYmV0d2VlbjtcbiAgICAgIC5mb3JtLWZpZWxkIHtcbiAgICAgICAgd2lkdGg6IDUwJTtcbiAgICAgIH1cbiAgICAgIC5zd2l0Y2gge1xuICAgICAgICBhbGlnbi1pdGVtczogc3RhcnQ7XG4gICAgICB9XG4gICAgfVxuICB9XG4gIC51c2VyLWludml0ZWQtdGFnIHtcbiAgICBtYXJnaW4tdG9wOiB2YXIoLS1nYXApO1xuICAgIG1hcmdpbi1ib3R0b206IDhweDtcbiAgfVxufVxuIl19 */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
