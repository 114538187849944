import React from 'react';

import './Roles.scss';
import { Button } from 'primereact/button';
import { ResponsiveTable } from '../../components/ResponsiveTable/ResponsiveTable';
import { TableColumn, TableAction } from '../../types/responsiveTable';
import { PrimeIcons } from 'primereact/api';
import { Role, RolesTableEntry } from '../../types/role';
import { useClient } from '../../hooks/useClient';
import { useOrganizationContext } from '../../context/OrganizationContext';
import useSWR, { mutate } from 'swr';
import { UserShort } from '../../types/user';
import { RolePanel } from '../../components/RolePanel/RolePanel';
import { useResourcePanel } from '../../hooks/useResourcePanel';
import { resourceDeleter } from '../../utils/resourceMutation';
import { showDeleteDialog } from '../../utils/dialogUtils';

const columns: TableColumn<RolesTableEntry>[] = [
  {
    label: 'Nombre',
    accessor: 'name',
    type: 'string',
    isTitle: true,
  },
  {
    label: 'Usuarios usando',
    accessor: 'roleUsers',
    type: 'number',
    valueFromCallBack: (users: UserShort[] | undefined) => users?.length.toString() ?? '0',
  },
  {
    label: 'Fecha de creacion',
    accessor: 'creationDate',
    type: 'calendar',
  },
];

export const Roles = () => {
  const { get, delete: del } = useClient();
  const { organization, organizationLoading } = useOrganizationContext() ?? {};

  const { data, isLoading } = useSWR(
    organization ? `/organizations/${organization.id}/roles-table` : null,
    get<RolesTableEntry[]>,
  );
  const { isEditing, setIsEditing, panelVisible, closePanel, selectedResource, openPanel, isDeleting, setIsDeleting } =
    useResourcePanel(data);

  const handleDelete = async (id: string | undefined) => {
    if (!id || !organization?.id) return false;
    setIsDeleting(true);
    const url = `/roles/${id}`;
    const response = await del<Role>(url, {
      errorMessages: {
        summary: 'No se pudo eliminar el rol',
        defaultDetail: 'Hubo un error al intentar borrar el rol',
        [409]: 'El rol no se puede eliminar porque está siendo usado',
      },
      successMessage: {
        summary: 'Rol eliminado',
        detail: 'El rol se ha eliminado correctamente',
      },
      handlers: {
        defaultSuccess: () => {
          mutate(`/organizations/${organization.id}/roles-table`, resourceDeleter(id));
        },
      },
    });

    setIsDeleting(false);
    return !!response;
  };

  const options: TableAction[] = [
    {
      label: 'Ver',
      onClick: (id) => {
        openPanel(false, id);
      },
    },
    {
      label: 'Editar',
      onClick: (id) => {
        openPanel(true, id);
      },
    },
    {
      label: 'Eliminar',
      onClick: (id) => {
        showDeleteDialog(() => handleDelete(id));
      },
    },
  ];

  return (
    <>
      <ResponsiveTable
        data={data || []}
        columns={columns}
        options={options}
        loading={organizationLoading || isLoading}
        headerOptions={
          <Button
            label='Añadir rol'
            outlined
            rounded
            icon={PrimeIcons.PLUS}
            onClick={() => {
              openPanel(false);
            }}
          />
        }
        onRowClick={(row) => {
          openPanel(false, row.id);
        }}
      />
      <RolePanel
        resource={selectedResource}
        visible={panelVisible}
        onHide={closePanel}
        onIsEditingChange={setIsEditing}
        onDeleteResource={handleDelete}
        isDeleting={isDeleting}
        isEditing={isEditing}
      ></RolePanel>
    </>
  );
};
