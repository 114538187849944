import React, { useRef, useState, useMemo } from 'react';
import { Button } from 'primereact/button';
import { FormField } from '../FormField/FormField';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { FileUpload } from 'primereact/fileupload';
import { Dialog } from 'primereact/dialog';
import SignatureCanvas from 'react-signature-canvas';
import './ExternalUserPanel.scss';
import { useOrganizationContext } from '../../context/OrganizationContext';
import { NotificationMessage, RequestErrorMessages, useClient } from '../../hooks/useClient';
import { mutate } from 'swr';
import { useForm } from '../../hooks/useForm';
import classNames from 'classnames';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { ExternalUser } from '../../types/externalUser';
import { useAuthContext } from '../../context/AuthContext';
import { Panel, ResourcePanelImplProps } from '../Panel/Panel';
import { EXTERNAL_USER_FORM_VALIDATORS, ExternalUserForm } from './validators';
import { BranchesSelect } from '../BranchesSelect/BranchesSelect';

// TODO move PDF stuff to a separate component or something
// Configuración necesaria para react-pdf
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const genderOptions = [
  { label: 'Hombre', value: 'male' },
  { label: 'Mujer', value: 'female' },
  { label: 'Otro', value: 'other' },
];

const getToastMessages = (
  isEditing: boolean,
): {
  successMessage: NotificationMessage;
  errorMessages: RequestErrorMessages;
} => {
  return {
    successMessage: {
      summary: isEditing ? 'Usuario modificado' : 'Usuario creado',
      detail: isEditing ? 'El usuario se ha modificado correctamente' : 'Se ha creado el nuevo usuario',
    },
    errorMessages: {
      summary: isEditing ? 'Error modificando usuario' : 'Error al crear usuario',
      defaultDetail: isEditing ? 'No se ha podido modificar el usuario' : 'No se ha podido crear el usuario',
    },
  };
};

const getInitialForm = (initialData?: Partial<ExternalUserForm>): ExternalUserForm => ({
  branchId: initialData?.branchId ?? '',
  name: initialData?.name ?? '',
  surnames: initialData?.surnames ?? '',
  email: initialData?.email ?? '',
  phone: initialData?.phone ?? '',
});

interface Props extends ResourcePanelImplProps<ExternalUser> {
  selectedBranchId: string | undefined;
  initialFormData?: Partial<ExternalUserForm>;
}

export const ExternalUserPanel = ({
  resource,
  visible,
  onHide,
  selectedBranchId,
  onIsEditingChange,
  onDeleteResource,
  isEditing = false,
  isDeleting = false,
  initialFormData,
}: Props) => {
  const { post } = useClient();
  const { organization } = useOrganizationContext() ?? {};
  const { user } = useAuthContext() ?? {};
  const [showConsentDialog, setShowConsentDialog] = useState(false);
  const signatureRef = useRef<SignatureCanvas>(null);
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);

  const initialExternalUserForm = useMemo(() => getInitialForm(initialFormData), [initialFormData]);

  const { form, setFormFields, isSaving, setIsSaving, hasChanged, validationErrors, resetForm, validate } = useForm<
    ExternalUserForm | undefined
  >(initialExternalUserForm, !visible, EXTERNAL_USER_FORM_VALIDATORS);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  const handleSave = async (): Promise<boolean> => {
    const errors = validate();
    if (!form || !organization || !user || errors) return false;

    setIsSaving(true);
    const { successMessage, errorMessages } = getToastMessages(isEditing);
    const response = await post<ExternalUser>(`/organizations/${organization?.id}/external-users`, {
      body: form,
      successMessage,
      errorMessages,
    });

    if (response && selectedBranchId) {
      mutate(`/organizations/${organization?.id}/external-users?branches=${selectedBranchId}`, undefined);
    }
    if (response && !selectedBranchId) {
      mutate(`/organizations/${organization?.id}/external-users`, undefined);
    }
    setIsSaving(false);
    return !!response;
  };

  const handleClearSignature = () => {
    signatureRef.current?.clear();
  };

  const handleSaveSignature = () => {
    const signatureImage = signatureRef.current?.getTrimmedCanvas().toDataURL();
    // Guardar signatureImage
    setShowConsentDialog(false);
  };

  return (
    <>
      <Panel
        panelType='resourcePanel'
        resourceName='usuario'
        visible={visible}
        onHide={onHide}
        onDelete={() => onDeleteResource(resource?.id)}
        isDeleting={isDeleting}
        onSave={handleSave}
        onIsEditingChange={onIsEditingChange}
        hasChanged={hasChanged}
        isSaving={isSaving}
        isEditing={false}
        isCreating={true}
        resetForm={resetForm}
      >
        <form
          className={classNames('user-form', {
            'form-loading': isSaving,
          })}
          onSubmit={handleSave}
        >
          <FormField elementId='user-branch' labelTitle='Sucursal' fullWidth error={validationErrors?.branchId} required>
            <BranchesSelect
              id='user-branch'
              isSingle
              error={validationErrors?.branchId}
              value={form?.branchId}
              onChange={(branch) => setFormFields({ branchId: branch?.id })}
            />
          </FormField>

          <FormField labelTitle='Imagen de perfil' fullWidth>
            <FileUpload
              mode='basic'
              name='avatar'
              accept='image/*'
              maxFileSize={1000000}
              chooseLabel='Seleccionar imagen'
              onSelect={(e) => {
                const file = (e.files as File[])[0];
                // Manejar la subida de imagen
                setFormFields({ avatarUrl: 'url_de_la_imagen' });
              }}
            />
          </FormField>

          <FormField labelTitle='Nombre' fullWidth error={validationErrors?.name} required>
            <InputText
              invalid={!!validationErrors?.name}
              value={form?.name}
              onChange={(e) => setFormFields({ name: e.target.value })}
            />
          </FormField>

          <FormField labelTitle='Apellidos' fullWidth error={validationErrors?.surnames} required>
            <InputText
              invalid={!!validationErrors?.surnames}
              value={form?.surnames}
              onChange={(e) => setFormFields({ surnames: e.target.value })}
            />
          </FormField>

          <FormField labelTitle='Correo electrónico' fullWidth error={validationErrors?.email} required>
            <InputText
              invalid={!!validationErrors?.email}
              value={form?.email}
              onChange={(e) => setFormFields({ email: e.target.value })}
            />
          </FormField>

          <FormField labelTitle='Teléfono' fullWidth error={validationErrors?.phone}>
            <InputText
              invalid={!!validationErrors?.phone}
              value={form?.phone}
              onChange={(e) => setFormFields({ phone: e.target.value })}
            />
          </FormField>

          <FormField labelTitle='Sexo' fullWidth error={validationErrors?.gender}>
            <Dropdown
              options={genderOptions}
              placeholder='Selecciona el sexo'
              invalid={!!validationErrors?.gender}
              value={form?.gender}
              onChange={(e) => setFormFields({ gender: e.value })}
            />
          </FormField>

          <FormField labelTitle='Fecha de nacimiento' fullWidth error={validationErrors?.dateOfBirth}>
            <Calendar
              invalid={!!validationErrors?.dateOfBirth}
              dateFormat='dd/mm/yy'
              showIcon
              value={form?.dateOfBirth ? new Date(form.dateOfBirth) : null}
              onChange={(e) => setFormFields({ dateOfBirth: e.value?.toISOString() })}
            />
          </FormField>

          <FormField labelTitle='Consentimiento' /* TODO: error={validationErrors?.consent}*/ required>
            <Button
              label='Firmar consentimiento'
              icon='pi pi-file-pdf'
              onClick={(e) => {
                e.preventDefault();
                setShowConsentDialog(true);
              }}
            />
          </FormField>
        </form>
      </Panel>

      <Dialog
        visible={showConsentDialog}
        onHide={() => setShowConsentDialog(false)}
        header='Consentimiento'
        className='consent-dialog'
      >
        <div className='consent-content'>
          <div className='pdf-viewer'>
            <Document
              file='/path/to/your/consent.pdf' // Aquí debes poner la ruta a tu PDF
              onLoadSuccess={onDocumentLoadSuccess}
              loading={<div>Cargando documento...</div>}
              error={<div>Error al cargar el documento</div>}
            >
              <Page pageNumber={pageNumber} renderTextLayer={false} renderAnnotationLayer={false} width={800} />
            </Document>
            {numPages && (
              <div className='pdf-controls'>
                <Button icon='pi pi-chevron-left' disabled={pageNumber <= 1} onClick={() => setPageNumber((prev) => prev - 1)} />
                <span>
                  Página {pageNumber} de {numPages}
                </span>
                <Button
                  icon='pi pi-chevron-right'
                  disabled={pageNumber >= numPages}
                  onClick={() => setPageNumber((prev) => prev + 1)}
                />
              </div>
            )}
          </div>

          <div className='signature-section'>
            <div className='signature-pad-container'>
              <SignatureCanvas
                ref={signatureRef}
                canvasProps={{
                  className: 'signature-pad',
                }}
              />
            </div>
            <div className='signature-actions'>
              <Button label='Limpiar' onClick={handleClearSignature} outlined />
              <Button label='Guardar firma' onClick={handleSaveSignature} />
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};
