import React, { useEffect, useState } from 'react';
import { FormField } from '../FormField/FormField';
import { Calendar } from 'primereact/calendar';
import './TimeRange.scss';
import { classNames } from 'primereact/utils';
import { format, parse, set } from 'date-fns';
import { Switch } from '../Switch/Switch';
import { TimeSlot } from '../../types/reservationType';

const getHoursValue = (date: Date): string => {
  return format(date, 'HH:mm');
};

const parseTimeToDate = (timeString: string | undefined): Date | undefined => {
  if (!timeString) return;
  const baseDate = new Date();
  const parsedTime = parse(timeString, 'HH:mm', baseDate);
  return set(baseDate, {
    hours: parsedTime.getHours(),
    minutes: parsedTime.getMinutes(),
    seconds: 0,
    milliseconds: 0,
  });
};

interface TimeRangeProps {
  value: Partial<TimeSlot> | undefined;
  onChange: (value: Partial<TimeSlot> | undefined) => void;
  toggleLabel?: string;
  label?: string;
}

const TimeRange: React.FC<TimeRangeProps> = ({ toggleLabel, value, onChange, label }) => {
  const [enabled, setEnabled] = useState(!!value);

  useEffect(() => {
    if (value && !enabled) {
      setEnabled(true);
    }
  }, [value]);

  const toggleEnabled = () => {
    if (enabled) {
      onChange(undefined);
    }
    setEnabled(!enabled);
  };

  return (
    <div className={classNames('time-range')}>
      {toggleLabel && (
        <Switch
          id={toggleLabel}
          label={toggleLabel}
          primeSwitchProps={{
            checked: enabled,
            onChange: toggleEnabled,
          }}
        />
      )}
      <FormField labelTitle={label}>
        <Calendar
          placeholder='HH:MM'
          value={parseTimeToDate(value?.start)}
          stepMinute={5}
          onChange={(e) => {
            if (!e.value) return;
            onChange({
              ...value,
              start: getHoursValue(e.value),
            });
          }}
          timeOnly
          disabled={!enabled}
        />
      </FormField>
      <FormField>
        <Calendar
          placeholder='HH:MM'
          value={parseTimeToDate(value?.end)}
          stepMinute={5}
          onChange={(e) => {
            if (!e.value) return;
            onChange({
              ...value,
              end: getHoursValue(e.value),
            });
          }}
          timeOnly
          disabled={!enabled}
        />
      </FormField>
    </div>
  );
};

export default TimeRange;
