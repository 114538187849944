import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Chart } from 'primereact/chart';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './ChartDetail.scss';
import { BusinessAnalyticsData, getBusinessChartOptions } from '../../../utils/chart-utils';

interface ChartDetailProps {
  visible: boolean;
  onHide: () => void;
  chartType: string | null;
  data: BusinessAnalyticsData;
}

export const ChartDetail = ({ visible, onHide, chartType, data }: ChartDetailProps) => {
  const getDetailContent = () => {
    switch (chartType) {
      case 'activity':
        return (
          <div className='chart-detail-content'>
            <div className='chart-container'>
              <Chart type='line' data={data.charts.combinedActivityTimeSeries} options={getBusinessChartOptions('line')} />
            </div>
            <div className='stats-container'>
              <h3>Estadísticas de Actividad</h3>
              <div className='stats-grid'>
                <div className='stat-item'>
                  <span className='label'>Promedio de asistencias</span>
                  <span className='value'>32.5</span>
                </div>
                <div className='stat-item'>
                  <span className='label'>Promedio de cancelaciones</span>
                  <span className='value'>3.7</span>
                </div>
                <div className='stat-item'>
                  <span className='label'>Tendencia asistencias</span>
                  <span className='value increase'>+8%</span>
                </div>
                <div className='stat-item'>
                  <span className='label'>Tendencia cancelaciones</span>
                  <span className='value decrease'>-12%</span>
                </div>
              </div>
            </div>
          </div>
        );

      case 'users-evolution':
        return (
          <div className='chart-detail-content'>
            <div className='chart-container'>
              <Chart type='line' data={data.charts.usersTimeSeries} options={getBusinessChartOptions('line')} />
            </div>
            <div className='stats-container'>
              <h3>Estadísticas de Usuarios</h3>
              <div className='stats-grid'>
                <div className='stat-item'>
                  <span className='label'>Balance neto</span>
                  <span className='value increase'>+26</span>
                </div>
                <div className='stat-item'>
                  <span className='label'>Retención</span>
                  <span className='value'>92%</span>
                </div>
                <div className='stat-item'>
                  <span className='label'>Crecimiento</span>
                  <span className='value increase'>+15%</span>
                </div>
              </div>
            </div>
          </div>
        );

      case 'new-users':
        return (
          <div className='chart-detail-content'>
            <div className='chart-container'>
              <Chart type='line' data={data.charts.newUsersTimeSeries} options={getBusinessChartOptions('line')} />
            </div>
            <div className='stats-container'>
              <h3>Análisis de Altas</h3>
              <div className='stats-grid'>
                <div className='stat-item'>
                  <span className='label'>Promedio mensual</span>
                  <span className='value'>22</span>
                </div>
                <div className='stat-item'>
                  <span className='label'>Mejor mes</span>
                  <span className='value'>34</span>
                </div>
                <div className='stat-item'>
                  <span className='label'>Tendencia</span>
                  <span className='value increase'>+25%</span>
                </div>
              </div>
            </div>
          </div>
        );

      case 'unsubscribes':
        return (
          <div className='chart-detail-content'>
            <div className='chart-container'>
              <Chart type='line' data={data.charts.unsubscribesTimeSeries} options={getBusinessChartOptions('line')} />
            </div>
            <div className='stats-container'>
              <h3>Análisis de Bajas</h3>
              <div className='stats-grid'>
                <div className='stat-item'>
                  <span className='label'>Promedio mensual</span>
                  <span className='value'>8</span>
                </div>
                <div className='stat-item'>
                  <span className='label'>Peor mes</span>
                  <span className='value'>12</span>
                </div>
                <div className='stat-item'>
                  <span className='label'>Tendencia</span>
                  <span className='value decrease'>-15%</span>
                </div>
              </div>
            </div>
          </div>
        );

      case 'templates':
        return (
          <div className='chart-detail-content'>
            <div className='chart-container'>
              <Chart type='bar' data={data.charts.templateUsage} options={getBusinessChartOptions('bar')} />
            </div>
            <div className='stats-container'>
              <h3>Análisis de Templates</h3>
              <DataTable
                value={data.charts.templateUsage.labels.map((label, index) => ({
                  template: label,
                  uses: data.charts.templateUsage.datasets[0].data[index],
                  percentage: `${Math.round(
                    (data.charts.templateUsage.datasets[0].data[index] /
                      data.charts.templateUsage.datasets[0].data.reduce((a: number, b: number) => a + b, 0)) *
                      100,
                  )}%`,
                  rating: (4.5 - index * 0.2).toFixed(1),
                }))}
                className='template-table'
              >
                <Column field='template' header='Template'></Column>
                <Column field='uses' header='Usos'></Column>
                <Column field='percentage' header='Porcentaje'></Column>
                <Column field='rating' header='Valoración'></Column>
              </DataTable>
            </div>
          </div>
        );

      case 'cancellations':
        return (
          <div className='chart-detail-content'>
            <div className='chart-container'>
              <Chart type='pie' data={data.charts.cancellationReasons} options={getBusinessChartOptions('pie')} />
            </div>
            <div className='stats-container'>
              <h3>Análisis de Cancelaciones</h3>
              <DataTable
                value={data.charts.cancellationReasons.labels.map((label, index) => ({
                  reason: label,
                  count: data.charts.cancellationReasons.datasets[0].data[index],
                  percentage: `${Math.round(
                    (data.charts.cancellationReasons.datasets[0].data[index] /
                      data.charts.cancellationReasons.datasets[0].data.reduce((a: number, b: number) => a + b, 0)) *
                      100,
                  )}%`,
                }))}
                className='cancellation-table'
              >
                <Column field='reason' header='Motivo'></Column>
                <Column field='count' header='Cantidad'></Column>
                <Column field='percentage' header='Porcentaje'></Column>
              </DataTable>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header={getChartTitle(chartType)}
      style={{ width: '90vw', maxWidth: '1200px' }}
      modal
      className='chart-detail-dialog'
    >
      {getDetailContent()}
    </Dialog>
  );
};

function getChartTitle(chartType: string | null): string {
  switch (chartType) {
    case 'activity':
      return 'Análisis Detallado de Actividad';
    case 'users-evolution':
      return 'Análisis de Evolución de Usuarios';
    case 'new-users':
      return 'Análisis Detallado de Altas';
    case 'unsubscribes':
      return 'Análisis Detallado de Bajas';
    case 'cancellations':
      return 'Análisis de Motivos de Cancelación';
    case 'templates':
      return 'Análisis de Uso de Templates';
    default:
      return '';
  }
}
