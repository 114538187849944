const css = `.numeric-filter-container {
  display: flex;
  gap: 8px;
  align-items: center;
}
.numeric-filter-container .p-inputtext {
  width: 100% !important;
  min-width: unset;
}
.numeric-filter-container input.p-checkbox-input {
  width: inherit !important;
  min-width: unset;
}

@media screen and (max-width: 768px) {
  .numeric-filter-container {
    width: 100%;
  }
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9yZW5kZXIvcHJvamVjdC9zcmMvc3JjL2NvbXBvbmVudHMvTnVtZXJpY0ZpbHRlciIsInNvdXJjZXMiOlsiTnVtZXJpY0ZpbHRlci5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0U7RUFDQTtFQUNBOztBQUNBO0VBQ0U7RUFDQTs7QUFFRjtFQUNFO0VBQ0E7OztBQUlKO0VBQ0U7SUFDRSIsInNvdXJjZXNDb250ZW50IjpbIi5udW1lcmljLWZpbHRlci1jb250YWluZXIge1xuICBkaXNwbGF5OiBmbGV4O1xuICBnYXA6IDhweDtcbiAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAgLnAtaW5wdXR0ZXh0IHtcbiAgICB3aWR0aDogMTAwJSAhaW1wb3J0YW50O1xuICAgIG1pbi13aWR0aDogdW5zZXQ7XG4gIH1cbiAgaW5wdXQucC1jaGVja2JveC1pbnB1dCB7XG4gICAgd2lkdGg6IGluaGVyaXQgIWltcG9ydGFudDtcbiAgICBtaW4td2lkdGg6IHVuc2V0O1xuICB9XG59XG5cbkBtZWRpYSBzY3JlZW4gYW5kIChtYXgtd2lkdGg6IDc2OHB4KSB7XG4gIC5udW1lcmljLWZpbHRlci1jb250YWluZXIge1xuICAgIHdpZHRoOiAxMDAlO1xuICB9XG59XG4iXX0= */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
