import React, { useEffect, useMemo, useState } from 'react';
import './FeaturePicker.scss';
import { ALL_FEATURES, Feature } from '../../config/features';
import { classNames } from 'primereact/utils';
import { Checkbox } from 'primereact/checkbox';
import { InputSwitch } from 'primereact/inputswitch';
import { useOrganizationContext } from '../../context/OrganizationContext';

interface FeatureNode {
  key: Feature;
  label: string;
  icon: string;
  children?: Feature[];
}

interface Props {
  featureSet?: Set<Feature>;
  onChange?: (features: Set<Feature>) => void;
  disabled?: boolean;
  elementId?: string;
  className?: string;
}

export const FeaturePicker = ({ featureSet, onChange, disabled, elementId, className }: Props) => {
  const { organization } = useOrganizationContext() ?? {};
  const organizationFeaturesSet = new Set(organization?.features);
  const [groupStates, setGroupStates] = useState<Record<string, boolean>>({});

  const FEATURE_NODES = useMemo((): FeatureNode[] => {
    const nodes = [
      // Web - Con hijos
      {
        key: ALL_FEATURES.BLOG,
        label: 'Blog',
        icon: 'pi pi-book',
        children: [ALL_FEATURES.BLOG_WRITE],
      },
      {
        key: ALL_FEATURES.EXTERNAL_USERS,
        label: 'Usuarios',
        icon: 'pi pi-users',
        children: [ALL_FEATURES.EXTERNAL_USERS_WRITE],
      },
      // Negocio - Con hijos
      {
        key: ALL_FEATURES.SINGLE_RESERVATIONS,
        label: 'Reservas individuales',
        icon: 'pi pi-calendar',
        children: [ALL_FEATURES.SINGLE_RESERVATIONS_WRITE],
      },
      {
        key: ALL_FEATURES.GROUP_RESERVATIONS,
        label: 'Reservas de grupo',
        icon: 'pi pi-calendar',
        children: [ALL_FEATURES.GROUP_RESERVATIONS_WRITE],
      },
      {
        key: ALL_FEATURES.ROUTINES,
        label: 'Rutinas',
        icon: 'pi pi-table',
        children: [ALL_FEATURES.ROUTINES_WRITE],
      },
      {
        key: ALL_FEATURES.EXERCISES,
        label: 'Ejercicios',
        icon: 'pi pi-hammer',
        children: [ALL_FEATURES.EXERCISES_WRITE],
      },
      {
        key: ALL_FEATURES.BUSINESS_CONFIG,
        label: 'Configuración',
        icon: 'pi pi-cog',
        children: [ALL_FEATURES.BUSINESS_CONFIG_WRITE],
      },
      // Ajustes - Con hijos
      {
        key: ALL_FEATURES.USERS,
        label: 'Empleados',
        icon: 'pi pi-building-columns',
        children: [ALL_FEATURES.USERS_WRITE],
      },
      // Nodos sin hijos al final
      {
        key: ALL_FEATURES.BUSINESS_ANALYTICS,
        label: 'Estadísticas',
        icon: 'pi pi-chart-pie',
      },
      {
        key: ALL_FEATURES.RESERVATIONS,
        label: 'Reservas',
        icon: 'pi pi-calendar',
      },
      {
        key: ALL_FEATURES.PROMOTIONS,
        label: 'Promociones',
        icon: 'pi pi-envelope',
      },
      {
        key: ALL_FEATURES.NOTIFICATIONS,
        label: 'Notificaciones',
        icon: 'pi pi-bell',
      },
    ];

    return nodes.filter((node) => {
      // Verificar que tanto el nodo padre como sus hijos (si tiene) están disponibles
      const hasParentFeature = organizationFeaturesSet.has(node.key);
      const hasChildrenFeatures = node.children?.every((child) => organizationFeaturesSet.has(child)) ?? true;
      return hasParentFeature && hasChildrenFeatures;
    });
  }, [organizationFeaturesSet]);

  useEffect(() => {
    // Inicializar estados de grupos basados en las features seleccionadas
    const newGroupStates: Record<string, boolean> = {};
    FEATURE_NODES.forEach((node) => {
      // Solo verificar el feature padre para el toggle
      newGroupStates[node.key] = featureSet?.has(node.key) ?? false;
    });
    setGroupStates(newGroupStates);
  }, [featureSet, FEATURE_NODES]);

  const handleNodeToggle = (node: FeatureNode, checked: boolean) => {
    if (disabled || !onChange) return;

    const newFeatures = new Set(featureSet);

    // Solo toggle del feature padre
    if (checked) {
      newFeatures.add(node.key);
    } else {
      newFeatures.delete(node.key);
    }

    onChange(newFeatures);
  };

  const handleChildToggle = (feature: Feature) => {
    if (disabled || !onChange) return;

    const newFeatures = new Set(featureSet);
    if (newFeatures.has(feature)) {
      newFeatures.delete(feature);
    } else {
      newFeatures.add(feature);
    }

    onChange(newFeatures);
  };

  const getChildLabel = (parentKey: Feature, childKey: Feature): string => {
    switch (childKey) {
      case ALL_FEATURES.BUSINESS_CONFIG_WRITE:
        return 'Editar la configuración';
      case ALL_FEATURES.EXTERNAL_USERS_WRITE:
        return 'Crear usuarios';
      case ALL_FEATURES.USERS_WRITE:
        return 'Crear empleados y modificar roles';
      case ALL_FEATURES.SINGLE_RESERVATIONS_WRITE:
        return 'Crear y editar reservas individuales';
      case ALL_FEATURES.GROUP_RESERVATIONS_WRITE:
        return 'Crear y editar reservas de grupo';
      case ALL_FEATURES.ROUTINES_WRITE:
        return 'Crear, editar y asignar rutinas';
      case ALL_FEATURES.EXERCISES_WRITE:
        return 'Crear y editar ejercicios';
      case ALL_FEATURES.BLOG_WRITE:
        return 'Publicar y editar artículos';
      default:
        return 'Editar';
    }
  };

  return (
    <div id={elementId} className={classNames('feature-picker', className, { disabled })}>
      {FEATURE_NODES.map((node) => (
        <div key={node.key} className='feature-node'>
          <div className='node-header'>
            <div className='node-title'>
              <i className={node.icon} />
              <span>{node.label}</span>
            </div>
            <div className='node-toggle'>
              <span className='toggle-label'>Solo ver</span>
              <InputSwitch
                checked={groupStates[node.key]}
                onChange={(e) => handleNodeToggle(node, e.value)}
                disabled={disabled}
              />
            </div>
          </div>
          {node.children && (
            <div className='node-children'>
              {node.children.map((childKey) => (
                <div key={childKey} className='feature-item'>
                  <span className='feature-label'>{getChildLabel(node.key, childKey)}</span>
                  <Checkbox
                    checked={featureSet?.has(childKey) || false}
                    onChange={() => handleChildToggle(childKey)}
                    disabled={disabled}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
