const css = `.user-display {
  display: flex;
  align-items: center;
  gap: 12px;
}
.user-display .p-button {
  height: 32px;
  margin: unset;
}
.user-display .p-button.p-button-icon-only {
  width: 32px;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9yZW5kZXIvcHJvamVjdC9zcmMvc3JjL2NvbXBvbmVudHMvVXNlckRpc3BsYXkiLCJzb3VyY2VzIjpbIlVzZXJEaXNwbGF5LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRTtFQUNBO0VBQ0E7O0FBRUE7RUFDRTtFQUNBOztBQUNBO0VBQ0UiLCJzb3VyY2VzQ29udGVudCI6WyIudXNlci1kaXNwbGF5IHtcbiAgZGlzcGxheTogZmxleDtcbiAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAgZ2FwOiAxMnB4O1xuXG4gIC5wLWJ1dHRvbiB7XG4gICAgaGVpZ2h0OiAzMnB4O1xuICAgIG1hcmdpbjogdW5zZXQ7XG4gICAgJi5wLWJ1dHRvbi1pY29uLW9ubHkge1xuICAgICAgd2lkdGg6IDMycHg7XG4gICAgfVxuICB9XG59XG4iXX0= */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
