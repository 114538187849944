import React from 'react';
import { NavElement } from '../../types/navigation';
import { Route } from 'react-router-dom';
import { EmployeesList } from './EmployeesList/EmployeesList';
import { Roles } from '../Roles/Roles';
import { PageTemplate } from '../../layout/PageTemplate/PageTemplate';

const employeeRoutes: NavElement[] = [
  {
    label: 'Empleados',
    element: <EmployeesList />,
    url: '/',
  },
  {
    label: 'Roles',
    element: <Roles />,
    url: '/roles',
  },
];

export const Employees = () => {
  return (
    <PageTemplate title='Empleados' parentRoute='/employees' routes={employeeRoutes} withPageLocaleContext>
      {employeeRoutes.map(({ element, url }) => (
        <Route key={url} path={url} element={element} />
      ))}
    </PageTemplate>
  );
};
