const css = `.dropdown-button-container {
  position: relative;
  width: fit-content;
}
.dropdown-button-container .dropdown-button-dropdown {
  position: absolute;
  top: -10%;
  left: 0;
  width: 100%;
  height: 120%;
  opacity: 0;
}
.dropdown-button-container.small .dropdown-button-dropdown {
  top: -11%;
  height: 125%;
}
.dropdown-button-container.small .p-button {
  height: 32px;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9yZW5kZXIvcHJvamVjdC9zcmMvc3JjL2NvbXBvbmVudHMvRHJvcGRvd25CdXR0b24iLCJzb3VyY2VzIjpbIkRyb3Bkb3duQnV0dG9uLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRTtFQUNBOztBQUVBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUlBO0VBQ0U7RUFDQTs7QUFFRjtFQUNFIiwic291cmNlc0NvbnRlbnQiOlsiLmRyb3Bkb3duLWJ1dHRvbi1jb250YWluZXIge1xuICBwb3NpdGlvbjogcmVsYXRpdmU7XG4gIHdpZHRoOiBmaXQtY29udGVudDtcblxuICAuZHJvcGRvd24tYnV0dG9uLWRyb3Bkb3duIHtcbiAgICBwb3NpdGlvbjogYWJzb2x1dGU7XG4gICAgdG9wOiAtMTAlO1xuICAgIGxlZnQ6IDA7XG4gICAgd2lkdGg6IDEwMCU7XG4gICAgaGVpZ2h0OiAxMjAlO1xuICAgIG9wYWNpdHk6IDA7XG4gIH1cblxuICAmLnNtYWxsIHtcbiAgICAuZHJvcGRvd24tYnV0dG9uLWRyb3Bkb3duIHtcbiAgICAgIHRvcDogLTExJTtcbiAgICAgIGhlaWdodDogMTI1JTtcbiAgICB9XG4gICAgLnAtYnV0dG9uIHtcbiAgICAgIGhlaWdodDogMzJweDtcbiAgICB9XG4gIH1cbn1cbiJdfQ== */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
