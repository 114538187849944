const css = `.coupon-display {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
}
.coupon-display small.warning {
  color: var(--orange-600) !important;
  font-weight: 700;
}
.coupon-display small.error {
  color: var(--pink-600) !important;
  font-weight: 700;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9yZW5kZXIvcHJvamVjdC9zcmMvc3JjL2NvbXBvbmVudHMvQ291cG9uRGlzcGxheSIsInNvdXJjZXMiOlsiQ291cG9uRGlzcGxheS5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7O0FBRUE7RUFDRTtFQUNBOztBQUVGO0VBQ0U7RUFDQSIsInNvdXJjZXNDb250ZW50IjpbIi5jb3Vwb24tZGlzcGxheSB7XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gIGdhcDogOHB4O1xuICBmbGV4LXdyYXA6IHdyYXA7XG5cbiAgc21hbGwud2FybmluZyB7XG4gICAgY29sb3I6IHZhcigtLW9yYW5nZS02MDApICFpbXBvcnRhbnQ7XG4gICAgZm9udC13ZWlnaHQ6IDcwMDtcbiAgfVxuICBzbWFsbC5lcnJvciB7XG4gICAgY29sb3I6IHZhcigtLXBpbmstNjAwKSAhaW1wb3J0YW50O1xuICAgIGZvbnQtd2VpZ2h0OiA3MDA7XG4gIH1cbn1cbiJdfQ== */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
