import React, { FormEvent, useCallback, useMemo } from 'react';
import './CouponPanel.scss';

import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { classNames } from 'primereact/utils';
import { mutate } from 'swr';
import { Coupon } from '../../types/coupon';
import { FormField } from '../FormField/FormField';
import { useForm } from '../../hooks/useForm';
import { useClient } from '../../hooks/useClient';
import { useOrganizationContext } from '../../context/OrganizationContext';
import { resourceMutator } from '../../utils/resourceMutation';
import { Panel, ResourcePanelImplProps } from '../Panel/Panel';
import { COUPON_FORM_VALIDATORS, CouponForm } from './validators';

const getInitialCouponForm = (coupon: Coupon | undefined): CouponForm => {
  if (!coupon) return { name: '', price: undefined, usageLimit: undefined };
  return {
    name: coupon.name,
    price: coupon.price,
    usageLimit: coupon.usageLimit,
  };
};

export const CouponPanel = ({
  resource,
  visible,
  onHide,
  onIsEditingChange,
  onDeleteResource,
  isEditing = false,
  isDeleting = false,
}: ResourcePanelImplProps<Coupon>) => {
  const { organization } = useOrganizationContext() ?? {};
  const { post, patch } = useClient();
  const isCreating = !resource;

  const initialForm = useMemo(() => getInitialCouponForm(resource), [resource]);
  const { form, setFormFields, isSaving, setIsSaving, hasChanged, validationErrors, validate, resetForm } = useForm<CouponForm>(
    initialForm,
    !visible,
    COUPON_FORM_VALIDATORS,
  );

  const handleSave = useCallback(
    async (e?: FormEvent): Promise<boolean> => {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
      const newErrors = validate();
      if (!organization || !form.name || form.price === undefined || form.usageLimit === undefined || newErrors) {
        return false;
      }

      setIsSaving(true);
      let response = null;

      if (isCreating) {
        response = await post<Coupon>(`/coupons`, {
          body: {
            name: form.name,
            price: form.price,
            usageLimit: form.usageLimit,
            organizationId: organization.id,
          },
        });
      } else if (resource) {
        response = await patch<Coupon>(`/coupons/${resource.id}`, {
          body: {
            name: form.name,
            price: form.price,
            usageLimit: form.usageLimit,
          },
        });
      }

      if (response) {
        mutate(`/organizations/${organization.id}/coupons`, resourceMutator(response), false);
      }

      setIsSaving(false);
      return !!response;
    },
    [organization, form, isCreating, resource, post, patch, validate, setIsSaving],
  );

  return (
    <Panel
      panelType='resourcePanel'
      resourceName='bono'
      visible={visible}
      onHide={onHide}
      onDelete={() => onDeleteResource(resource?.id)}
      isDeleting={isDeleting}
      onSave={handleSave}
      onIsEditingChange={onIsEditingChange}
      hasChanged={hasChanged}
      isSaving={isSaving}
      isEditing={isEditing}
      isCreating={isCreating}
      resetForm={resetForm}
    >
      {!isCreating && !isEditing && (
        <div className='readonly-form'>
          <FormField labelTitle='Nombre' fullWidth>
            <p>{resource?.name}</p>
          </FormField>
          <FormField labelTitle='Precio' fullWidth>
            <p>{resource?.price}€</p>
          </FormField>
          <FormField labelTitle='Usos' fullWidth>
            <p>{resource?.usageLimit}</p>
          </FormField>
        </div>
      )}

      {(isEditing || isCreating) && (
        <form
          className={classNames({
            'form-loading': isSaving,
          })}
          onSubmit={handleSave}
          noValidate
          autoComplete='off'
        >
          <FormField labelTitle='Nombre' elementId='name' error={validationErrors?.name} required>
            <InputText
              id='name'
              placeholder='Nombre del bono'
              className={classNames({ 'p-invalid': validationErrors?.name })}
              value={form?.name}
              onChange={(e) => setFormFields({ name: e.target.value })}
            />
          </FormField>

          <FormField labelTitle='Precio' elementId='price' error={validationErrors?.price} required>
            <div className='p-inputgroup'>
              <InputNumber
                id='price'
                placeholder='Precio del bono (euros)'
                className={classNames({ 'p-invalid': validationErrors?.price })}
                value={form?.price ?? null}
                onChange={(e) => setFormFields({ price: e.value ?? undefined })}
              />
              <span className='p-inputgroup-addon'>€</span>
            </div>
          </FormField>

          <FormField labelTitle='Usos' elementId='usageLimit' error={validationErrors?.usageLimit} required>
            <InputNumber
              id='usageLimit'
              placeholder='Número de sesiones que cubre el bono'
              className={classNames({ 'p-invalid': validationErrors?.usageLimit })}
              value={form?.usageLimit ?? null}
              onChange={(e) => setFormFields({ usageLimit: e.value ?? undefined })}
              min={1}
              showButtons
            />
          </FormField>
        </form>
      )}
    </Panel>
  );
};
