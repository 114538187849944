import { FormValidators, VALIDATION_MESSAGES } from '../../hooks/useForm';
import { validateEmail, validatePassword } from '../../utils/validationUtils';
import { LoginForm } from './Login';
import { PasswordRecoveryForm } from './PasswordRecovery';
import { PasswordResetForm } from './PasswordReset';

const { NOT_EMAIL, INVALID_PASSWORD, NOT_PASSWORD } = VALIDATION_MESSAGES;

export const LOGIN_VALIDATORS: FormValidators<LoginForm> = {
  email: (form) => {
    if (!form.email || !validateEmail(form.email)) return NOT_EMAIL;
  },
  password: (form) => {
    if (!form.password || !validatePassword(form.password)) return INVALID_PASSWORD;
  },
};

export const PASSWORD_RECOVERY_VALIDATORS: FormValidators<PasswordRecoveryForm> = {
  email: (form) => {
    if (!form.email || !validateEmail(form.email)) return NOT_EMAIL;
  },
};

export const PASSWORD_FORM_VALIDATORS: FormValidators<PasswordResetForm> = {
  password: (form) => {
    if (!form.password || !validatePassword(form.password)) return INVALID_PASSWORD;
  },
  confirmPassword: (form) => {
    if (!form.confirmPassword?.length) return NOT_PASSWORD;
    if (form.password !== form.confirmPassword) return 'Debe ser igual que la nueva contraseña';
  },
};
