import React from 'react';
import { useLocation } from 'react-router-dom';
import './SidebarButton.scss';
import { getRouteSteps } from '../../../utils/navigation';
import { LinkButton } from '../../../components/LinkButton/LinkButton';

const ROUTE_STEP_INDEX = 0;

interface Props {
  label: string;
  href?: string;
  icon?: string;
  onClick?: (e: any) => void;
}

export const SidebarButton = ({ label, href, icon = '', onClick }: Props) => {
  const location = useLocation();
  const currentPage = getRouteSteps(location)[ROUTE_STEP_INDEX];
  const routeName = href?.replace('/', '');

  return (
    <>
      <LinkButton
        className={`sidebar-button ${currentPage === routeName ? ' selected' : ''}`}
        to={href || ''}
        label={label}
        icon={icon}
        onClick={onClick}
      ></LinkButton>
    </>
  );
};
