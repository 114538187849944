import React from 'react';
import { Chip } from 'primereact/chip';
import { format } from 'date-fns';
import './HolidaysList.scss';

interface Props {
  holidays: Date[];
  onRemove?: (date: Date) => void;
}

export const HolidaysList = ({ holidays, onRemove }: Props) => {
  if (!holidays.length) return null;

  const sortedHolidays = [...holidays].sort((a, b) => a.getTime() - b.getTime());

  return (
    <div className='holiday-chips'>
      {sortedHolidays.map((date) => (
        <Chip
          key={date.toISOString()}
          label={format(date, "d 'de' MMMM")}
          removable={!!onRemove}
          onRemove={onRemove ? () => onRemove(date) : undefined}
        />
      ))}
    </div>
  );
};
