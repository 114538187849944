import React, { useMemo } from 'react';
import { MultiSelect, MultiSelectChangeEvent, MultiSelectProps } from 'primereact/multiselect';
import { useOrganizationContext } from '../../context/OrganizationContext';
import { getHashMap } from '../../utils/objectTransformers';
import { Dropdown, DropdownChangeEvent, DropdownProps } from 'primereact/dropdown';
import { ValidationErrors } from '../../hooks/useForm';
import { Branch } from '../../types/branch';
import { ALL_SELECT_OPTION, getBranchOptions } from '../../utils/selectOptions';

interface BaseProps {
  onChange: ((branches: Branch[]) => void) | ((branch: Branch) => void);
  value?: string[] | string;
  id?: string;
  error?: string | (ValidationErrors<Branch> | undefined)[];
  className?: string;
  placeholder?: string;
  searchPlaceholder?: string;
  isSingle: boolean;
  selectAllOption?: boolean;
}

interface MultiProps extends BaseProps {
  isSingle: false;
  onChange: (branches: Branch[]) => void;
  value?: string[];
  selectAllOption?: never;
}
interface SingleSelectProps extends BaseProps {
  isSingle: true;
  onChange: (branch: Branch | undefined) => void;
  value?: string;
  selectAllOption?: boolean;
}

type Props = MultiProps | SingleSelectProps;

export const BranchesSelect = ({
  onChange,
  id = 'branches-dropdown',
  value,
  error,
  className,
  placeholder,
  searchPlaceholder = 'Buscar sucursales',
  isSingle,
  selectAllOption = false,
}: Props) => {
  const { orgBranches, organizationLoading } = useOrganizationContext() ?? {};

  const finalPlaceholder = placeholder ?? (isSingle ? 'Elige sucursal' : 'Elige sucursales');

  const options = useMemo(() => {
    return getBranchOptions(orgBranches, selectAllOption);
  }, [orgBranches, selectAllOption]);
  const branchesHashMap = useMemo(() => getHashMap(orgBranches ?? [], 'id'), [orgBranches]);

  const handleMultiChange = (e: MultiSelectChangeEvent) => {
    if (isSingle) return;
    const newBranches: Branch[] = e.value?.map((branchId: string) => branchesHashMap[branchId]);
    onChange(newBranches);
  };

  const handleSingleChange = (e: DropdownChangeEvent) => {
    if (!isSingle) return;
    if (e.value === ALL_SELECT_OPTION) {
      onChange(undefined);
      return;
    }
    const newBranch = branchesHashMap[e.value];
    if (!newBranch) return;
    onChange(newBranch);
  };

  const commonProps: DropdownProps & MultiSelectProps = {
    filter: true,
    id,
    placeholder: finalPlaceholder,
    value,
    className,
    invalid: !!error,
    options,
    emptyMessage: 'No hay sucursales',
    emptyFilterMessage: 'No se han encontrado sucursales',
    filterPlaceholder: searchPlaceholder,
    loading: organizationLoading,
  };

  return isSingle ? (
    <Dropdown clearIcon {...commonProps} onChange={handleSingleChange} />
  ) : (
    <MultiSelect {...commonProps} onChange={handleMultiChange} />
  );
};
