import React, { useMemo } from 'react';
import './EmployeesList.scss';

import { useClient } from '../../../hooks/useClient';
import { User } from '../../../types/user';
import { useOrganizationContext } from '../../../context/OrganizationContext';
import useSWR, { mutate } from 'swr';
import { Button } from 'primereact/button';
import { UserPanel } from '../../../components/UserPanel/UserPanel';
import { ResponsiveTable } from '../../../components/ResponsiveTable/ResponsiveTable';
import { TableColumn, TableAction } from '../../../types/responsiveTable';
import { useNavigate } from 'react-router-dom';
import { PrimeIcons } from 'primereact/api';
import { ItemDisplay } from '../../../types/responses';
import { useResourcePanel } from '../../../hooks/useResourcePanel';
import { showDeleteDialog } from '../../../utils/dialogUtils';
import { resourceDeleter } from '../../../utils/resourceMutation';

const getTableColumns = (): TableColumn<User>[] => [
  {
    label: '',
    accessor: 'avatarUrl',
    type: 'avatar',
  },
  {
    label: 'Nombre',
    accessor: 'name',
    type: 'string',
    isTitle: true,
    valueFromCallBack: (name, row) => `${name} ${row.surnames}${row.isInvitation ? ' (pendiente)' : ''}`,
  },
  {
    label: 'Rol',
    accessor: 'role',
    valueFromCallBack: (role) => [role.name],
    type: 'dropdown',
    hideBulletPoints: true,
  },
  {
    label: 'Sucursales',
    accessor: 'branches',
    type: 'dropdown',
    valueFromCallBack: (branches: ItemDisplay[]) => branches?.map((branch) => branch?.name) || '',
  },
];

export const EmployeesList = () => {
  const { get, delete: del } = useClient();
  const navigate = useNavigate();
  const { organization, organizationLoading } = useOrganizationContext() ?? {};

  const { data, isLoading } = useSWR(organization ? `/organizations/${organization.id}/users` : null, get<User[]>);
  const { isEditing, setIsEditing, panelVisible, closePanel, selectedResource, openPanel, isDeleting, setIsDeleting } =
    useResourcePanel(data);

  const handleDelete = async (id: string | undefined) => {
    if (!id || !organization?.id) return false;
    setIsDeleting(true);
    const url = `/users/${id}`;
    const response = await del<User>(url, {
      errorMessages: {
        summary: 'No se pudo eliminar el usuario',
        defaultDetail: 'Hubo un error al intentar borrar el usuario',
        [409]: 'El usuario no se puede eliminar porque está siendo usado',
      },
      successMessage: {
        summary: 'Usuario eliminado',
        detail: 'El usuario se ha eliminado correctamente',
      },
      handlers: {
        defaultSuccess: () => {
          mutate(`/organizations/${organization.id}/users`, resourceDeleter(id));
        },
      },
    });

    setIsDeleting(false);
    return !!response;
  };

  const tableHeaders: TableColumn<User>[] = useMemo(() => getTableColumns(), []);
  const options: TableAction[] = [
    {
      label: 'Ver',
      onClick: (id) => {
        openPanel(false, id);
      },
    },
    {
      label: 'Editar',
      onClick: (id) => {
        openPanel(true, id);
      },
    },
    {
      label: 'Eliminar',
      onClick: (id) => {
        showDeleteDialog(() => handleDelete(id));
      },
    },
  ];

  return (
    <>
      <ResponsiveTable
        data={data || []}
        columns={tableHeaders}
        options={options}
        loading={organizationLoading || isLoading}
        headerOptions={
          <>
            <Button
              label='Añadir empleado'
              outlined
              rounded
              icon={PrimeIcons.PLUS}
              onClick={() => {
                openPanel(false);
              }}
            />
            <Button
              label='Ver roles'
              outlined
              text
              size='large'
              onClick={() => {
                navigate('/employees/roles');
              }}
            />
          </>
        }
        onRowClick={(row) => {
          openPanel(false, row.id);
        }}
      ></ResponsiveTable>
      <UserPanel
        resource={selectedResource}
        visible={panelVisible}
        onHide={closePanel}
        onIsEditingChange={setIsEditing}
        onDeleteResource={handleDelete}
        isDeleting={isDeleting}
        isEditing={isEditing}
      />
    </>
  );
};
