const css = `.kpi-container .p-card-body {
  width: 100%;
}
.kpi-container .p-card-content {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
}
.kpi-container .kpi-value-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.kpi-container span.bold {
  font-weight: 800;
  display: flex;
  gap: 4px;
}
.kpi-container div.previous {
  font-weight: 400;
}
.kpi-container div.previous span:nth-child(1) {
  font-size: 8px;
}
.kpi-container div.previous span:nth-child(2) {
  font-size: 12px;
}
.kpi-container div.increase {
  color: var(--green-500);
}
.kpi-container div.decrease {
  color: var(--pink-500);
}
.kpi-container .pi {
  border-radius: 8px;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9yZW5kZXIvcHJvamVjdC9zcmMvc3JjL2NvbXBvbmVudHMvS3BpQ2FyZCIsInNvdXJjZXMiOlsiS3BpQ2FyZC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUdFO0VBQ0U7O0FBRUY7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUVGO0VBQ0U7RUFDQTtFQUNBOztBQUVGO0VBQ0U7RUFDQTtFQUNBOztBQUVGO0VBQ0U7O0FBRUE7RUFDRTs7QUFFRjtFQUNFOztBQUdKO0VBQ0U7O0FBRUY7RUFDRTs7QUFFRjtFQUNFIiwic291cmNlc0NvbnRlbnQiOlsiQGltcG9ydCAnLi4vLi4vc3R5bGVzL21peGlucyc7XG5cbi5rcGktY29udGFpbmVyIHtcbiAgLnAtY2FyZC1ib2R5IHtcbiAgICB3aWR0aDogMTAwJTtcbiAgfVxuICAucC1jYXJkLWNvbnRlbnQge1xuICAgIHdpZHRoOiAxMDAlO1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgZmxleC1kaXJlY3Rpb246IHJvdztcbiAgICBqdXN0aWZ5LWNvbnRlbnQ6IHNwYWNlLWJldHdlZW47XG4gICAgYWxpZ24taXRlbXM6IHN0YXJ0O1xuICB9XG4gIC5rcGktdmFsdWUtY29udGFpbmVyIHtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG4gICAgZ2FwOiAxNnB4O1xuICB9XG4gIHNwYW4uYm9sZCB7XG4gICAgZm9udC13ZWlnaHQ6IDgwMDtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGdhcDogNHB4O1xuICB9XG4gIGRpdi5wcmV2aW91cyB7XG4gICAgZm9udC13ZWlnaHQ6IDQwMDtcblxuICAgICYgc3BhbjpudGgtY2hpbGQoMSkge1xuICAgICAgZm9udC1zaXplOiA4cHg7XG4gICAgfVxuICAgICYgc3BhbjpudGgtY2hpbGQoMikge1xuICAgICAgZm9udC1zaXplOiAxMnB4O1xuICAgIH1cbiAgfVxuICBkaXYuaW5jcmVhc2Uge1xuICAgIGNvbG9yOiB2YXIoLS1ncmVlbi01MDApO1xuICB9XG4gIGRpdi5kZWNyZWFzZSB7XG4gICAgY29sb3I6IHZhcigtLXBpbmstNTAwKTtcbiAgfVxuICAucGkge1xuICAgIGJvcmRlci1yYWRpdXM6IDhweDtcbiAgfVxufVxuIl19 */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
