const css = `.sidebar-button.p-button.p-button-link {
  padding: 10px 14px;
  width: 100%;
  height: 40px;
  text-align: left;
  font-size: 14px;
  color: var(--primary600);
  transition: background-color 0.2s, box-shadow 0.2s, padding 0.5s ease;
}
.sidebar-button.p-button.p-button-link:focus, .sidebar-button.p-button.p-button-link:active {
  outline: 0 none;
  outline-offset: 0;
  transition: box-shadow 0.2s;
  box-shadow: inset var(--focus-ring) !important;
}
.sidebar-button.p-button.p-button-link .p-button-label {
  font-size: 14px;
  transition: none;
  font-weight: 400;
  text-decoration: none;
  white-space: nowrap;
}
.sidebar-button.p-button.p-button-link .p-button-icon {
  font-size: 14px;
  margin-right: auto;
}
.sidebar-button.p-button.p-button-link:hover {
  background-color: var(--surface-hover);
}
.sidebar-button.p-button.p-button-link.selected {
  color: var(--disruptive);
}
.sidebar-button.p-button.p-button-link.selected span {
  font-weight: 700;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9yZW5kZXIvcHJvamVjdC9zcmMvc3JjL2xheW91dC9TaWRlYmFyL1NpZGViYXJCdXR0b24iLCJzb3VyY2VzIjpbIlNpZGViYXJCdXR0b24uc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBLFlBQ0U7O0FBSUY7RUFFRTtFQUNBO0VBQ0E7RUFDQTs7QUFFRjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7O0FBR0Y7RUFDRTtFQUNBOztBQUdGO0VBQ0U7O0FBR0Y7RUFDRTs7QUFDQTtFQUNFIiwic291cmNlc0NvbnRlbnQiOlsiLnNpZGViYXItYnV0dG9uLnAtYnV0dG9uLnAtYnV0dG9uLWxpbmsge1xuICBwYWRkaW5nOiAxMHB4IDE0cHg7XG4gIHdpZHRoOiAxMDAlO1xuICBoZWlnaHQ6IDQwcHg7XG4gIHRleHQtYWxpZ246IGxlZnQ7XG4gIGZvbnQtc2l6ZTogMTRweDtcbiAgY29sb3I6IHZhcigtLXByaW1hcnk2MDApO1xuICB0cmFuc2l0aW9uOlxuICAgIGJhY2tncm91bmQtY29sb3IgMC4ycyxcbiAgICBib3gtc2hhZG93IDAuMnMsXG4gICAgcGFkZGluZyAwLjVzIGVhc2U7XG5cbiAgJjpmb2N1cyxcbiAgJjphY3RpdmUge1xuICAgIG91dGxpbmU6IDAgbm9uZTtcbiAgICBvdXRsaW5lLW9mZnNldDogMDtcbiAgICB0cmFuc2l0aW9uOiBib3gtc2hhZG93IDAuMnM7XG4gICAgYm94LXNoYWRvdzogaW5zZXQgdmFyKC0tZm9jdXMtcmluZykgIWltcG9ydGFudDtcbiAgfVxuICAucC1idXR0b24tbGFiZWwge1xuICAgIGZvbnQtc2l6ZTogMTRweDtcbiAgICB0cmFuc2l0aW9uOiBub25lO1xuICAgIGZvbnQtd2VpZ2h0OiA0MDA7XG4gICAgdGV4dC1kZWNvcmF0aW9uOiBub25lO1xuICAgIHdoaXRlLXNwYWNlOiBub3dyYXA7XG4gIH1cblxuICAucC1idXR0b24taWNvbiB7XG4gICAgZm9udC1zaXplOiAxNHB4O1xuICAgIG1hcmdpbi1yaWdodDogYXV0bztcbiAgfVxuXG4gICY6aG92ZXIge1xuICAgIGJhY2tncm91bmQtY29sb3I6IHZhcigtLXN1cmZhY2UtaG92ZXIpO1xuICB9XG5cbiAgJi5zZWxlY3RlZCB7XG4gICAgY29sb3I6IHZhcigtLWRpc3J1cHRpdmUpO1xuICAgIHNwYW4ge1xuICAgICAgZm9udC13ZWlnaHQ6IDcwMDtcbiAgICB9XG4gIH1cbn1cbiJdfQ== */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
