import React from 'react';
import { PageTemplate } from '../../layout/PageTemplate/PageTemplate';

import { Button } from 'primereact/button';
import { ResponsiveTable } from '../../components/ResponsiveTable/ResponsiveTable';
import { TableColumn, TableAction } from '../../types/responsiveTable';
import { useClient } from '../../hooks/useClient';
import { useOrganizationContext } from '../../context/OrganizationContext';
import useSWR, { mutate } from 'swr';
import { Routine } from '../../types/Fisiofit/routine';
import { differenceInDays, startOfDay } from 'date-fns';
import { PrimeIcons } from 'primereact/api';
import { RoutinePanel } from '../../components/RoutinePanel/RoutinePanel';
import { useResourcePanel } from '../../hooks/useResourcePanel';
import { resourceDeleter } from '../../utils/resourceMutation';
import { showDeleteDialog } from '../../utils/dialogUtils';

export const getRoutineDays = (startDate: string | undefined, duration: number | null) => {
  const daysDifference = startDate ? differenceInDays(startOfDay(new Date()), startOfDay(new Date(startDate))) : null;
  const elapsedDays = Math.max(0, Math.min(daysDifference ?? 0, duration ?? 0));
  const hasStarted = daysDifference !== null && daysDifference >= 0;
  const remainingDays = duration ? duration - elapsedDays : null;
  return { daysDifference, elapsedDays, hasStarted, remainingDays };
};

export const getRoutineDaysSeverity = (
  startDate: string | undefined,
  duration: number | null,
  hasNextRoutine: boolean,
  isExpired: boolean,
): 'warning' | 'error' | null => {
  const { remainingDays, hasStarted } = getRoutineDays(startDate, duration);
  if (isExpired) return 'error';
  if (!hasStarted) return null;
  if (hasNextRoutine) return null;
  if (remainingDays !== null && remainingDays <= 3) return 'error';
  if (remainingDays !== null && remainingDays <= 5) return 'warning';
  return null;
};

const getRowDaysRemainingSeverity = (_: any, row: Routine) => {
  return getRoutineDaysSeverity(row.localStartDate, row.duration, !!row.nextRoutine, !!row.isExpired);
};

export const Routines = () => {
  const { get, delete: del } = useClient();
  const { organization, organizationLoading } = useOrganizationContext() ?? {};

  const { data, isLoading } = useSWR(organization ? `/organizations/${organization.id}/routines` : null, get<Routine[]>);
  const { isEditing, setIsEditing, panelVisible, closePanel, selectedResource, openPanel, isDeleting, setIsDeleting } =
    useResourcePanel(data);

  const handleDelete = async (id: string | undefined): Promise<boolean> => {
    if (!id || !organization?.id) return false;
    setIsDeleting(true);
    const url = `/routines/${id}`;
    const response = await del<Routine>(url, {
      errorMessages: {
        summary: 'No se pudo eliminar la rutina',
        defaultDetail: 'Hubo un error al intentar borrar la rutina',
        [409]: 'La rutina no se puede eliminar porque está asignada a un usuario',
      },
      successMessage: {
        summary: 'Rutina eliminada',
        detail: 'La rutina se ha eliminado correctamente',
      },
      handlers: {
        defaultSuccess: () => {
          mutate(`/organizations/${organization.id}/routines`, resourceDeleter(id));
        },
      },
    });
    setIsDeleting(false);
    return !!response;
  };

  const tableHeaders: TableColumn<Routine>[] = [
    {
      label: 'Nombre',
      accessor: 'name',
      type: 'string',
      isTitle: true,
    },
    {
      label: 'Tipo',
      accessor: 'externalUser',
      colorFromCallBack: (_, row) => {
        return row.externalUser?.id ? 'primary' : 'secondary';
      },
      textKeyFromCallBack: (_, row) => {
        return row.externalUser?.id ? 'usuario' : 'plantilla';
      },
      type: 'tag',
    },
    {
      label: 'Duración (días)',
      accessor: 'duration',
      type: 'number',
    },
    {
      label: 'Días restantes',
      accessor: 'localStartDate',
      type: 'number',
      valueFromCallBack: (_, row) => {
        if (!row.localStartDate || !row.duration) return '-';

        const { elapsedDays, hasStarted } = getRoutineDays(row.localStartDate, row.duration);

        if (!hasStarted || row.isExpired) return '-';

        return (row.duration - elapsedDays).toString();
      },
      severityCallback: (_, row) => {
        if (row.isExpired) return null;
        return getRowDaysRemainingSeverity(_, row);
      },
    },
    {
      label: 'Progreso',
      accessor: 'duration',
      type: 'progress',
      truncate: true,
      percentageValueFromCallBack: (_, row) => {
        const { elapsedDays, daysDifference } = getRoutineDays(row.localStartDate, row.duration);
        const hasStarted = daysDifference !== null && daysDifference >= 0;
        if (!hasStarted) return null;
        return (elapsedDays / row.duration) * 100;
      },
      severityCallback: (_, row) => {
        const { hasStarted } = getRoutineDays(row.localStartDate, row.duration);
        if (!hasStarted) return null;
        return getRowDaysRemainingSeverity(_, row) ?? 'success';
      },
      overrideMessage: (_, row) => {
        if (!row.externalUser) return null;
        const { hasStarted, daysDifference } = getRoutineDays(row.localStartDate, row.duration);
        if (!hasStarted && daysDifference !== null) {
          const daysAbs = Math.abs(daysDifference);
          if (daysAbs === 1) return 'Empieza mañana';
          return 'Empieza en ' + daysAbs + ' días';
        }
        if (row.isExpired) {
          return 'Rutina caducada';
        }
        return null;
      },
    },
    {
      label: 'Próxima rutina',
      accessor: 'nextRoutine',
      type: 'boolean',
      valueFromCallBack: (nextRoutine, row) => {
        if (!row.localStartDate) return null;
        return !!nextRoutine;
      },
      trueText: 'Sí',
      falseText: 'No',
      trueIcon: PrimeIcons.CHECK_CIRCLE,
      falseIcon: PrimeIcons.TIMES_CIRCLE,
    },
  ];

  const options: TableAction[] = [
    {
      label: 'Ver',
      onClick: (id) => {
        openPanel(false, id);
      },
    },
    {
      label: 'Editar',
      onClick: (id) => {
        openPanel(true, id);
      },
    },
    {
      label: 'Eliminar',
      onClick: (id) => {
        showDeleteDialog(() => handleDelete(id));
      },
    },
  ];

  return (
    <PageTemplate className='employees-page' title='Rutinas'>
      <ResponsiveTable
        data={data || []}
        columns={tableHeaders}
        options={options}
        loading={organizationLoading || isLoading}
        headerOptions={
          <Button
            label='Añadir rutina'
            outlined
            rounded
            icon={PrimeIcons.PLUS}
            onClick={() => {
              openPanel(false);
            }}
          />
        }
        onRowClick={(row) => {
          openPanel(false, row.id);
        }}
      ></ResponsiveTable>
      <RoutinePanel
        resource={selectedResource}
        visible={panelVisible}
        onHide={closePanel}
        onIsEditingChange={(value) => setIsEditing(value)}
        isEditing={isEditing}
        onDeleteResource={handleDelete}
        isDeleting={isDeleting}
      ></RoutinePanel>
    </PageTemplate>
  );
};
