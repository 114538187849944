export const validatePassword = (password: string): boolean => {
  const strongPasswordRegex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/;
  return strongPasswordRegex.test(password);
};
export const validateKebabCase = (str: string): boolean => {
  const kebabCaseRegex = /^[a-z0-9]+(-[a-z0-9]+)*$/;
  return kebabCaseRegex.test(str);
};
export const validateEmail = (email: string): boolean => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};
export const validateLettersAndSpaces = (str: string): boolean => {
  // Any Unicode letter (from any language) and spaces
  const regex = /^[\p{L}\s]+$/u;
  return regex.test(str);
};
export const validateLettersSpacesAndNumbers = (str: string): boolean => {
  const regex = /^[\p{L}\s\d]+$/u;
  return regex.test(str);
};
export const validatePhone = (phone: string): boolean => {
  const phoneRegex = /^\+?\d{1,4}[-.\s]?\(?\d{1,4}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,4}$/;
  return phoneRegex.test(phone);
};
