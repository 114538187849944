import { PrimeIcons } from 'primereact/api';
import { MultiStateCheckbox } from 'primereact/multistatecheckbox';
import React from 'react';
import './NumericFilter.scss';
import { InputNumber } from 'primereact/inputnumber';

interface NumericFilterValue {
  mode: string;
  value: number | null;
}

const options = [
  { value: 'equal', icon: 'pi pi-equals' },
  { value: 'greater-than', icon: PrimeIcons.CHEVRON_RIGHT },
  { value: 'less-than', icon: PrimeIcons.CHEVRON_LEFT },
];

interface Props {
  onChange?: (value: NumericFilterValue) => void;
  value: NumericFilterValue[] | undefined;
  placeholder?: string;
}

export const NumericFilter = ({ onChange, value, placeholder }: Props) => {
  const { mode = 'equal', value: filterValue = null } = value?.[0] ?? {};
  const handleOnChange = (newMode: string, newValue: number | null) => {
    onChange?.({ mode: newMode, value: newValue });
  };

  return (
    <div className='numeric-filter-container'>
      <MultiStateCheckbox
        value={mode}
        onChange={(e) => handleOnChange(e.value, filterValue)}
        options={options}
        empty={false}
        optionValue='value'
      />
      <InputNumber
        value={filterValue}
        useGrouping={false}
        onChange={(e) => handleOnChange(mode, e.value)}
        placeholder={placeholder}
      />
    </div>
  );
};
