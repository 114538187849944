import { Feature } from '../../config/features';
import { FormValidators, VALIDATION_MESSAGES } from '../../hooks/useForm';
import { validateLettersAndSpaces, validateEmail, validatePhone } from '../../utils/validationUtils';

const {
  NOT_NAME,
  ONLY_LETTERS_AND_SPACES,
  NOT_EMAIL,
  NOT_PHONE,
  NOT_DATE_OF_BIRTH,
  NOT_BRANCHES,
  NOT_ROLE,
  NOT_ROLE_NAME,
  NOT_ROLE_FEATURES,
  NOT_SURNAMES,
} = VALIDATION_MESSAGES;

export interface UserForm {
  name?: string;
  surnames?: string;
  email?: string;
  branchIds?: string[];
  roleId?: string;
  roleName?: string;
  roleFeatures?: Set<Feature>;
  dateOfBirth?: string;
  phone?: string;
}

export const employeeFormValidators = (isCreatingNewRole: boolean): FormValidators<UserForm> => {
  return {
    name: (user) => {
      if (!user.name?.length) return NOT_NAME;
      if (!validateLettersAndSpaces(user.name)) return ONLY_LETTERS_AND_SPACES;
    },
    surnames: (user) => {
      if (!user.surnames?.length) return NOT_SURNAMES;
      if (!validateLettersAndSpaces(user.surnames)) return ONLY_LETTERS_AND_SPACES;
    },
    email: (user) => {
      if (!user.email || !validateEmail(user.email)) return NOT_EMAIL;
    },
    dateOfBirth: (user) => {
      if (!user.dateOfBirth) return NOT_DATE_OF_BIRTH;
    },
    phone: (user) => {
      if (!user.phone || !validatePhone(user.phone)) return NOT_PHONE;
    },
    branchIds: (user) => {
      if (user.branchIds?.length === 0 || !user.branchIds) return NOT_BRANCHES;
    },
    roleId: (user) => {
      if (!isCreatingNewRole && !user.roleId) return NOT_ROLE;
    },
    roleName: (user) => {
      if (isCreatingNewRole && !user.roleName) return NOT_ROLE_NAME;
    },
    roleFeatures: (user) => {
      if (isCreatingNewRole && user.roleFeatures?.size === 0) return NOT_ROLE_FEATURES;
    },
  };
};
