import React from 'react';
import { Avatar } from 'primereact/avatar';
import { Tag } from 'primereact/tag';
import { Skeleton } from 'primereact/skeleton';
import { Button } from 'primereact/button';
import { PrimeIcons } from 'primereact/api';
import { TableColumn } from '../../types/responsiveTable';
import { format } from 'date-fns';
import { classNames } from 'primereact/utils';
import { getUserAvatarProps } from '../../utils/avatarUtils';
import { Progress } from '../Progress/Progress';

interface TableRowsProps<T extends Record<any, string>> {
  data: any[];
  columns: TableColumn<T>[];
  loading?: boolean;
  onOptionsClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => void;
  onRowClick?: (row: any) => void;
}

export const TableRows = <T extends Record<any, string>>({
  data,
  columns: headers,
  onOptionsClick,
  loading,
  onRowClick,
}: TableRowsProps<T>) => {
  const createRow = (row: T, columns: TableColumn<T>[]) => {
    const tableRow: any[] = [];
    columns.forEach((column, index) => {
      const possibleFallbackName = row.name;
      const possibleFallbackSurnames = row.surnames;
      const fieldValue = row[column.accessor];

      switch (column.type) {
        case 'avatar':
          tableRow.push(
            <td className='avatar' key={`image-${index}-${row.id}`} style={{ justifyContent: 'center' }}>
              <Avatar
                {...getUserAvatarProps({
                  name: possibleFallbackName,
                  surnames: possibleFallbackSurnames,
                  avatarUrl: fieldValue?.toString(),
                })}
                shape='circle'
                size='large'
              />
            </td>,
          );
          return;
        case 'boolean': {
          const boolValue = column.valueFromCallBack ? column.valueFromCallBack(row[column.accessor], row) : row[column.accessor];

          tableRow.push(
            <td className='boolean' key={`boolean-${index}-${row.id}`}>
              <span className='cell-header'>{columns[index].label}:</span>
              {boolValue !== null && (
                <span className={`boolean-value ${boolValue ? 'true' : 'false'}`}>
                  <i
                    className={`pi ${boolValue ? column.trueIcon || PrimeIcons.CHECK_CIRCLE : column.falseIcon || PrimeIcons.TIMES_CIRCLE}`}
                  ></i>
                  <span className='boolean-text'>{boolValue ? column.trueText || 'Sí' : column.falseText || 'No'}</span>
                </span>
              )}
            </td>,
          );
          return;
        }
        case 'dropdown': {
          const value = column.valueFromCallBack ? column.valueFromCallBack(row[column.accessor], row) : row[column.accessor];
          tableRow.push(
            <td className='dropdown' key={`dropdown-${index}-${row.id}`}>
              <span className='cell-header'>{columns[index].label}:</span>
              {Array.isArray(value) ? (
                <ul>
                  {value.map((v: string) => (
                    <li
                      key={`dropdown-${index}-${v}`}
                      className={classNames('list-item', { 'no-decorated': column.hideBulletPoints })}
                    >
                      {v}
                    </li>
                  ))}
                </ul>
              ) : (
                <span>{value?.toString() || '-'}</span>
              )}
            </td>,
          );
          return;
        }
        case 'string': {
          const stringValue = column.valueFromCallBack
            ? column.valueFromCallBack(row[column.accessor], row)
            : row[column.accessor];
          tableRow.push(
            <td className='string' key={`string-${index}-${row.id}`}>
              {!column.isTitle && <span className='cell-header'>{columns[index].label}:</span>}
              <span className={column.isTitle ? 'cell-header-text' : ''}>{stringValue?.toString() || '-'}</span>
            </td>,
          );
          return;
        }
        case 'calendar': {
          const date = column.valueFromCallBack ? column.valueFromCallBack(row[column.accessor], row) : row[column.accessor];
          tableRow.push(
            <td className='calendar' key={`calendar-${index}-${row.id}`}>
              <span className='cell-header'>{columns[index].label}:</span>
              <span>{date ? format(new Date(date?.toString()), 'dd/MM/yyyy') : '-'}</span>
            </td>,
          );
          return;
        }
        case 'tag': {
          const tagLabel = column.textKeyFromCallBack
            ? column.textKeyFromCallBack(row[column.accessor], row)
            : row[column.accessor];
          const color = column.colorFromCallBack(row[column.accessor], row);
          tableRow.push(
            <td className='tag' key={`tag-${index}-${row.id}`}>
              <span className='cell-header'>{columns[index].label}:</span>
              {tagLabel ? <Tag value={tagLabel?.toString()} {...(color === 'primary' ? {} : { severity: color })} /> : '-'}
            </td>,
          );
          return;
        }
        case 'number': {
          const numberValue = column.valueFromCallBack
            ? column.valueFromCallBack(row[column.accessor], row)
            : row[column.accessor];
          const severity = column.severityCallback ? column.severityCallback(numberValue, row) : null;
          tableRow.push(
            <td className={classNames('number', severity)} key={`number-${index}-${row.id}`}>
              <span className='cell-header'>{columns[index].label}:</span>
              <span className='number-cell-value'>
                {numberValue?.toString() || '-'}
                {severity && (
                  <i
                    className={classNames('cell-severity-icon', {
                      [PrimeIcons.EXCLAMATION_CIRCLE]: severity === 'error' || severity === 'warning',
                      [PrimeIcons.CHECK_CIRCLE]: severity === 'success',
                    })}
                  />
                )}
              </span>
            </td>,
          );
          return;
        }
        case 'progress': {
          const percentage = column.percentageValueFromCallBack
            ? column.percentageValueFromCallBack(row[column.accessor], row)
            : row[column.accessor];
          const severity = column.severityCallback ? column.severityCallback(percentage, row) : null;
          const overrideMessage = column.overrideMessage ? column.overrideMessage(percentage, row) : null;

          tableRow.push(
            <td className='progress' key={`progress-${index}-${row.id}`}>
              <span className='cell-header'>{columns[index].label}:</span>
              {percentage !== null && !overrideMessage && (
                <Progress
                  value={Number(percentage)}
                  showValue
                  truncate={column.truncate}
                  {...(severity ? { color: severity } : {})}
                />
              )}
              {overrideMessage && (
                <span className={classNames('override-message', severity)}>
                  {severity && (
                    <i
                      className={classNames('cell-severity-icon', {
                        [PrimeIcons.EXCLAMATION_CIRCLE]: severity === 'error' || severity === 'warning',
                        [PrimeIcons.CHECK_CIRCLE]: severity === 'success',
                      })}
                    />
                  )}
                  {overrideMessage}
                </span>
              )}
            </td>,
          );
          return;
        }
        default:
          return;
      }
    });
    return tableRow;
  };

  return (
    <>
      {loading &&
        Array.from({ length: 5 }).map((_, rowIndex) => (
          <tr key={rowIndex}>
            {Array.from({ length: 4 }).map((_, colIndex) => (
              <td key={colIndex}>
                <Skeleton width='100%' height='1.5rem' />
              </td>
            ))}
          </tr>
        ))}
      {!loading &&
        data.map((row: any, index: number) => (
          <tr onClick={() => onRowClick && onRowClick(row)} key={'key-' + index} style={{ cursor: 'pointer' }}>
            {createRow(row, headers)}
            <td className='option'>
              <Button
                icon={PrimeIcons.ELLIPSIS_V}
                text
                rounded
                className='option-button-desktop'
                onClick={(event) => {
                  event.stopPropagation();
                  onOptionsClick(event, row.id);
                }}
              ></Button>
              <Button
                icon={PrimeIcons.ELLIPSIS_H}
                text
                rounded
                className='option-button-mobile'
                onClick={(event) => {
                  event.stopPropagation();
                  onOptionsClick(event, row.id);
                }}
              ></Button>
            </td>
          </tr>
        ))}
    </>
  );
};
