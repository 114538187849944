import React, { useMemo } from 'react';
import { MultiSelect, MultiSelectChangeEvent, MultiSelectProps } from 'primereact/multiselect';
import useSWR from 'swr';
import { useClient } from '../../hooks/useClient';
import { useOrganizationContext } from '../../context/OrganizationContext';
import { getHashMap } from '../../utils/objectTransformers';
import { Dropdown, DropdownChangeEvent, DropdownProps } from 'primereact/dropdown';
import { UserDisplay } from '../UserDisplay/UserDisplay';
import { ValidationErrors } from '../../hooks/useForm';
import { UserShort } from '../../types/user';

const getUsersUrl = (organizationId: string, params: { short?: boolean; branches?: string }) => {
  const queryParams = Object.entries(params)
    .filter(([_, value]) => value)
    .map(([key, value]) => {
      if (typeof value === 'string') {
        return `${key}=${value}`;
      }
      return `${key}=true`;
    });

  return `/organizations/${organizationId}/users${queryParams.length ? '?' + queryParams.join('&') : ''}`;
};

interface UserSelectItem extends UserShort {
  label: string;
  value: string;
}

const getUserOptions = (users?: UserShort[]): UserSelectItem[] | undefined => {
  return users?.map((user) => ({
    label: `${user.name} ${user.surnames}`,
    value: user.id,
    ...user,
  }));
};

const userTemplate = (option: UserSelectItem) => {
  return <UserDisplay fullName={option.label} avatarUrl={option.avatarUrl} />;
};

interface BaseProps {
  onChange: ((users: UserShort[]) => void) | ((user: UserShort) => void);
  value?: string[] | string;
  id?: string;
  error?: string | (ValidationErrors<UserShort> | undefined)[];
  className?: string;
  placeholder?: string;
  searchPlaceholder?: string;
  isSingle: boolean;
  filterByBranchIds?: string[];
  disabled?: boolean;
}

interface MultiProps extends BaseProps {
  isSingle: false;
  onChange: (users: UserShort[]) => void;
  value?: string[];
}
interface SingleSelectProps extends BaseProps {
  isSingle: true;
  onChange: (user: UserShort) => void;
  value?: string;
}

type Props = MultiProps | SingleSelectProps;

export const UsersSelect = ({
  onChange,
  id = 'users-dropdown',
  value,
  error,
  className,
  placeholder,
  searchPlaceholder = 'Busca empleados',
  isSingle,
  filterByBranchIds,
  disabled = false,
}: Props) => {
  const { get } = useClient();
  const { organization, organizationLoading } = useOrganizationContext() ?? {};
  const finalPlaceholder = placeholder ?? (isSingle ? 'Elige empleado' : 'Elige empleados');

  const { data: users, isLoading: usersLoading } = useSWR(
    organization ? getUsersUrl(organization.id, { short: true, branches: filterByBranchIds?.join(',') }) : null,
    get<UserShort[]>,
  );

  const usersHashMap = useMemo(() => getHashMap(users ?? [], 'id'), [users]);

  const handleMultiChange = (e: MultiSelectChangeEvent) => {
    if (isSingle) return;
    const newUsers: UserShort[] = e.value?.map((userId: string) => usersHashMap[userId]);
    onChange(newUsers);
  };

  const handleSingleChange = (e: DropdownChangeEvent) => {
    if (!isSingle) return;
    const newUser = usersHashMap[e.value];
    if (!newUser) return;
    onChange(newUser);
  };

  const commonProps: DropdownProps & MultiSelectProps = {
    filter: true,
    id,
    placeholder: finalPlaceholder,
    value,
    className,
    invalid: !!error,
    options: getUserOptions(users),
    itemTemplate: userTemplate,
    emptyMessage: 'No hay usuarios',
    emptyFilterMessage: 'No se han encontrado usuarios',
    filterPlaceholder: searchPlaceholder,
    loading: usersLoading || organizationLoading,
    disabled,
  };

  return isSingle ? (
    <Dropdown {...commonProps} onChange={handleSingleChange} />
  ) : (
    <MultiSelect {...commonProps} onChange={handleMultiChange} />
  );
};
