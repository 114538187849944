import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { confirmDialog } from 'primereact/confirmdialog';
import React, { ReactElement, ReactNode } from 'react';
import './Panel.scss';
import classNames from 'classnames';
import { PrimeIcons } from 'primereact/api';
import { showCancelDialog, showDeleteDialog } from '../../utils/dialogUtils';

// If no title or HeaderComponent is passed, it will be generated from the resourceName
const getPanelTitle = (isCreating: boolean, isEditing: boolean, resourceName: string | undefined): string => {
  if (!resourceName) {
    return '';
  }
  if (!isCreating && isEditing) {
    return `Editar ${resourceName}`;
  }
  if (!isCreating && !isEditing) {
    return `Detalle de ${resourceName}`;
  }
  if (isCreating && !isEditing) {
    return `Añadir ${resourceName}`;
  }
  return `${resourceName}`;
};

export interface ResourcePanelImplProps<T> {
  resource: T | undefined;
  visible: boolean;
  onHide: () => void;
  onIsEditingChange: (isEditing: boolean) => void;
  onDeleteResource: (id: string | undefined) => Promise<boolean>;
  isDeleting: boolean;
  isEditing: boolean;
  deleteConfirmation?: boolean;
}

export type PanelType = 'resourcePanel' | 'readonlyPanel';

interface BaseProps {
  // Common props
  title?: string;
  HeaderComponent?: ReactElement;
  visible: boolean;
  panelType: PanelType;
  onHide: () => void;
  children: ReactNode;
  className?: string;
  isSaving?: boolean;

  // ResourcePanel props
  resourceName?: string;
  resetForm?: () => void;
  onDelete?: () => Promise<boolean>;
  onSave?: () => Promise<boolean>;
  onIsEditingChange?: (isEditing: boolean) => void;
  hasChanged?: boolean;
  isEditing?: boolean;
  isCreating?: boolean;
  isDeleting?: boolean;
  disableButtons?: boolean;
  hasCustomDelete?: boolean;
  editButtonText?: string;
  showEditButton?: boolean;
  showAttendanceButton?: boolean;
  onAttendanceClick?: () => void;
}

interface ReadonlyPanelProps extends BaseProps {
  panelType: 'readonlyPanel';
}

interface ResourcePanelProps extends BaseProps {
  panelType: 'resourcePanel';
  resourceName: string;
  resetForm: () => void;
  onDelete?: () => Promise<boolean>;
  onSave: () => Promise<boolean>;
  onIsEditingChange: (isEditing: boolean) => void;
  hasChanged: boolean;
  isEditing: boolean;
  isCreating: boolean;
  isDeleting: boolean;
  isSaving: boolean;
  disableButtons?: boolean;
  hasCustomDelete?: boolean;
}

type Props = ReadonlyPanelProps | ResourcePanelProps;

export const Panel = ({
  HeaderComponent,
  panelType,
  title,
  resourceName,
  visible,
  resetForm,
  onHide,
  onDelete,
  onSave,
  onIsEditingChange,
  hasChanged,
  children,
  className,
  isSaving = false,
  isEditing = false,
  isCreating = false,
  isDeleting = false,
  disableButtons = false,
  hasCustomDelete = false,
  editButtonText = 'Editar',
  showEditButton = false,
  showAttendanceButton = false,
  onAttendanceClick,
}: Props) => {
  const isResourcePanel = panelType === 'resourcePanel';
  const showSave = (isEditing || isCreating) && isResourcePanel;
  const showEdit = !isCreating && !isEditing && isResourcePanel;
  const showDelete = !!onDelete && !isCreating && !isEditing && isResourcePanel;
  const disableAllButtons = isDeleting || isSaving || disableButtons;

  const saveLabel = isCreating ? `Crear ${resourceName}` : `Guardar ${resourceName}`;
  const editLabel = `Editar ${resourceName}`;

  const confirmDelete = () => {
    if (!onDelete) return;
    if (hasCustomDelete) {
      onDelete();
    } else {
      showDeleteDialog(async () => {
        const success = await onDelete();
        if (success) onHide();
      });
    }
  };

  const handleCancel = () => {
    if (hasChanged) {
      showCancelDialog(() => {
        onIsEditingChange?.(false);
        resetForm?.();
      });
    } else {
      onIsEditingChange?.(false);
      resetForm?.();
    }
  };

  const handleClose = () => {
    if (hasChanged) {
      confirmDialog({
        message: '¿Deseas cerrar el panel? Perderás los cambios no guardados.',
        header: 'Confirmar cierre',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Sí, cerrar',
        rejectLabel: 'No, continuar editando',
        accept: () => {
          resetForm?.();
          onHide();
        },
        acceptClassName: 'p-button p-button-text',
        rejectClassName: 'p-button-secondary p-button-text',
        dismissableMask: false,
      });
    } else {
      resetForm?.();
      onHide();
    }
  };

  const handleSave = async () => {
    if (!onSave) return;
    const success = await onSave();
    if (success) {
      onHide();
      if (isCreating) {
        resetForm?.();
      }
    }
  };

  const renderEditButton = () => {
    if (isCreating || !onIsEditingChange || !showEditButton) return null;
    return (
      <Button
        key='edit'
        icon={isEditing ? PrimeIcons.TIMES : 'pi pi-file-edit'}
        label={isEditing ? 'Cancelar' : editButtonText}
        onClick={() => {
          if (isEditing) {
            resetForm?.();
          }
          onIsEditingChange(!isEditing);
        }}
        disabled={disableAllButtons}
        className={classNames('pull-right', {
          'p-button-text p-button-danger': isEditing,
        })}
      />
    );
  };

  const header = (
    <div className='panel-header'>
      {HeaderComponent ?? <h4>{title ? title : getPanelTitle(isCreating, isEditing, resourceName)}</h4>}
      <div className='header-right'>
        <Button icon='pi pi-times' className='p-button-text' onClick={handleClose} rounded />
      </div>
    </div>
  );

  const renderFooter = () =>
    showSave || showEdit ? (
      <div className='panel-footer'>
        {showSave ? (
          <>
            {!isCreating && (
              <Button
                label='Cancelar'
                key='cancel'
                className='p-button-text'
                severity='secondary'
                onClick={handleCancel}
                disabled={disableAllButtons}
              />
            )}
            <Button
              className='pull-right'
              key='save'
              label={saveLabel}
              onClick={handleSave}
              loading={isSaving}
              disabled={disableAllButtons || !hasChanged}
              icon={PrimeIcons.SAVE}
            />
          </>
        ) : (
          <>
            {showAttendanceButton ? (
              <Button
                icon='pi pi-file-edit'
                label='Asistencias'
                className='p-button-secondary'
                onClick={() => onAttendanceClick?.()}
                disabled={disableAllButtons}
              />
            ) : (
              showDelete && (
                <Button
                  icon='pi pi-trash'
                  label='Eliminar'
                  className='p-button-text p-button-danger'
                  onClick={confirmDelete}
                  loading={isDeleting}
                  disabled={disableAllButtons}
                />
              )
            )}
            {renderEditButton()}
          </>
        )}
      </div>
    ) : null;

  return (
    <Sidebar
      className={classNames('base-panel', className)}
      position='right'
      visible={visible}
      onHide={handleClose}
      header={header}
      modal
      showCloseIcon={false}
    >
      <div className='panel-content'>{children}</div>
      {renderFooter()}
    </Sidebar>
  );
};
