import React from 'react';
import { EventProps } from 'react-big-calendar';
import { Tooltip } from 'primereact/tooltip';
import { format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';
import './ExpenseCalendarEvent.scss';

export enum PaymentType {
  CASH = 'cash',
  BONUS = 'bonus',
}

interface ExpenseEventDto {
  id: string;
  title: string;
  start: string;
  amount: number;
  paymentType: PaymentType;
  reservationId: string;
  employee?: {
    id: string;
    name: string;
    surnames: string;
  };
}

export const ExpenseCalendarEvent: React.FC<EventProps<ExpenseEventDto>> = ({ event }) => {
  const color = event.paymentType === PaymentType.CASH ? 'green' : 'blue';
  const tooltipId = `expense-${event.id}`;

  return (
    <>
      <div id={tooltipId} className='expense-calendar-event'>
        <div className='event-type' style={{ backgroundColor: `var(--${color}-500)` }}>
          <i className={event.paymentType === PaymentType.CASH ? 'pi pi-money-bill' : 'pi pi-ticket'} />
        </div>
        <div className='event-content'>
          <span>{event.amount}€</span>
        </div>
      </div>
      <Tooltip target={`#${tooltipId}`} position='right' showDelay={50} hideDelay={0}>
        <div className='event-tooltip'>
          <strong>{event.paymentType === PaymentType.CASH ? 'Pago en efectivo' : 'Pago con bono'}</strong>
          <div className='event-details'>
            <div>
              <i className='pi pi-calendar' />
              <span>{format(parseISO(event.start), "EEEE, d 'de' MMMM", { locale: es })}</span>
            </div>
            {event.employee && (
              <div>
                <i className='pi pi-user' />
                <span>{`${event.employee.name} ${event.employee.surnames}`}</span>
              </div>
            )}
            <div>
              <i className='pi pi-euro' />
              <span>{event.amount}€</span>
            </div>
            <div>
              <i className={event.paymentType === PaymentType.CASH ? 'pi pi-money-bill' : 'pi pi-ticket'} />
              <span>{event.paymentType === PaymentType.CASH ? 'Efectivo' : 'Bono'}</span>
            </div>
            <div>
              <i className='pi pi-info-circle' />
              <span>{event.title}</span>
            </div>
          </div>
        </div>
      </Tooltip>
    </>
  );
};
