import React, { useMemo, useState } from 'react';
import './UserPanel.scss';

import { mutate } from 'swr';
import { User } from '../../types/user';
import { Role } from '../../types/role';
import { format } from 'date-fns';
import { Avatar } from 'primereact/avatar';
import { FormField } from '../FormField/FormField';
import { useOrganizationContext } from '../../context/OrganizationContext';
import { Tag } from 'primereact/tag';
import { resourceMutator } from '../../utils/resourceMutation';
import { Panel, ResourcePanelImplProps } from '../Panel/Panel';
import { useClient } from '../../hooks/useClient';
import { useForm } from '../../hooks/useForm';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Dropdown } from 'primereact/dropdown';
import { Switch } from '../Switch/Switch';
import { FeaturePicker } from '../FeaturePicker/FeaturePicker';
import { useAuthContext } from '../../context/AuthContext';
import { getRoleOptions } from '../../utils/selectOptions';
import useSWR from 'swr';
import { Calendar } from 'primereact/calendar';
import { InputMask } from 'primereact/inputmask';
import { employeeFormValidators, UserForm } from './validators';
import { getUserAvatarProps } from '../../utils/avatarUtils';
import { BranchesSelect } from '../BranchesSelect/BranchesSelect';

const getInitialUserForm = (user?: User): UserForm => {
  const { name, email, surnames, branches, role, dateOfBirth, phone } = user ?? {};
  return {
    name,
    surnames,
    email,
    branchIds: branches?.map((_) => _.id),
    roleId: role?.id,
    roleFeatures: new Set(),
    dateOfBirth,
    phone,
  };
};

export const UserPanel = ({
  resource,
  visible,
  onHide,
  onIsEditingChange,
  onDeleteResource,
  isEditing = false,
  isDeleting = false,
}: ResourcePanelImplProps<User>) => {
  const { organization, organizationLoading } = useOrganizationContext() ?? {};
  const { user: authUser } = useAuthContext() ?? {};
  const { get, patch, post } = useClient();
  const [isCreatingNewRole, setIsCreatingNewRole] = useState(false);
  const isCreating = !resource;

  const {
    data: orgRoles,
    isLoading: orgRolesLoading,
    mutate: mutateOrgRoles,
  } = useSWR(organization ? `/organizations/${organization.id}/roles` : null, get<Role[]>);

  const initialUserForm = useMemo(() => getInitialUserForm(resource), [resource]);
  const { form, setFormFields, isSaving, setIsSaving, hasChanged, validationErrors, resetForm, validate } = useForm<UserForm>(
    initialUserForm,
    !visible,
    employeeFormValidators(isCreatingNewRole),
  );

  const createRole = async () => {
    const { roleName, roleFeatures } = form ?? {};
    if (!roleName || !roleFeatures) return;
    return await post<Role>(`/organizations/${organization?.id}/roles`, {
      body: {
        name: roleName,
        features: Array.from(roleFeatures),
      },
    });
  };

  const editUser = async (): Promise<boolean> => {
    if (!form) return false;
    if (isCreatingNewRole) {
      const createdRole = await createRole();
      if (!createdRole) return false;
      const updatedUser = await patch<User>(`/users/${resource?.id}`, {
        body: {
          roleId: createdRole.id,
          branchIds: form.branchIds,
        },
      });
      if (!updatedUser) return false;
      mutate(`/organizations/${organization?.id}/users`, resourceMutator(updatedUser), false);
      mutateOrgRoles(resourceMutator(createdRole), false);
      onHide();
      return true;
    }

    const updatedUser = await patch<User>(`/users/${resource?.id}`, {
      body: {
        roleId: form.roleId,
        branchIds: form.branchIds,
      },
    });
    if (!updatedUser) return false;
    mutate(`/organizations/${organization?.id}/users`, resourceMutator(updatedUser), false);
    return true;
  };

  const createUser = async (): Promise<boolean> => {
    if (!form) return false;
    const { name, surnames, email, roleId, branchIds, dateOfBirth, phone } = form;
    const inviterName = `${authUser?.name} ${authUser?.surnames}`;
    const inviterOrg = organization?.name;
    if (isCreatingNewRole) {
      const createdRole = await createRole();
      if (!createdRole) return false;
      const createdUser = await post<User>(`/users/add-employee`, {
        body: {
          name,
          surnames,
          email,
          branchIds,
          inviterName,
          inviterOrg,
          roleId: createdRole.id,
          dateOfBirth,
          phone,
        },
      });
      if (!createdUser) return false;
      mutate(`/organizations/${organization?.id}/users`, resourceMutator(createdUser), false);
      mutateOrgRoles(resourceMutator(createdRole), false);
      onHide();
      return true;
    }

    const createdUser = await post<User>(`/users/add-employee`, {
      body: {
        name,
        surnames,
        email,
        branchIds,
        inviterName,
        inviterOrg,
        roleId,
        dateOfBirth,
        phone,
      },
    });
    if (!createdUser) return false;
    mutate(`/organizations/${organization?.id}/users`, resourceMutator(createdUser), false);
    onHide();
    return true;
  };

  const handleSubmit = async (): Promise<boolean> => {
    const errors = validate();
    if (errors) return false;

    setIsSaving(true);
    const success = isEditing ? await editUser() : await createUser();
    setIsSaving(false);
    return !!success;
  };

  return (
    <Panel
      panelType='resourcePanel'
      resourceName='empleado'
      className='user-panel'
      visible={visible}
      onHide={onHide}
      onDelete={() => onDeleteResource(resource?.id)}
      isDeleting={isDeleting}
      onIsEditingChange={onIsEditingChange}
      hasChanged={hasChanged}
      isEditing={isEditing}
      isCreating={isCreating}
      resetForm={resetForm}
      onSave={handleSubmit}
      isSaving={isSaving || organizationLoading || orgRolesLoading}
    >
      <form
        className={classNames('user-form', {
          editing: isEditing,
          'form-loading': orgRolesLoading || organizationLoading || isSaving,
        })}
      >
        <FormField className='align-center' fullWidth>
          <div style={{ position: 'relative' }}>
            <Avatar {...getUserAvatarProps(resource)} shape='circle' className='big-avatar' />
          </div>
        </FormField>

        <FormField elementId='employee-name' error={validationErrors?.name} labelTitle='Nombre' required={isCreating}>
          {isCreating ? (
            <InputText
              className={classNames({
                'p-invalid': validationErrors?.name,
              })}
              id='employee-name'
              name='employee-name'
              placeholder={`Escribe el nombre del empleado`}
              value={form?.name ?? ''}
              onChange={(e: any) =>
                setFormFields({
                  name: e.target.value,
                })
              }
            />
          ) : (
            <p>{resource?.name}</p>
          )}
        </FormField>

        <FormField elementId='employee-surnames' error={validationErrors?.surnames} labelTitle='Apellidos' required={isCreating}>
          {isCreating ? (
            <InputText
              className={classNames({
                'p-invalid': validationErrors?.surnames,
              })}
              id='employee-surnames'
              name='employee-surnames'
              placeholder={`Escribe los apellidos del empleado`}
              value={form?.surnames ?? ''}
              onChange={(e: any) =>
                setFormFields({
                  surnames: e.target.value,
                })
              }
            />
          ) : (
            <p>{resource?.surnames}</p>
          )}
        </FormField>

        <FormField
          elementId='employee-email'
          error={validationErrors?.email}
          labelTitle='Correo electrónico'
          required={isCreating}
        >
          {isCreating ? (
            <InputText
              className={classNames({
                'p-invalid': validationErrors?.email,
              })}
              id='employee-email'
              name='employee-email'
              placeholder={`Escribe el correo electrónico del empleado`}
              value={form?.email ?? ''}
              onChange={(e: any) =>
                setFormFields({
                  email: e.target.value,
                })
              }
            />
          ) : (
            <p>{resource?.email}</p>
          )}
        </FormField>

        <FormField
          elementId='date-of-birth'
          error={validationErrors?.dateOfBirth}
          labelTitle='Fecha de nacimiento'
          required={isCreating}
        >
          {isCreating ? (
            <Calendar
              className={classNames({
                'p-invalid': validationErrors?.dateOfBirth,
              })}
              id='date-of-birth'
              name='date-of-birth'
              placeholder='Selecciona la fecha de nacimiento'
              value={form?.dateOfBirth ? new Date(form.dateOfBirth) : null}
              onChange={(e) =>
                setFormFields({
                  dateOfBirth: e.value?.toISOString(),
                })
              }
              dateFormat='dd/mm/yy'
              showIcon
            />
          ) : (
            <p>{resource?.dateOfBirth ? format(new Date(resource.dateOfBirth), "d 'de' LLLL, yyyy") : '-'}</p>
          )}
        </FormField>

        <FormField elementId='phone' error={validationErrors?.phone} labelTitle='Teléfono' required={isCreating}>
          {isCreating ? (
            <InputMask
              id='phone'
              name='phone'
              mask='(+99) 999-999-999'
              placeholder='(+__) ___-___-___'
              value={form?.phone ?? ''}
              className={classNames({
                'p-invalid': validationErrors?.phone,
              })}
              onChange={(e) =>
                setFormFields({
                  phone: e.target.value ?? undefined,
                })
              }
            />
          ) : (
            <p>{resource?.phone ?? '-'}</p>
          )}
        </FormField>

        <FormField
          elementId='user-branches'
          labelTitle='Sucursales'
          required={isCreating || isEditing}
          error={validationErrors?.branchIds}
        >
          {isEditing || isCreating ? (
            <BranchesSelect
              id='user-branches'
              isSingle={false}
              error={validationErrors?.branchIds}
              value={form?.branchIds}
              onChange={(branches) => setFormFields({ branchIds: branches.map((_) => _.id) })}
            />
          ) : (
            <p>
              {resource?.branches?.map((branch) => resource?.branches?.find((_) => _.id === branch.id)?.name).join(', ') ?? '-'}
            </p>
          )}
        </FormField>

        {(isEditing || isCreating) && (
          <div className='select-role-row'>
            {isCreatingNewRole ? (
              <FormField elementId='role-name' error={validationErrors?.roleName} labelTitle='Nombre del rol' required>
                <InputText
                  className={classNames({
                    'p-invalid': validationErrors?.roleName,
                  })}
                  id='role-name'
                  name='role-name'
                  placeholder={`Escribe el nombre del rol`}
                  value={form?.roleName ?? ''}
                  onChange={(e: any) =>
                    setFormFields({
                      roleName: e.target.value,
                    })
                  }
                />
              </FormField>
            ) : (
              <FormField labelTitle='Elegir rol' required error={validationErrors?.roleId}>
                <Dropdown
                  loading={orgRolesLoading}
                  options={getRoleOptions(orgRoles)}
                  value={form?.roleId}
                  onChange={(e) => setFormFields({ roleId: e.value })}
                  placeholder={'Elige un rol'}
                  className={classNames({
                    'p-invalid': validationErrors?.roleId,
                  })}
                />
              </FormField>
            )}
            <Switch
              id='create-user'
              label='Crear rol nuevo'
              primeSwitchProps={{
                checked: isCreatingNewRole,
                onChange: (e) => {
                  setIsCreatingNewRole(e.value);
                },
              }}
            />
          </div>
        )}

        {!isCreatingNewRole && !isEditing && !isCreating && (
          <FormField labelTitle='Rol'>
            <p>{resource?.role?.name ?? '-'}</p>
          </FormField>
        )}

        {isCreatingNewRole && (
          <FormField labelTitle='Permisos' required error={validationErrors?.roleFeatures}>
            <FeaturePicker
              className={classNames({
                'p-invalid': !!validationErrors?.roleFeatures,
              })}
              onChange={(newFeatures) =>
                setFormFields({
                  roleFeatures: newFeatures,
                })
              }
              featureSet={form?.roleFeatures}
            />
          </FormField>
        )}
      </form>

      {resource?.isInvitation && (
        <>
          <Tag className='user-invited-tag' value='Invitación enviada' />
          <small>El usuario debe aceptar la invitación para unirse a No Gravity.</small>
        </>
      )}
    </Panel>
  );
};
