import { Feature } from '../../config/features';
import { FormValidators, VALIDATION_MESSAGES } from '../../hooks/useForm';
import { validateLettersSpacesAndNumbers } from '../../utils/validationUtils';

const { NOT_NAME, ONLY_LETTERS_SPACES_AND_NUMBERS, NOT_FEATURES } = VALIDATION_MESSAGES;

export interface RoleForm {
  name?: string;
  features?: Set<Feature>;
}

export const ROLE_FORM_VALIDATORS: FormValidators<RoleForm> = {
  name: (role) => {
    if (!role.name?.length) return NOT_NAME;
    if (!validateLettersSpacesAndNumbers(role.name)) return ONLY_LETTERS_SPACES_AND_NUMBERS;
  },
  features: (role) => {
    if (!role.features?.size) return NOT_FEATURES;
  },
};
