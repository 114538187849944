import React, { useState, useCallback, useMemo } from 'react';
import { Avatar } from 'primereact/avatar';
import { TabView, TabPanel } from 'primereact/tabview';
import { Tag } from 'primereact/tag';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Calendar, Views, EventProps } from 'react-big-calendar';
import { getDay, parseISO, format, subDays } from 'date-fns';
import { RoutinePanel } from '../../../components/RoutinePanel/RoutinePanel';
import { useResourcePanel } from '../../../hooks/useResourcePanel';
import { Routine } from '../../../types/Fisiofit/routine';
import { useClient } from '../../../hooks/useClient';
import { useOrganizationContext } from '../../../context/OrganizationContext';
import useSWR from 'swr';
import './UserProfile.scss';
import { useParams } from 'react-router-dom';
import { ReservationPanel } from '../../../components/ReservationPanel/ReservationPanel';
import { EXTERNAL_USER_GENDERS, EXTERNAL_USER_STATUSES, ExternalUser, USER_ROUTINE_EVENTS } from '../../../types/externalUser';
import { PrimeIcons } from 'primereact/api';
import { getUserAvatarProps } from '../../../utils/avatarUtils';
import { calendarLocalizer, calendarMessages } from '../../../utils/calendarUtils';
import { Reservation } from '../../../types/reservation';
import { RESERVATION_COLORS, useReservationTypes } from '../../../hooks/AppData/useReservationTypes';
import { ReservationCalendarEvent } from '../../../components/ReservationCalendarEvent/ReservationCalendarEvent';
import { RoutineCalendarEvent } from '../../../components/RoutineCalendarEvent/RoutineCalendarEvent';
import { ExpenseCalendarEvent } from '../../../components/ExpenseCalendarEvent/ExpenseCalendarEvent';
import { CalendarToolbar } from '../../../components/CalendarToolbar/CalendarToolbar';
import {
  createReservationSummaryItems,
  createRoutineSummaryItems,
  createExpenseSummaryItems,
} from '../../../utils/calendarToolbarUtils';
import { CouponDisplay } from '../../../components/CouponDisplay/CouponDisplay';

const getGenderDisplay = (gender: EXTERNAL_USER_GENDERS | undefined) => {
  if (!gender) return;
  if (gender === EXTERNAL_USER_GENDERS.MALE) return 'Hombre';
  if (gender === EXTERNAL_USER_GENDERS.FEMALE) return 'Mujer';
  return 'Otro';
};

const getRoutineEventDescription = (eventType: USER_ROUTINE_EVENTS, fieldsUpdated?: string[]) => {
  if (eventType === USER_ROUTINE_EVENTS.NEXT_ROUTINE) return 'Próxima rutina';
  if (eventType === USER_ROUTINE_EVENTS.CURRENT_ROUTINE) return 'Rutina actual';
  if (eventType === USER_ROUTINE_EVENTS.PAST_ROUTINE) return 'Rutina pasada';
  if (eventType === USER_ROUTINE_EVENTS.CURRENT_ROUTINE_NOT_STARTED) return 'Comienza nueva rutina';
  if (eventType === USER_ROUTINE_EVENTS.UPDATE) {
    if (fieldsUpdated?.includes('exercises') && fieldsUpdated?.includes('duration')) return `Ejercicios y duración actualizados`;
    if (fieldsUpdated?.includes('exercises')) return `Ejercicios actualizados`;
    if (fieldsUpdated?.includes('duration')) return `Duración actualizada`;
    return 'Rutina modificada';
  }
  return '';
};

const getRoutineEventIcon = (eventType: USER_ROUTINE_EVENTS) => {
  if (eventType === USER_ROUTINE_EVENTS.NEXT_ROUTINE) return PrimeIcons.CALENDAR_PLUS;
  if (eventType === USER_ROUTINE_EVENTS.CURRENT_ROUTINE) return PrimeIcons.CALENDAR;
  if (eventType === USER_ROUTINE_EVENTS.CURRENT_ROUTINE_NOT_STARTED) return PrimeIcons.CALENDAR_PLUS;
  if (eventType === USER_ROUTINE_EVENTS.PAST_ROUTINE) return PrimeIcons.CALENDAR_MINUS;
  if (eventType === USER_ROUTINE_EVENTS.UPDATE) return PrimeIcons.COG;
  return '';
};

interface HistoryItem {
  id: string;
  date: Date | string;
  description: string;
  icon: string;
  hideViewButton?: boolean;
  isMinor?: boolean;
}

interface ActivityItem extends HistoryItem {
  content: string;
}

interface RoutineHistoryItem extends HistoryItem {
  eventType: USER_ROUTINE_EVENTS;
  routineId: string;
  fieldsUpdated?: string[];
}

export enum PaymentType {
  CASH = 'cash',
  BONUS = 'bonus',
}

interface ExpenseEventDto {
  id: string;
  title: string;
  start: string;
  amount: number;
  paymentType: PaymentType;
  reservationId: string;
  employee?: {
    id: string;
    name: string;
    surnames: string;
  };
}

interface ExternalUserExpensesResponse {
  expenses: ExpenseEventDto[];
  totalAmount: number;
  totalCashAmount: number;
  totalBonusAmount: number;
}

export const UserProfile = () => {
  const { get } = useClient();
  const { organization, orgBranches } = useOrganizationContext() ?? {};
  // TODO: Cambiar a externalUserId
  const { userId } = useParams();
  const { reservationTypeFromId } = useReservationTypes();

  // Fetch user reservations
  const { data: reservations } = useSWR(
    organization && userId ? `/organizations/${organization.id}/reservations?externalUserId=${userId}` : null,
    get<Reservation[]>,
  );

  const { data: user, mutate: mutateUser } = useSWR(
    organization && userId ? `/organizations/${organization.id}/external-users/${userId}` : null,
    get<ExternalUser>,
  );

  const { data: userExpenses } = useSWR(
    organization && userId ? `/organizations/${organization.id}/external-users/${userId}/expenses` : null,
    get<ExternalUserExpensesResponse>,
  );

  const {
    isEditing: editingRoutine,
    setIsEditing: setEditingRoutine,
    panelVisible: routinePanelVisible,
    closePanel: closeRoutinePanel,
    selectedResource: selectedRoutine,
    openPanel: openRoutinePanel,
  } = useResourcePanel<Routine>();
  const [overrideNextRoutine, setOverrideNextRoutine] = useState(false);

  const [reservationPanelVisible, setReservationPanelVisible] = useState(false);
  const [reservationPanelIsEditing, setReservationPanelIsEditing] = useState(false);
  const [selectedReservation, setSelectedReservation] = useState<Reservation>();

  const genderDisplay = getGenderDisplay(user?.gender);

  const handleViewHistoryRoutine = async (item: RoutineHistoryItem) => {
    if (!organization) return;
    if (item.routineId === user?.currentRoutine?.id) {
      openRoutinePanel(false, user?.currentRoutine);
      return;
    }
    if (item.routineId === user?.currentRoutine?.nextRoutine?.id) {
      openRoutinePanel(false, user?.currentRoutine);
      setOverrideNextRoutine(true);
      return;
    }
    const historyRoutine = await get<Routine>(`/routines/${item.routineId}`);

    openRoutinePanel(false, historyRoutine);
  };

  const handleViewReservation = async (item: ActivityItem) => {
    setReservationPanelVisible(true);
  };

  const handleCloseRoutinePanel = () => {
    setOverrideNextRoutine(false);
    closeRoutinePanel();
  };

  const minTime = new Date();
  minTime.setHours(7, 0, 0);
  const maxTime = new Date();
  maxTime.setHours(22, 0, 0);

  const defaultDate = new Date();

  const eventPropGetter = useCallback(
    (reservation: Reservation) => {
      const reservationType = reservationTypeFromId(reservation.reservationTypeId);
      const branchIndex = orgBranches?.findIndex((b) => b.id === reservation.branch.id) ?? 0;
      const color = reservationType?.color ?? RESERVATION_COLORS[branchIndex % RESERVATION_COLORS.length];
      return {
        style: {
          backgroundColor: `var(--${color}-100)`,
          border: `1px solid var(--${color}-300)`,
        },
      };
    },
    [reservationTypeFromId, orgBranches],
  );

  const dayPropGetter = useCallback((date: Date) => {
    const day = getDay(date);
    if (day === 0 || day === 6) {
      return {
        className: 'disabled-day',
        style: { backgroundColor: '#f0f0f0', color: '#ccc' },
      };
    }
    return {};
  }, []);

  const expenseEventPropGetter = useCallback((event: ExpenseEventDto) => {
    const color = event.paymentType === PaymentType.CASH ? 'green' : 'blue';
    return {
      style: {
        backgroundColor: `var(--${color}-100)`,
        border: `1px solid var(--${color}-300)`,
      },
    };
  }, []);

  return (
    <div className='user-profile-page'>
      <Card title='Información' className='profile-header'>
        <div className='profile-info'>
          <div className='profile-info-top'>
            <Avatar {...getUserAvatarProps(user)} shape='circle' size='xlarge' />
            <div className='profile-info-top-container'>
              <div className='profile-info-name'>
                <h2>
                  {user?.name} {user?.surnames}
                </h2>
                <Tag value={user?.status} severity={user?.status === EXTERNAL_USER_STATUSES.ACTIVE ? 'success' : 'danger'} />
              </div>
              {(user?.age || user?.gender) && (
                <p>
                  {user?.age ? `${user?.age} años` : ''} {user?.gender && user?.age ? '• ' : ''}
                  {genderDisplay}
                </p>
              )}
            </div>
          </div>
          <div className='profile-info-top'>
            <p>
              <i className='pi pi-calendar'></i> <strong>Fecha de alta:</strong>{' '}
              {user?.joinedDate ? format(new Date(user?.joinedDate), "d 'de' MMMM 'de' yyyy") : ''}
            </p>
            <p>
              <i className='pi pi-clock'></i> <strong>Ultima visita:</strong>{' '}
              {user?.latestReservationDate ? format(new Date(user?.latestReservationDate), "d 'de' MMMM 'de' yyyy") : ''}
            </p>
          </div>
          <p>
            <i className='pi pi-envelope'></i> {user?.email}
          </p>
          {user?.phone ? (
            <p>
              <i className='pi pi-phone'></i> {user?.phone}
            </p>
          ) : null}
          {user?.userCoupon && <CouponDisplay coupon={user.userCoupon} />}

          <div className='profile-actions'>
            <Button
              label='Crear cita'
              icon='pi pi-calendar-plus'
              className='p-button-success mr-2'
              onClick={() => setReservationPanelVisible(true)}
            />
            <Button
              label={user?.currentRoutine ? 'Ver rutina' : 'Crear rutina'}
              icon='pi pi-list'
              onClick={() => {
                const { name, surnames, email, id, avatarUrl } = user ?? {};
                const panelRoutine =
                  user?.currentRoutine ??
                  ({
                    name: `Rutina de ${name} ${surnames}`,
                    organizationId: organization?.id,
                    externalUser: {
                      name,
                      surnames,
                      email,
                      id,
                      avatarUrl,
                    },
                  } as Routine);
                openRoutinePanel(false, panelRoutine);
              }}
            />
            <Button label='Dar de baja' icon='pi pi-user-minus' className='p-button-danger' />
          </div>
        </div>
      </Card>

      <Card title='Actividad'>
        <TabView>
          <TabPanel header='Reservas'>
            <div className='calendar-container'>
              <Calendar
                dayLayoutAlgorithm='no-overlap'
                localizer={calendarLocalizer}
                events={reservations ?? []}
                startAccessor={(reservation) => parseISO(reservation.localStartDate)}
                endAccessor={(reservation) => parseISO(reservation.localEndDate)}
                step={30}
                timeslots={1}
                messages={calendarMessages}
                views={[Views.MONTH, Views.WEEK, Views.DAY]}
                max={maxTime}
                min={minTime}
                defaultDate={defaultDate}
                scrollToTime={(() => {
                  const adjustedTime = new Date(new Date().setHours(new Date().getHours() - 1, 0, 0, 0));
                  return adjustedTime < minTime ? minTime : adjustedTime;
                })()}
                eventPropGetter={eventPropGetter}
                components={{
                  event: (props: EventProps<Reservation>) => {
                    const reservationType = reservationTypeFromId(props.event.reservationTypeId);
                    if (!reservationType) return null;
                    return <ReservationCalendarEvent {...props} reservationType={reservationType} showAttendance />;
                  },
                  toolbar: (props) => (
                    <CalendarToolbar
                      {...props}
                      summaryItems={createReservationSummaryItems(reservations ?? [])}
                      showTodayButton={false}
                      showViewSelector={false}
                    />
                  ),
                }}
                onSelectEvent={(event) => {
                  setSelectedReservation(event);
                  setReservationPanelVisible(true);
                }}
                dayPropGetter={dayPropGetter}
              />
            </div>
          </TabPanel>
          <TabPanel header='Rutinas'>
            <div className='calendar-container'>
              <Calendar
                dayLayoutAlgorithm='no-overlap'
                localizer={calendarLocalizer}
                events={(() => {
                  if (!user?.routineHistory) return [];

                  // Primero, filtramos y ordenamos el historial para tener solo las rutinas principales
                  const mainRoutines = user.routineHistory
                    .filter(
                      (item) =>
                        item.eventType === USER_ROUTINE_EVENTS.CURRENT_ROUTINE ||
                        item.eventType === USER_ROUTINE_EVENTS.NEXT_ROUTINE ||
                        item.eventType === USER_ROUTINE_EVENTS.CURRENT_ROUTINE_NOT_STARTED ||
                        item.eventType === USER_ROUTINE_EVENTS.PAST_ROUTINE,
                    )
                    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

                  // Luego, filtramos las actualizaciones
                  const updates = user.routineHistory.filter((item) => item.eventType === USER_ROUTINE_EVENTS.UPDATE);

                  // Creamos los eventos de rutinas principales
                  const routineEvents = mainRoutines.map((item, index) => {
                    const startDate = new Date(item.date);
                    let endDate;

                    // Si es la rutina actual
                    if (item.routineId === user.currentRoutine?.id) {
                      const duration = user.currentRoutine?.duration || 30;
                      endDate = new Date(startDate);
                      endDate.setDate(startDate.getDate() + duration);
                    }
                    // Si es la próxima rutina
                    else if (item.routineId === user.currentRoutine?.nextRoutine?.id) {
                      const duration = user.currentRoutine?.nextRoutine?.duration || 30;
                      endDate = new Date(startDate);
                      endDate.setDate(startDate.getDate() + duration);
                    }
                    // Para rutinas pasadas, terminan cuando empieza la siguiente
                    else {
                      endDate =
                        index < mainRoutines.length - 1
                          ? new Date(mainRoutines[index + 1].date)
                          : new Date(startDate.getTime() + 30 * 24 * 60 * 60 * 1000); // 30 días por defecto
                    }

                    return {
                      id: item.routineId,
                      title: getRoutineEventDescription(item.eventType),
                      start: startDate,
                      end: endDate,
                      routineId: item.routineId,
                      eventType: item.eventType,
                    };
                  });

                  // Creamos los eventos de actualización
                  const updateEvents = updates.map((item) => ({
                    id: `${item.routineId}-update-${item.date}`,
                    title: getRoutineEventDescription(item.eventType, item.fieldsUpdated),
                    start: new Date(item.date),
                    end: new Date(item.date),
                    routineId: item.routineId,
                    eventType: item.eventType,
                    fieldsUpdated: item.fieldsUpdated,
                  }));

                  return [...routineEvents, ...updateEvents];
                })()}
                startAccessor='start'
                endAccessor='end'
                step={30}
                timeslots={1}
                messages={calendarMessages}
                views={[Views.MONTH]}
                defaultView={Views.MONTH}
                toolbar={true}
                view={Views.MONTH}
                onView={() => {}}
                max={maxTime}
                min={minTime}
                defaultDate={defaultDate}
                scrollToTime={(() => {
                  const adjustedTime = new Date(new Date().setHours(new Date().getHours() - 1, 0, 0, 0));
                  return adjustedTime < minTime ? minTime : adjustedTime;
                })()}
                eventPropGetter={(event) => {
                  let color = 'primary';
                  if (event.eventType === USER_ROUTINE_EVENTS.CURRENT_ROUTINE) color = 'green';
                  if (event.eventType === USER_ROUTINE_EVENTS.NEXT_ROUTINE) color = 'blue';
                  if (event.eventType === USER_ROUTINE_EVENTS.PAST_ROUTINE) color = 'orange';
                  if (event.eventType === USER_ROUTINE_EVENTS.UPDATE) color = 'yellow';

                  return {
                    style: {
                      backgroundColor: `var(--${color}-100)`,
                      border: `1px solid var(--${color}-300)`,
                    },
                  };
                }}
                components={{
                  event: (props) => <RoutineCalendarEvent {...props} />,
                  toolbar: (props) => (
                    <CalendarToolbar
                      {...props}
                      summaryItems={createRoutineSummaryItems(
                        user?.routineHistory?.map((item) => ({
                          eventType: item.eventType,
                        })) ?? [],
                      )}
                      showViewSelector={false}
                      showTodayButton={false}
                    />
                  ),
                }}
                onSelectEvent={(event: any) => {
                  handleViewHistoryRoutine({
                    id: event.id,
                    date: event.start.toISOString(),
                    description: event.title,
                    icon: getRoutineEventIcon(event.eventType),
                    eventType: event.eventType,
                    routineId: event.routineId,
                    fieldsUpdated: event.fieldsUpdated,
                  });
                }}
                dayPropGetter={dayPropGetter}
              />
            </div>
          </TabPanel>
          <TabPanel header='Gastos'>
            <div className='calendar-container'>
              <Calendar
                dayLayoutAlgorithm='no-overlap'
                localizer={calendarLocalizer}
                events={userExpenses?.expenses ?? []}
                startAccessor={(event) => parseISO(event.start)}
                endAccessor={(event) => parseISO(event.start)}
                step={30}
                timeslots={1}
                messages={calendarMessages}
                views={[Views.MONTH]}
                defaultView={Views.MONTH}
                toolbar={true}
                view={Views.MONTH}
                onView={() => {}}
                max={maxTime}
                min={minTime}
                defaultDate={defaultDate}
                eventPropGetter={expenseEventPropGetter}
                components={{
                  event: (props) => <ExpenseCalendarEvent {...props} />,
                  toolbar: (props) => (
                    <CalendarToolbar
                      {...props}
                      summaryItems={createExpenseSummaryItems(userExpenses?.expenses ?? [], {
                        totalAmount: userExpenses?.totalAmount ?? 0,
                        totalCashAmount: userExpenses?.totalCashAmount ?? 0,
                        totalBonusAmount: userExpenses?.totalBonusAmount ?? 0,
                      })}
                      showViewSelector={false}
                      showTodayButton={false}
                    />
                  ),
                }}
                dayPropGetter={dayPropGetter}
              />
            </div>
          </TabPanel>
        </TabView>
      </Card>

      <RoutinePanel
        resource={selectedRoutine}
        visible={routinePanelVisible}
        onHide={handleCloseRoutinePanel}
        onIsEditingChange={(value) => setEditingRoutine(value)}
        onSaveSuccess={mutateUser}
        isEditing={editingRoutine}
        isDeleting={false}
        overrideNextRoutine={overrideNextRoutine}
        creatingFromUserProfile
      />

      <ReservationPanel
        visible={reservationPanelVisible}
        onHide={() => {
          setReservationPanelVisible(false);
        }}
        onIsEditingChange={(value) => setReservationPanelIsEditing(value)}
        initialHours={undefined}
        clearInitialTemporaryEvent={() => {}}
        isEditing={reservationPanelIsEditing}
        resource={selectedReservation}
        onDeleteResource={async () => {
          // No implementamos la eliminación aquí ya que es solo vista
          return false;
        }}
        isDeleting={false}
      />
    </div>
  );
};

export default UserProfile;
