import { FormValidators, VALIDATION_MESSAGES } from '../../hooks/useForm';
import { validateLettersSpacesAndNumbers } from '../../utils/validationUtils';

const { NOT_NAME, ONLY_LETTERS_SPACES_AND_NUMBERS, NOT_PRICE, NOT_USAGE_LIMIT } = VALIDATION_MESSAGES;

export interface CouponForm {
  name: string;
  price: number | undefined;
  usageLimit: number | undefined;
}

export const COUPON_FORM_VALIDATORS: FormValidators<CouponForm> = {
  name: (coupon) => {
    if (!coupon.name?.length) return NOT_NAME;
    if (!validateLettersSpacesAndNumbers(coupon.name)) return ONLY_LETTERS_SPACES_AND_NUMBERS;
  },
  price: (coupon) => {
    if (!coupon.price) return NOT_PRICE;
  },
  usageLimit: (coupon) => {
    if (!coupon.usageLimit) return NOT_USAGE_LIMIT;
  },
};
