import { ExternalUserShort } from '../externalUser';
import { EXERCISE_TYPE } from './exercise';

export enum ROUTINE_TYPES {
  TEMPLATE = 'template',
  USER = 'user',
}

export interface RoutineChange {
  date: Date;
  fieldsUpdated: string[];
}

interface BaseRoutineExercise {
  name: string;
  exerciseId: string;
  exerciseType: EXERCISE_TYPE;
  weight?: number;
  sets?: number;
  videoUrl?: string;
  imageUrl?: string;
}
export interface RoutineRepetitionExercise extends BaseRoutineExercise {
  repetitions?: number;
}
export interface RoutineDurationExercise extends BaseRoutineExercise {
  duration?: number;
}
export type RoutineExercise = RoutineRepetitionExercise | RoutineDurationExercise;

export interface Routine {
  id: string;
  name: string;
  description?: string;
  // If a routine has start date, it is linked to a user
  localStartDate?: string;
  // Duration in days
  duration: number;
  history?: RoutineChange[];
  isExpired?: boolean;

  // References
  nextRoutine?: Routine;
  exercises: RoutineExercise[];
  externalUser?: ExternalUserShort;
  organizationId: string;
}

// Routine detail
interface BaseRoutineDetailExercise extends BaseRoutineExercise {
  name: string;
  videoUrl?: string;
  imageUrl?: string;
}
interface RoutineDetailRepetitionExercise extends BaseRoutineDetailExercise {
  repetitions?: number;
}
interface RoutineDetailDurationExercise extends BaseRoutineDetailExercise {
  duration?: number;
}
export type RoutineDetailExercise = RoutineDetailRepetitionExercise | RoutineDetailDurationExercise;

export interface RoutineDetail extends Routine {
  exercises: RoutineDetailExercise[];
}
