import React from 'react';
import { ToolbarProps, NavigateAction, View, ViewsProps } from 'react-big-calendar';
import { Button } from 'primereact/button';
import { PrimeIcons } from 'primereact/api';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import './CalendarToolbar.scss';

export type SummaryItem = {
  icon: string;
  label: string;
  value: string | number;
  suffix?: string;
  highlight?: boolean;
};

interface Props<TEvent extends object = any> extends Omit<ToolbarProps<TEvent>, 'onView' | 'views' | 'view'> {
  onNavigate: (action: NavigateAction) => void;
  onView?: (view: View) => void;
  label: string;
  view?: View;
  views?: ViewsProps<TEvent>;
  summaryItems?: SummaryItem[];
  showViewSelector?: boolean;
  showTodayButton?: boolean;
}

const viewLabels: Record<string, string> = {
  month: 'Mes',
  week: 'Semana',
  day: 'Día',
  agenda: 'Agenda',
};

const viewIcons: Record<string, string> = {
  month: PrimeIcons.CALENDAR,
  week: PrimeIcons.LIST,
  day: PrimeIcons.CLOCK,
  agenda: PrimeIcons.BOOK,
};

export const CalendarToolbar = <TEvent extends object = any>({
  onNavigate,
  onView,
  label,
  view,
  views,
  summaryItems,
  showViewSelector = true,
  showTodayButton = true,
}: Props<TEvent>) => {
  return (
    <div className='calendar-toolbar'>
      <div className='toolbar-navigation'>
        <button type='button' onClick={() => onNavigate('PREV' as NavigateAction)}>
          <i className='pi pi-chevron-left' />
        </button>
        <span className='toolbar-label'>{format(new Date(label), 'MMMM yyyy', { locale: es })}</span>
        <button type='button' onClick={() => onNavigate('NEXT' as NavigateAction)}>
          <i className='pi pi-chevron-right' />
        </button>
        {showTodayButton && (
          <Button label='Hoy' icon={PrimeIcons.CALENDAR} onClick={() => onNavigate('TODAY' as NavigateAction)} text rounded />
        )}
      </div>

      <div className='toolbar-content'>
        {summaryItems && summaryItems.length > 0 && (
          <div className='toolbar-summary'>
            {summaryItems.map((item, index) => (
              <div key={index} className={`summary-item ${item.highlight ? 'highlight' : ''}`}>
                <i className={item.icon} />
                <span>
                  {item.label}: {item.value}
                  {item.suffix}
                </span>
              </div>
            ))}
          </div>
        )}

        {showViewSelector && view && views && onView && (
          <div className='toolbar-views'>
            {Object.keys(views).map((name) => (
              <Button
                key={name}
                label={viewLabels[name]}
                icon={viewIcons[name]}
                onClick={() => onView(name as View)}
                text
                rounded
                severity={view === name ? 'info' : 'secondary'}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
