function isDateString(str: string) {
  const date = new Date(str);
  return !isNaN(date.getTime()); // `getTime` returns NaN if the date is invalid
}

export const areFormObjectsDifferent = (
  obj1: any,
  obj2: any,
  emptyStringIsUndefined = false
): boolean => {
  if (obj1 === obj2) return false;

  if (obj1 instanceof Set || obj2 instanceof Set) {
    if (!(obj1 instanceof Set) || !(obj2 instanceof Set)) return true; // One is a Set, the other is not
    if (obj1.size !== obj2.size) return true; // Different sizes

    // Compare sets by converting to arrays and checking contents
    const arr1 = Array.from(obj1);
    const arr2 = Array.from(obj2);

    // Sort and compare element-by-element
    arr1.sort();
    arr2.sort();
    for (let i = 0; i < arr1.length; i++) {
      if (areFormObjectsDifferent(arr1[i], arr2[i], emptyStringIsUndefined)) {
        return true;
      }
    }
    return false;
  }

  if (
    typeof obj1 !== 'object' ||
    obj1 === null ||
    typeof obj2 !== 'object' ||
    obj2 === null
  ) {
    if (emptyStringIsUndefined && obj1 === '' && obj2 === undefined)
      return false;
    if (emptyStringIsUndefined && obj2 === '' && obj1 === undefined)
      return false;
    if (isDateString(obj1 ?? '') && isDateString(obj2 ?? '')) {
      return new Date(obj1).toISOString() !== new Date(obj2).toISOString();
    }
    return obj1 !== obj2;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) {
    if (emptyStringIsUndefined) {
      const newKeys1 = keys1.filter((key) => !keys2.includes(key));
      const newKeys2 = keys2.filter((key) => !keys1.includes(key));
      newKeys1.forEach((key) => {
        if (obj1[key] === '') {
          delete obj1[key];
        } else {
          return true;
        }
      });
      newKeys2.forEach((key) => {
        if (obj2[key] === '') {
          delete obj2[key];
        } else {
          return true;
        }
      });
    } else {
      return true;
    }
  }

  for (const key of Object.keys(obj1)) {
    if (!keys2.includes(key) || areFormObjectsDifferent(obj1[key], obj2[key])) {
      return true;
    }
  }

  return false;
};
