import React, { FormEvent, useEffect } from 'react';
import { useAuthContext } from '../../context/AuthContext';
import { useForm } from '../../hooks/useForm';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { NoAuthPageTemplate } from '../../layout/NoAuthPageTemplate/NoAuthPageTemplate';
import { LinkButton } from '../../components/LinkButton/LinkButton';
import { LOGIN_VALIDATORS } from './validators';
import { classNames } from 'primereact/utils';
import { FormField } from '../../components/FormField/FormField';
import { notificationService } from '../../services/NotificationService';

const EMPTY_LOGIN_FORM: LoginForm = {
  password: '',
  email: '',
};

export interface LoginForm {
  email: string;
  password: string;
}

export const Login = () => {
  const { login, isExpectedLoggedIn } = useAuthContext() ?? {};
  const {
    form,
    setFormFields,
    hasChanged,
    isSaving: isSigningIn,
    setIsSaving: setIsSigningIn,
    validate,
    validationErrors,
  } = useForm<LoginForm>(EMPTY_LOGIN_FORM, true, LOGIN_VALIDATORS);
  const { email, password } = form;
  const navigate = useNavigate();

  const handleSignIn = async (e: FormEvent) => {
    e.preventDefault();
    const newErrors = validate();
    if (!email || !password || newErrors) return;

    try {
      setIsSigningIn(true);
      // Inicializar notificaciones y obtener token
      const notificationToken = await notificationService.initialize();
      // Incluir el token en el login
      await login?.(email, password, notificationToken);
      navigate('/analytics');
    } catch (error) {
      console.error('Error during login:', error);
    } finally {
      setIsSigningIn(false);
    }
  };

  return (
    <NoAuthPageTemplate>
      <h2>Te damos la bienvenida</h2>
      <p>Inicia sesión para continuar</p>

      <form onSubmit={handleSignIn}>
        <FormField labelTitle='Email' elementId='email' error={validationErrors?.email}>
          <InputText
            placeholder='Dirección de correo electrónico'
            id='email'
            name='email'
            value={email}
            autoComplete='username'
            onChange={(e) => setFormFields({ email: e.target.value })}
            className={classNames({ 'p-invalid': validationErrors?.email })}
          />
        </FormField>

        <FormField labelTitle='Contraseña' elementId='password' error={validationErrors?.password}>
          <Password
            toggleMask
            feedback={false}
            placeholder='Contraseña'
            id='password'
            name='password'
            value={password}
            type='password'
            inputMode='text'
            autoComplete='current-password'
            onChange={(e) => setFormFields({ password: e.target.value })}
            className={classNames({ 'p-invalid': validationErrors?.password })}
          />
        </FormField>

        <LinkButton to='/password-recovery' type='button' link>
          Recordar contraseña
        </LinkButton>
        <Button className='large' type='submit' loading={isSigningIn}>
          Iniciar sesión
        </Button>
      </form>
    </NoAuthPageTemplate>
  );
};
