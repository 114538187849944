import React, { useMemo } from 'react';
import './UserDisplay.scss';
import { Avatar } from 'primereact/avatar';
import { getUserAvatarProps } from '../../utils/avatarUtils';
import { Button, ButtonProps } from 'primereact/button';

interface BaseProps {
  avatarUrl?: string;
  name?: string;
  surnames?: string;
  fullName?: string;
  buttonProps?: ButtonProps;
  isPlaceholder?: boolean;
}
interface FullNameProps extends BaseProps {
  fullName: string | undefined;
  name?: never;
  surnames?: never;
}
interface NameSurnamesProps extends BaseProps {
  name: string | undefined;
  surnames: string | undefined;
  fullName?: never;
}

type Props = FullNameProps | NameSurnamesProps;

export const UserDisplay = ({ avatarUrl, name, surnames, fullName, buttonProps, isPlaceholder }: Props) => {
  const [inferredName, inferredSurnames] = fullName?.split(' ') || [];

  const userAvatarProps = useMemo(
    () =>
      getUserAvatarProps({
        name: name || inferredName,
        surnames: surnames || inferredSurnames,
        avatarUrl,
        isPlaceholder,
      }),
    [name, surnames, inferredName, inferredSurnames, avatarUrl, isPlaceholder],
  );

  return (
    <div className='user-display align-center half-gap'>
      <Avatar shape='circle' {...userAvatarProps} />
      <p>{fullName || `${name} ${surnames}`}</p>
      {buttonProps && <Button {...buttonProps} />}
    </div>
  );
};
