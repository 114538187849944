import React, { useMemo, useState, useEffect } from 'react';
import { Calendar as BigCalendar, Views, SlotInfo, Event } from 'react-big-calendar';
import withDragAndDrop, { EventInteractionArgs } from 'react-big-calendar/lib/addons/dragAndDrop';

import { format, set } from 'date-fns';
import { es } from 'date-fns/locale';
import './ReservationPanel.scss';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';

import { Calendar } from 'primereact/calendar';
import { useOrganizationContext } from '../../context/OrganizationContext';
import { useForm } from '../../hooks/useForm';
import { FormField } from '../FormField/FormField';
import { classNames } from 'primereact/utils';
import { NotificationMessage, RequestErrorMessages, useClient } from '../../hooks/useClient';
import useSWR, { mutate } from 'swr';
import { InputTextarea } from 'primereact/inputtextarea';
import {
  Reservation,
  EditReservationPayload,
  CANCELED_BY_OPTIONS,
  CancellationReason,
  ExternalUserReservation,
} from '../../types/reservation';
import { InputNumber } from 'primereact/inputnumber';
import { Panel, ResourcePanelImplProps } from '../Panel/Panel';
import { getReservationFormValidators, ReservationForm } from './validators';
import { UserDisplay } from '../UserDisplay/UserDisplay';
import { BranchesSelect } from '../BranchesSelect/BranchesSelect';
import { UsersSelect } from '../UsersSelect/UsersSelect';
import { calendarLocalizer, calendarMessages } from '../../utils/calendarUtils';
import { useReservationTypes } from '../../hooks/AppData/useReservationTypes';
import { middleOfDay } from '../../utils/DateUtils';
import { ReservationType } from '../../types/reservationType';
import { Skeleton } from 'primereact/skeleton';
import { useAuthContext } from '../../context/AuthContext';
import { User } from '../../types/user';
import { ExternalUserField } from '../ExternalUserField/ExternalUserField';
import { PrimeIcons } from 'primereact/api';
import { ExternalUserShort } from '../../types/externalUser';
import { Button, ButtonProps } from 'primereact/button';
import { ExternalUsersDropdownButton } from '../ExternalUsersDropdownButton/ExternalUsersDropdownButton';
import { capitalize } from '../../utils/textUtils';
import { Switch } from '../Switch/Switch';
import { CouponWithUsers, UserCoupon } from '../../types/coupon';
import { Dropdown } from 'primereact/dropdown';
import { getCouponOptions } from '../../utils/selectOptions';
import { FormFieldMessage } from '../ConditionalFieldMessage/ConditionalFieldMessage';
import { InitialHours } from '../../pages/Reservations/Reservations';
import { CouponDisplay } from '../CouponDisplay/CouponDisplay';
import { InputText } from 'primereact/inputtext';
import { useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { isPastReservation } from '../../utils/reservationUtils';

const getPanelTitle = (
  isCreating: boolean,
  isEditing: boolean,
  reservationTypeName: string | undefined,
  reservation?: Reservation,
): string => {
  if (!isCreating && isEditing && reservationTypeName) {
    const now = new Date();
    const startDate = reservation?.localStartDate ? new Date(reservation.localStartDate) : null;
    if (startDate && startDate <= now) {
      return `Asistencias de ${reservationTypeName}`;
    }
    return `Editar reserva de ${reservationTypeName}`;
  }
  if (!isCreating && !isEditing) {
    return `Detalle de reserva de ${reservationTypeName}`;
  }
  if (isCreating && !isEditing) {
    return `Añadir reserva de ${reservationTypeName}`;
  }
  return `Reserva de ${reservationTypeName}`;
};

export interface ReservationEvent extends Event {
  id: string;
  title: string;
  start: Date;
  end: Date;
  isBlocked?: boolean;
}

const getToastMessages = (
  isEditing: boolean,
): {
  successMessage: NotificationMessage;
  errorMessages: RequestErrorMessages;
} => {
  return {
    successMessage: {
      summary: isEditing ? 'Reserva modificada' : 'Reserva creada',
      detail: isEditing ? 'La reserva se ha modificado correctamente' : 'Se ha creado una nueva reserva',
    },
    errorMessages: {
      summary: isEditing ? 'Error modificando la reserva' : 'Error al crear la reserva',
      defaultDetail: isEditing ? 'No se ha podido modificar la reserva' : 'No se ha podido crear la reserva',
    },
  };
};

const getInitialForm = (
  reservation?: Reservation,
  authUser?: User,
  isCreating: boolean = false,
  reservationType?: ReservationType,
  initialHours?: InitialHours,
): ReservationForm | undefined => {
  const { hasAssignedUsers } = reservationType ?? {};
  const {
    localStartDate,
    localEndDate,
    price,
    comment,
    reservationTypeId,
    user,
    externalUsers,
    confirmedExternalUsers,
    queuedExternalUsers,
    branch,
  } = reservation ?? {};
  const initialDay = initialHours?.start ? new Date(initialHours.start) : middleOfDay(new Date());

  // Inicializar attendanceData basado en confirmedExternalUsers
  const initialAttendanceData =
    confirmedExternalUsers?.reduce(
      (acc, user) => ({
        ...acc,
        [user.id]: user.attendance ?? false,
      }),
      {},
    ) ?? {};

  return {
    localStartDate: localStartDate ?? initialHours?.start,
    localEndDate: localEndDate ?? initialHours?.end,
    price,
    comment,
    reservationTypeId,
    user: hasAssignedUsers ? (isCreating ? authUser : user) : undefined,
    branch: isCreating ? authUser?.branches?.[0] : branch,
    externalUsers,
    confirmedExternalUsers,
    queuedExternalUsers,
    selectedDay: reservation?.localStartDate ? new Date(reservation.localStartDate) : initialDay,
    couponId: reservation?.couponId,
    useCoupon: !!reservation?.couponId,
    unregisteredClientName: reservation?.unregisteredClient?.name,
    unregisteredClientSurnames: reservation?.unregisteredClient?.surnames,
    unregisteredClientPhone: reservation?.unregisteredClient?.phone,
    usersBeforeSave: [],
    attendanceData: initialAttendanceData,
  };
};

const DragAndDropCalendar = withDragAndDrop<ReservationEvent, object>(BigCalendar);

const getDayDisplay = (startDate: string | undefined) => {
  if (!startDate) return null;
  const formattedDate = format(new Date(startDate), "d 'de' MMMM 'de' yyyy", {
    locale: es,
  });
  return (
    <div className='form-field-row'>
      <i className='pi pi-calendar primary-icon' />
      <span>{formattedDate}</span>
    </div>
  );
};

const getHourDisplay = (startDate: string | undefined, endDate: string | undefined) => {
  if (!startDate || !endDate) return null;
  const formattedStartTime = format(new Date(startDate), 'h:mm a', { locale: es });
  const formattedEndTime = format(new Date(endDate), 'h:mm a', { locale: es });
  return (
    <div className='form-field-row'>
      <i className='pi pi-clock primary-icon' />
      <span>{formattedStartTime}</span>
      <i className='pi pi-arrow-right primary-icon' />
      <span>{formattedEndTime}</span>
    </div>
  );
};

const isSlotAvailable = (start: Date, end: Date, existingEvents: ReservationEvent[]): boolean => {
  // No permitir reservas de duración 0
  if (start.getTime() === end.getTime()) return false;

  // Ordenar eventos por fecha de inicio
  const sortedEvents = [...existingEvents].sort((a, b) => a.start.getTime() - b.start.getTime());

  // Comprobar si hay algún evento que se solape
  return !sortedEvents.some((event) => {
    // Redondear todos los timestamps al segundo más cercano
    const eventStart = Math.floor(event.start.getTime() / 1000) * 1000;
    const eventEnd = Math.floor(event.end.getTime() / 1000) * 1000;
    const newStart = Math.floor(start.getTime() / 1000) * 1000;
    const newEnd = Math.floor(end.getTime() / 1000) * 1000;

    // Permitir eventos que empiecen exactamente cuando termina otro
    // o que terminen exactamente cuando empieza otro
    if (newStart === eventEnd || newEnd === eventStart) {
      return false;
    }

    // Hay solapamiento si el nuevo evento empieza antes de que termine el existente
    // Y termina después de que empiece el existente
    return newStart < eventEnd && newEnd > eventStart;
  });
};

// Redondea una fecha al bloque de media hora más cercano
const roundToNearestBlock = (date: Date): Date => {
  const minutes = date.getMinutes();
  const roundedMinutes = minutes >= 30 ? 30 : 0;
  return new Date(date.setMinutes(roundedMinutes, 0, 0));
};

// Encuentra el espacio disponible más cercano para una reserva
const findNearestAvailableSlot = (
  clickedTime: Date,
  existingEvents: ReservationEvent[],
  preferredDuration: number = 60,
): { start: Date; end: Date } => {
  const sortedEvents = [...existingEvents].sort((a, b) => a.start.getTime() - b.start.getTime());
  const clickedTimeMs = clickedTime.getTime();

  // Primero intentamos crear la reserva exactamente donde el usuario ha clickado
  const roundedStart = roundToNearestBlock(clickedTime);
  const defaultSlot = {
    start: roundedStart,
    end: new Date(roundedStart.getTime() + preferredDuration * 60 * 1000),
  };

  // Si este slot está disponible, lo usamos
  if (isSlotAvailable(defaultSlot.start, defaultSlot.end, existingEvents)) {
    return defaultSlot;
  }

  // Si no está disponible, buscamos el hueco más cercano
  let prevEvent: ReservationEvent | null = null;
  let nextEvent: ReservationEvent | null = null;

  for (let i = 0; i < sortedEvents.length; i++) {
    if (sortedEvents[i].start.getTime() > clickedTimeMs) {
      nextEvent = sortedEvents[i];
      prevEvent = i > 0 ? sortedEvents[i - 1] : null;
      break;
    }
    if (i === sortedEvents.length - 1) {
      prevEvent = sortedEvents[i];
    }
  }

  // Si hay eventos antes y después, encontrar el espacio máximo disponible
  if (prevEvent && nextEvent) {
    const availableSpace = nextEvent.start.getTime() - prevEvent.end.getTime();
    const duration = Math.min(availableSpace, preferredDuration * 60 * 1000);

    // Si el click está más cerca del final del evento previo, crear después de él
    const distanceToPrev = clickedTimeMs - prevEvent.end.getTime();
    const distanceToNext = nextEvent.start.getTime() - clickedTimeMs;

    if (distanceToPrev < distanceToNext) {
      return {
        start: prevEvent.end,
        end: new Date(prevEvent.end.getTime() + duration),
      };
    }
    return {
      start: new Date(nextEvent.start.getTime() - duration),
      end: nextEvent.start,
    };
  }

  // Si hay un evento previo pero no siguiente, intentar crear después del previo
  if (prevEvent) {
    return {
      start: prevEvent.end,
      end: new Date(prevEvent.end.getTime() + preferredDuration * 60 * 1000),
    };
  }

  // Si hay un evento siguiente pero no previo, intentar crear antes del siguiente
  if (nextEvent) {
    return {
      start: new Date(nextEvent.start.getTime() - preferredDuration * 60 * 1000),
      end: nextEvent.start,
    };
  }

  // Si no hay eventos cercanos, usar el slot por defecto
  return defaultSlot;
};

const getHourPickerEventTitle = (
  reservationForm: ReservationForm | undefined,
  reservationType: ReservationType | undefined,
  newEvent: boolean,
) => {
  if (newEvent) return 'Nueva reserva';
  if (!reservationForm) return 'Reserva existente'; // Fallback name
  return reservationType?.groupSize === 1
    ? `${capitalize(reservationType?.name)} - ${capitalize(reservationForm.externalUsers?.[0]?.name)} ${capitalize(reservationForm.externalUsers?.[0]?.surnames)}`
    : `${capitalize(reservationType?.name)}`;
};

const getBlockedEvents = (
  reservations: Reservation[] | undefined,
  reservationTypeFromId: (reservationTypeId: string) => ReservationType | undefined,
  currentReservationId?: string,
): ReservationEvent[] => {
  if (!reservations?.length) return [];

  return reservations
    .filter((reservation) => reservation.id !== currentReservationId)
    .map((reservation) => {
      const reservationType = reservationTypeFromId(reservation.reservationTypeId);
      const title = getHourPickerEventTitle(reservation, reservationType, false);

      // Crear nuevas fechas y asegurarnos de que los milisegundos están a 0
      const start = new Date(reservation.localStartDate);
      start.setMilliseconds(0);
      const end = new Date(reservation.localEndDate);
      end.setMilliseconds(0);

      return {
        id: reservation.id,
        title,
        start,
        end,
        isBlocked: true,
      };
    });
};

const findSelectedExternalUserCoupon = (
  allUserCoupons: UserCoupon[] | undefined,
  externalUsers: ExternalUserShort[] | undefined,
  filterUsedCoupons: boolean = false,
): UserCoupon | undefined => {
  if (!allUserCoupons || !externalUsers || !externalUsers.length) return undefined;
  const filteredCoupons = filterUsedCoupons ? allUserCoupons.filter((coupon) => coupon.usesRemaining > 0) : allUserCoupons;
  return filteredCoupons.find((coupon) => coupon?.userId && coupon.userId === externalUsers[0].id);
};

interface CancellationForm {
  canceledBy?: CANCELED_BY_OPTIONS;
  cancellationReason?: CancellationReason;
  customCancellationReason?: string;
}

interface CancellationInfo {
  canceledBy: CANCELED_BY_OPTIONS;
  cancellationReason: CancellationReason;
  customCancellationReason?: string;
}

interface ExternalUserWithCancellation extends ExternalUserShort {
  cancellation?: CancellationInfo;
}

interface Props extends ResourcePanelImplProps<Reservation> {
  createModeReservationTypeId?: string;
  initialHours: InitialHours | undefined;
  initialTempEventOverride?: ReservationEvent;
  clearInitialTemporaryEvent: () => void;
}

export const ReservationPanel = ({
  resource,
  visible,
  onHide,
  onIsEditingChange,
  onDeleteResource,
  clearInitialTemporaryEvent,
  isEditing = false,
  isDeleting = false,
  createModeReservationTypeId,
  initialHours,
  initialTempEventOverride,
}: Props) => {
  const navigate = useNavigate();
  const { organization } = useOrganizationContext() ?? {};
  const { user: authUser } = useAuthContext() ?? {};
  const { post, patch, get } = useClient();
  const [temporaryEvent, setTemporaryEvent] = useState<ReservationEvent | null>(null);
  const isCreating = !resource;
  const isModifying = isCreating || isEditing;
  const [showQueuedUsers, setShowQueuedUsers] = useState(false);
  const [showCancellationDialog, setShowCancellationDialog] = useState(false);
  const [showGroupCancellationDialog, setShowGroupCancellationDialog] = useState(false);
  const [cancellationForm, setCancellationForm] = useState<CancellationForm>({});
  const [cancellationErrors, setCancellationErrors] = useState<Record<string, string>>({});
  const [userToCancel, setUserToCancel] = useState<ExternalUserShort>();
  const [canceledUsers, setCanceledUsers] = useState<ExternalUserWithCancellation[]>([]);
  const [attendanceData, setAttendanceData] = useState<Record<string, boolean>>({});
  const [isAttendanceMode, setIsAttendanceMode] = useState(false);

  const { reservationTypeFromId, findReservationTypeByBranchAndName } = useReservationTypes();
  const reservationType = useMemo(
    () => reservationTypeFromId((isCreating ? createModeReservationTypeId : resource?.reservationTypeId) ?? ''),
    [createModeReservationTypeId, isCreating, reservationTypeFromId, resource?.reservationTypeId],
  );

  const initialReservationForm = useMemo(() => {
    return getInitialForm(resource, authUser, isCreating, reservationType, initialHours);
  }, [resource, authUser, isCreating, reservationType, initialHours]);
  const validators = useMemo(() => getReservationFormValidators(reservationType), [reservationType]);
  const { form, setFormFields, isSaving, setIsSaving, hasChanged, validationErrors, resetForm, validate } = useForm<
    ReservationForm | undefined
  >(initialReservationForm, !visible, validators);

  const hasSelectedExternalUsers =
    (form?.externalUsers && form.externalUsers.length > 0) || reservationType?.hasUnregisteredClients;

  const {
    data: coupons,
    isLoading: couponsLoading,
    mutate: mutateCoupons,
  } = useSWR(
    organization && reservationType?.canUseCoupon ? `/organizations/${organization.id}/coupons` : null,
    get<CouponWithUsers[]>,
  );
  const allUserCoupons: UserCoupon[] | undefined = useMemo(() => {
    if (!coupons) return undefined;
    return coupons.flatMap((coupon) => {
      const { couponUsers, ...rest } = coupon;
      return couponUsers.map((user) => {
        return {
          ...rest,
          ...user,
        };
      });
    });
  }, [coupons]);
  const selectedExternalUserCoupon: UserCoupon | undefined = useMemo(() => {
    const userCoupon = findSelectedExternalUserCoupon(allUserCoupons, form?.externalUsers, true);
    const isCurrentReservationCouponExpired = resource?.couponId && !userCoupon?.usedInReservations?.includes(resource?.id);
    if (
      userCoupon &&
      !isCurrentReservationCouponExpired &&
      (isCreating || (isModifying && !resource.couponId) || (!isCreating && form?.couponId === userCoupon.id))
    )
      return userCoupon;
    const reservationCoupon = coupons?.find((coupon) => coupon.id === form?.couponId);
    if (!reservationCoupon || !form?.externalUsers?.[0]?.id) return undefined;
    return {
      ...reservationCoupon,
      id: reservationCoupon.id,
      userId: form.externalUsers[0].id,
      usesRemaining: 0,
      expired: true,
      purchasedDate: '',
    };
  }, [allUserCoupons, form?.externalUsers, form?.couponId, resource?.couponId, resource?.id, isCreating, isModifying, coupons]);
  const selectedCouponHasUses = selectedExternalUserCoupon?.usesRemaining && selectedExternalUserCoupon?.usesRemaining > 0;
  const couponWasPurchased = !!resource?.couponId && !!resource.price && resource.price > 0;
  const couponIsExpired = !!isEditing && !!resource?.couponId && !!selectedExternalUserCoupon?.expired;

  const canSelectHour =
    isModifying && form?.selectedDay && (!reservationType?.hasAssignedUsers || form?.user?.id) && reservationType;
  const fetchReservationsUrl = reservationType?.hasAssignedUsers
    ? `/users/${form?.user?.id}/reservations?date=${form?.selectedDay?.toISOString()}`
    : `/branches/${form?.branch?.id}/reservations?date=${form?.selectedDay?.toISOString()}&reservationType=${reservationType?.id}`;
  const {
    data: existingReservations,
    isLoading: existingReservationsLoading,
    mutate: mutateExistingReservations,
  } = useSWR(canSelectHour ? fetchReservationsUrl : null, get<Reservation[]>);
  const blockedEvents = useMemo(
    () => getBlockedEvents(existingReservations, reservationTypeFromId, resource?.id) ?? [],
    [existingReservations, reservationTypeFromId, resource?.id],
  );

  const initialTemporaryEvent = useMemo(() => {
    if (!isEditing || !resource) return initialTempEventOverride;
    const start = new Date(resource.localStartDate);
    start.setMilliseconds(0);
    const end = new Date(resource.localEndDate);
    end.setMilliseconds(0);
    return { start, end, title: getHourPickerEventTitle(resource, reservationType, !isEditing), id: 'temporary' };
  }, [isEditing, resource, initialTempEventOverride, reservationType]);

  const invalidHourSelection = useMemo(() => {
    if (!blockedEvents.length || (!temporaryEvent && !initialTemporaryEvent)) {
      return false;
    }
    const eventToValidate = temporaryEvent || initialTemporaryEvent;
    if (!eventToValidate) return false;
    return !isSlotAvailable(eventToValidate.start, eventToValidate.end, blockedEvents);
  }, [blockedEvents, temporaryEvent, initialTemporaryEvent]);

  const scrollTime = useMemo(() => {
    const eventToScroll = temporaryEvent || initialTemporaryEvent;
    return eventToScroll?.start ?? set(new Date(), { hours: 7, minutes: 0 });
  }, [temporaryEvent, initialTemporaryEvent]);

  const canceledByOptions = [
    { label: 'Administrador', value: CANCELED_BY_OPTIONS.CANCELED_BY_ADMIN },
    { label: 'Usuario', value: CANCELED_BY_OPTIONS.CANCELED_BY_USER },
  ];

  const cancellationReasonOptions = [
    { label: 'Conflicto de horarios', value: CancellationReason.SCHEDULE_CONFLICT },
    { label: 'Problema de salud', value: CancellationReason.HEALTH_ISSUE },
    { label: 'Motivo personal', value: CancellationReason.PERSONAL_REASON },
    { label: 'Ya no necesita el servicio', value: CancellationReason.NO_LONGER_NEEDED },
    { label: 'Problema de transporte', value: CancellationReason.TRANSPORT_ISSUE },
    { label: 'Otro', value: CancellationReason.OTHER },
  ];

  const isPast = useMemo(() => (resource ? isPastReservation(resource) : false), [resource]);

  const handleSave = async (): Promise<boolean> => {
    const errors = validate();
    if (!form || !organization || errors || invalidHourSelection) return false;

    setIsSaving(true);

    const {
      externalUsers,
      confirmedExternalUsers,
      branch,
      user,
      selectedDay,
      useCoupon,
      couponId,
      reservationTypeId,
      localStartDate,
      localEndDate,
      unregisteredClientName,
      unregisteredClientSurnames,
      unregisteredClientPhone,
      usersBeforeSave,
      queuedExternalUsers,
      attendanceData,
      ...rest
    } = form;

    const reservationTypeForSelectedBranch = findReservationTypeByBranchAndName(branch?.id, reservationType?.name);
    if (!reservationTypeForSelectedBranch) return false;
    let unregisteredClient = undefined;
    if (reservationType?.hasUnregisteredClients) {
      unregisteredClient = {
        name: unregisteredClientName,
        surnames: unregisteredClientSurnames,
        phone: unregisteredClientPhone,
      };
    }

    if (isEditing && resource) {
      const externalUsersToSend = externalUsers
        ?.map((user) => {
          const newUser: ExternalUserReservation = {
            externalUserId: user.id,
          };
          if (canceledUsers.some((u) => u.id === user.id)) {
            newUser.cancellation = canceledUsers.find((u) => u.id === user.id)?.cancellation;
          }
          return newUser;
        })
        .filter((user) => !queuedExternalUsers?.some((u) => u.id === user.externalUserId && !user.cancellation));

      const editBody = {
        ...rest,
        userId: user?.id,
        couponId: useCoupon ? form.couponId : undefined,
        utcStartDate: form.localStartDate,
        utcEndDate: form.localEndDate,
        externalUsers: externalUsersToSend?.concat(usersBeforeSave?.map((user) => ({ externalUserId: user.id })) ?? []),
      };

      const { successMessage, errorMessages } = getToastMessages(true);
      const response = await patch<EditReservationPayload>(`/reservations/${resource.id}`, {
        body: editBody,
        successMessage,
        errorMessages,
      });

      if (response) {
        mutate(`/organizations/${organization?.id}/reservations`, undefined);
        mutateCoupons(undefined);
        mutateExistingReservations(undefined);
        // Limpiar los usuarios cancelados después de guardar
        setCanceledUsers([]);
      }

      setIsSaving(false);
      return !!response;
    }
    const createBody = {
      ...rest,
      branchId: branch?.id,
      userId: user?.id,
      ...(isCreating ? { reservationTypeId: reservationTypeForSelectedBranch?.id } : {}),
      ...(useCoupon ? { couponId: form.couponId } : {}),
      ...(reservationType?.hasUnregisteredClients ? { unregisteredClient } : {}),
      // Parse dates from UTC because the backend expects them in local TZ
      // TODO decide if we want BE to receive UTC instead to simplify some more
      utcStartDate: form.localStartDate,
      utcEndDate: form.localEndDate,
      externalUserIds: [...(externalUsers?.map((user) => user.id) ?? []), ...(usersBeforeSave?.map((user) => user.id) ?? [])],
    };

    const { successMessage, errorMessages } = getToastMessages(false);
    const response = await post<Reservation>('/reservations', {
      body: createBody,
      successMessage,
      errorMessages,
    });

    if (response) {
      mutate(`/organizations/${organization?.id}/reservations`, undefined);
      mutateCoupons(undefined);
      mutateExistingReservations(undefined);
    }

    setIsSaving(false);
    return !!response;
  };

  const handleHide = () => {
    setTemporaryEvent(null);
    onHide();
  };

  const handleAttendanceChange = async (): Promise<boolean> => {
    if (!resource || !organization || !form?.attendanceData) return false;

    const payload = {
      reservationId: resource.id,
      attendanceData: Object.entries(form.attendanceData).map(([externalUserId, attendance]) => ({
        externalUserId,
        attendance,
      })),
    };

    const response = await post('/reservations/registerAttendance', {
      body: payload,
      successMessage: {
        summary: 'Asistencias registradas',
        detail: 'Las asistencias se han registrado correctamente',
      },
      errorMessages: {
        summary: 'Error al registrar asistencias',
        defaultDetail: 'No se han podido registrar las asistencias',
      },
    });

    if (response) {
      mutate(`/organizations/${organization?.id}/reservations`, undefined);
      onHide();
    }

    return !!response;
  };

  const getGroupExternalUserButtonProps = (externalUser: ExternalUserShort, isConfirmed: boolean): ButtonProps | undefined => {
    if (!isModifying) return undefined;
    if (isPast || (resource && new Date(resource.localStartDate) <= new Date())) {
      return undefined;
    }
    return {
      icon: PrimeIcons.TRASH,
      className: 'p-button-rounded p-button-danger p-button-text',
      onClick: (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (isConfirmed) {
          handleUserCancellation(externalUser);
        } else {
          setFormFields({
            usersBeforeSave: form?.usersBeforeSave?.filter((user) => user.id !== externalUser.id) ?? [],
          });
        }
      },
    };
  };

  const renderUserAttendance = (user: ExternalUserShort) => {
    if (!isPast && !(resource && new Date(resource.localStartDate) <= new Date())) return null;
    if (!isEditing || !isAttendanceMode) {
      if (resource?.confirmedExternalUsers?.find((u) => u.id === user.id)?.attendance !== undefined) {
        return (
          <span className='user-status'>
            ({resource?.confirmedExternalUsers?.find((u) => u.id === user.id)?.attendance ? 'Asistió' : 'No asistió'})
          </span>
        );
      }
      return null;
    }

    const initialAttendance = resource?.confirmedExternalUsers?.find((u) => u.id === user.id)?.attendance ?? false;
    const currentAttendance = form?.attendanceData?.[user.id] ?? false;

    return (
      <div className='attendance-checkbox'>
        <input
          type='checkbox'
          checked={currentAttendance}
          onChange={(e) => {
            setFormFields({
              attendanceData: {
                ...(form?.attendanceData ?? {}),
                [user.id]: e.target.checked,
              },
            });
          }}
        />
        <span>{currentAttendance ? 'Asistió' : 'No asistió'}</span>
      </div>
    );
  };

  const handleUserCancellation = (user: ExternalUserShort) => {
    setUserToCancel(user);
    setShowCancellationDialog(true);
  };

  const handleDelete = async (): Promise<boolean> => {
    if (!resource) return false;

    setShowGroupCancellationDialog(true);
    return false;
  };

  const handleConfirmCancellation = async () => {
    if (!validateCancellationForm()) return;

    if (showGroupCancellationDialog) {
      // Cancelar toda la reserva
      const payload = {
        cancellation: {
          canceledBy: cancellationForm.canceledBy!,
          cancellationReason: cancellationForm.cancellationReason!,
          ...(cancellationForm.cancellationReason === CancellationReason.OTHER
            ? { customCancellationReason: cancellationForm.customCancellationReason }
            : {}),
        },
      };

      const response = await post(`/reservations/${resource!.id}/cancel`, {
        body: payload,
        successMessage: {
          summary: 'Reserva cancelada',
          detail: 'La reserva se ha cancelado correctamente',
        },
        errorMessages: {
          summary: 'Error al cancelar la reserva',
          defaultDetail: 'No se ha podido cancelar la reserva',
        },
      });

      if (response) {
        mutate(`/organizations/${organization?.id}/reservations`, undefined);
        onHide();
      }

      setShowGroupCancellationDialog(false);
    } else {
      // Para reservas de grupo, guardamos la información de cancelación
      if (reservationType?.groupSize && reservationType.groupSize > 1 && userToCancel) {
        const cancellation: CancellationInfo = {
          canceledBy: cancellationForm.canceledBy!,
          cancellationReason: cancellationForm.cancellationReason!,
          ...(cancellationForm.cancellationReason === CancellationReason.OTHER
            ? { customCancellationReason: cancellationForm.customCancellationReason }
            : {}),
        };

        // Actualizar la lista de usuarios confirmados y mover el siguiente de la cola si existe
        const updatedConfirmedUsers = form?.confirmedExternalUsers?.filter((u) => u.id !== userToCancel.id) ?? [];

        // Si hay usuarios en cola, mover el primero a confirmados
        let updatedQueuedUsers = [...(form?.queuedExternalUsers ?? [])];
        if (updatedQueuedUsers.length > 0) {
          const [nextUser, ...remainingQueuedUsers] = updatedQueuedUsers;
          updatedConfirmedUsers.push(nextUser);
          updatedQueuedUsers = remainingQueuedUsers;
        }

        // Guardar el usuario cancelado con su información de cancelación
        setCanceledUsers([...canceledUsers, { ...userToCancel, cancellation }]);

        setFormFields({
          confirmedExternalUsers: updatedConfirmedUsers,
          queuedExternalUsers: updatedQueuedUsers,
        });
      } else {
        // Para reservas individuales, usar el mismo endpoint que para cancelación completa
        const payload = {
          cancellation: {
            canceledBy: cancellationForm.canceledBy!,
            cancellationReason: cancellationForm.cancellationReason!,
            ...(cancellationForm.cancellationReason === CancellationReason.OTHER
              ? { customCancellationReason: cancellationForm.customCancellationReason }
              : {}),
          },
        };

        const response = await post(`/reservations/${resource!.id}/cancel`, {
          body: payload,
          successMessage: {
            summary: 'Reserva cancelada',
            detail: 'La reserva se ha cancelado correctamente',
          },
          errorMessages: {
            summary: 'Error al cancelar la reserva',
            defaultDetail: 'No se ha podido cancelar la reserva',
          },
        });

        if (response) {
          mutate(`/organizations/${organization?.id}/reservations`, undefined);
          onHide();
        }
      }

      setShowCancellationDialog(false);
    }

    setCancellationForm({});
    setUserToCancel(undefined);
  };

  const validateCancellationForm = () => {
    const errors: Record<string, string> = {};
    if (!cancellationForm.canceledBy) {
      errors.canceledBy = 'Debes seleccionar quién cancela la reserva';
    }
    if (!cancellationForm.cancellationReason) {
      errors.cancellationReason = 'Debes seleccionar un motivo de cancelación';
    }
    if (cancellationForm.cancellationReason === CancellationReason.OTHER && !cancellationForm.customCancellationReason) {
      errors.customCancellationReason = 'Debes especificar el motivo de cancelación';
    }
    setCancellationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const getCouponNoteText = () => {
    if (!isModifying) return;
    if (isEditing && couponWasPurchased && !selectedExternalUserCoupon?.expired) {
      return 'No es posible modificar la adquisición de un bono';
    }
    if (couponIsExpired) {
      return 'No se puede modificar el uso de un bono expirado';
    }
    if (
      !resource?.couponId &&
      form?.useCoupon &&
      form?.couponId &&
      !selectedExternalUserCoupon?.expired &&
      selectedCouponHasUses
    ) {
      return 'Se gastará un uso del bono';
    }
    if (!resource?.couponId && form?.useCoupon && form?.couponId && form.couponId !== resource?.couponId) {
      return 'Se creará un nuevo bono';
    }
    if (isEditing && !form?.useCoupon && resource?.couponId) {
      return 'Se devolverá un uso del bono';
    }
    return '';
  };

  const renderCouponNote = () => {
    const text = getCouponNoteText();
    if (!text) return null;
    return (
      <small className='note coupon-note'>
        <i className={classNames(PrimeIcons.INFO_CIRCLE, 'primary-icon')} />
        {text}
      </small>
    );
  };

  const handleAttendanceClick = async (): Promise<boolean> => {
    setIsAttendanceMode(true);
    onIsEditingChange?.(true);
    return true;
  };

  console.log('reserva', resource);
  console.log('form', form);
  return (
    <>
      {/* Diálogo para cancelar usuario individual */}
      <Dialog
        visible={showCancellationDialog}
        onHide={() => {
          setShowCancellationDialog(false);
          setCancellationForm({});
          setCancellationErrors({});
          setUserToCancel(undefined);
        }}
        header={`Cancelar usuario ${userToCancel ? `(${userToCancel.name} ${userToCancel.surnames})` : ''}`}
        className='cancellation-dialog'
        footer={
          <div className='dialog-footer'>
            <Button
              label='Volver'
              icon='pi pi-times'
              onClick={() => {
                setShowCancellationDialog(false);
                setCancellationForm({});
                setCancellationErrors({});
                setUserToCancel(undefined);
              }}
              className='p-button-text'
            />
            <Button
              label='Confirmar cancelación'
              icon='pi pi-check'
              onClick={handleConfirmCancellation}
              className='p-button-danger'
              autoFocus
            />
          </div>
        }
      >
        <div className='cancellation-warning'>
          <i className={classNames(PrimeIcons.EXCLAMATION_TRIANGLE, 'warning-icon')} />
          <p>Esta acción cancelará la reserva para este usuario.</p>
          <p>Se enviará un correo electrónico al cliente notificándole de la cancelación.</p>
        </div>
        {reservationType?.groupSize === 1 &&
          (reservationType.hasUnregisteredClients ? (
            <div className='unregistered-client-container'>
              <FormField
                labelTitle='Nombre'
                elementId='unregistered-client-name'
                error={validationErrors?.unregisteredClientName}
                required={isModifying}
              >
                {isModifying ? (
                  <InputText
                    id='unregistered-client-name'
                    value={form?.unregisteredClientName ?? ''}
                    onChange={(e) => setFormFields({ unregisteredClientName: e.target.value })}
                    placeholder='Nombre del cliente'
                    className={classNames({ 'p-invalid': validationErrors?.unregisteredClientName })}
                  />
                ) : (
                  <p>{form?.unregisteredClientName}</p>
                )}
              </FormField>
              <FormField
                labelTitle='Apellidos'
                elementId='unregistered-client-surnames'
                error={validationErrors?.unregisteredClientSurnames}
                required={isModifying}
              >
                {isModifying ? (
                  <InputText
                    id='unregistered-client-surnames'
                    value={form?.unregisteredClientSurnames ?? ''}
                    onChange={(e) => setFormFields({ unregisteredClientSurnames: e.target.value })}
                    placeholder='Apellidos del cliente'
                    className={classNames({ 'p-invalid': validationErrors?.unregisteredClientSurnames })}
                  />
                ) : (
                  <p>{form?.unregisteredClientSurnames}</p>
                )}
              </FormField>
              <FormField
                labelTitle='Teléfono'
                elementId='unregistered-client-phone'
                error={validationErrors?.unregisteredClientPhone}
              >
                {isModifying ? (
                  <InputText
                    id='unregistered-client-phone'
                    value={form?.unregisteredClientPhone ?? ''}
                    onChange={(e) => setFormFields({ unregisteredClientPhone: e.target.value })}
                    placeholder='Teléfono del cliente'
                    className={classNames({ 'p-invalid': validationErrors?.unregisteredClientPhone })}
                  />
                ) : (
                  <p>{form?.unregisteredClientPhone}</p>
                )}
              </FormField>
              {!isModifying && resource && (
                <Button
                  link
                  icon={PrimeIcons.USER_PLUS}
                  label='Crear usuario con estos datos'
                  className='create-user-button'
                  onClick={() => {
                    navigate(
                      `/users?name=${form?.unregisteredClientName}&surnames=${form?.unregisteredClientSurnames}&phone=${form?.unregisteredClientPhone}&branchId=${form?.branch?.id}`,
                    );
                  }}
                />
              )}
            </div>
          ) : (
            <FormField
              labelTitle={reservationType.hasUnregisteredClients ? 'Datos del cliente' : 'Cliente'}
              elementId='reservation-user'
              fullWidth
              error={validationErrors?.externalUsers}
              required={isModifying}
            >
              <ExternalUserField
                isModifying={isCreating}
                user={form?.confirmedExternalUsers?.[0] ?? form?.externalUsers?.[0]}
                onChange={(externalUser) => {
                  const externalUserCoupon = findSelectedExternalUserCoupon(allUserCoupons, [externalUser], true);

                  setFormFields({
                    externalUsers: [externalUser],
                    useCoupon: !!externalUserCoupon,
                    couponId: externalUserCoupon?.id,
                    ...(externalUserCoupon ? { price: 0 } : {}),
                  });
                }}
                error={validationErrors?.externalUsers}
              />
              {!isCreating && form?.confirmedExternalUsers?.[0] && (
                <div className='user-display-container'>{renderUserAttendance(form.confirmedExternalUsers[0])}</div>
              )}
            </FormField>
          ))}
        <div className='cancellation-form'>
          <FormField labelTitle='¿Quién cancela?' error={cancellationErrors.canceledBy} required>
            <Dropdown
              value={cancellationForm.canceledBy}
              options={canceledByOptions}
              onChange={(e) => setCancellationForm({ ...cancellationForm, canceledBy: e.value })}
              placeholder='Selecciona quién cancela'
              className={classNames({ 'p-invalid': cancellationErrors.canceledBy })}
            />
          </FormField>

          <FormField labelTitle='Motivo de cancelación' error={cancellationErrors.cancellationReason} required>
            <Dropdown
              value={cancellationForm.cancellationReason}
              options={cancellationReasonOptions}
              onChange={(e) => setCancellationForm({ ...cancellationForm, cancellationReason: e.value })}
              placeholder='Selecciona el motivo'
              className={classNames({ 'p-invalid': cancellationErrors.cancellationReason })}
            />
          </FormField>

          {cancellationForm.cancellationReason === CancellationReason.OTHER && (
            <FormField labelTitle='Especifica el motivo' error={cancellationErrors.customCancellationReason} required>
              <InputTextarea
                value={cancellationForm.customCancellationReason}
                onChange={(e) => setCancellationForm({ ...cancellationForm, customCancellationReason: e.target.value })}
                rows={3}
                className={classNames({ 'p-invalid': cancellationErrors.customCancellationReason })}
              />
            </FormField>
          )}
        </div>
      </Dialog>

      {/* Diálogo para cancelar reserva completa */}
      <Dialog
        visible={showGroupCancellationDialog}
        onHide={() => {
          setShowGroupCancellationDialog(false);
          setCancellationForm({});
          setCancellationErrors({});
        }}
        header='Cancelar reserva'
        className='cancellation-dialog'
        footer={
          <div className='dialog-footer'>
            <Button
              label='Volver'
              icon='pi pi-times'
              onClick={() => {
                setShowGroupCancellationDialog(false);
                setCancellationForm({});
                setCancellationErrors({});
              }}
              className='p-button-text'
            />
            <Button
              label='Confirmar cancelación'
              icon='pi pi-check'
              onClick={handleConfirmCancellation}
              className='p-button-danger'
              autoFocus
            />
          </div>
        }
      >
        <div className='cancellation-warning'>
          <i className={classNames(PrimeIcons.EXCLAMATION_TRIANGLE, 'warning-icon')} />
          {reservationType?.groupSize && reservationType.groupSize > 1 ? (
            <>
              <p>Esta acción cancelará la reserva para todos los usuarios del grupo.</p>
              <p>Se enviará un correo electrónico a cada participante notificándoles de la cancelación.</p>
            </>
          ) : (
            <>
              <p>Esta acción cancelará la reserva.</p>
              <p>Se enviará un correo electrónico al cliente notificándole de la cancelación.</p>
            </>
          )}
        </div>
        <div className='cancellation-form'>
          <FormField labelTitle='¿Quién cancela?' error={cancellationErrors.canceledBy} required>
            <Dropdown
              value={cancellationForm.canceledBy}
              options={canceledByOptions}
              onChange={(e) => setCancellationForm({ ...cancellationForm, canceledBy: e.value })}
              placeholder='Selecciona quién cancela'
              className={classNames({ 'p-invalid': cancellationErrors.canceledBy })}
            />
          </FormField>

          <FormField labelTitle='Motivo de cancelación' error={cancellationErrors.cancellationReason} required>
            <Dropdown
              value={cancellationForm.cancellationReason}
              options={cancellationReasonOptions}
              onChange={(e) => setCancellationForm({ ...cancellationForm, cancellationReason: e.value })}
              placeholder='Selecciona el motivo'
              className={classNames({ 'p-invalid': cancellationErrors.cancellationReason })}
            />
          </FormField>

          {cancellationForm.cancellationReason === CancellationReason.OTHER && (
            <FormField labelTitle='Especifica el motivo' error={cancellationErrors.customCancellationReason} required>
              <InputTextarea
                value={cancellationForm.customCancellationReason}
                onChange={(e) => setCancellationForm({ ...cancellationForm, customCancellationReason: e.target.value })}
                rows={3}
                className={classNames({ 'p-invalid': cancellationErrors.customCancellationReason })}
              />
            </FormField>
          )}
        </div>
      </Dialog>

      <Panel
        panelType='resourcePanel'
        title={getPanelTitle(isCreating, isEditing, reservationType?.name, resource)}
        resourceName='reserva'
        visible={visible}
        onHide={handleHide}
        onDelete={
          !isCreating && (isPast || (resource && new Date(resource.localStartDate) <= new Date()))
            ? handleAttendanceClick
            : !isPast
              ? () => handleDelete()
              : undefined
        }
        isDeleting={isDeleting}
        onSave={
          isEditing && (isPast || (resource && new Date(resource.localStartDate) <= new Date()))
            ? isAttendanceMode
              ? handleAttendanceChange
              : handleSave
            : handleSave
        }
        onIsEditingChange={(editing) => {
          if (!editing) {
            setIsAttendanceMode(false);
          }
          onIsEditingChange?.(editing);
        }}
        hasChanged={hasChanged}
        isSaving={isSaving}
        isEditing={isEditing}
        isCreating={isCreating}
        resetForm={resetForm}
        className='reservation-panel'
        hasCustomDelete={isPast || (resource && new Date(resource.localStartDate) <= new Date())}
        showEditButton={
          !(isPast || (resource && new Date(resource.localStartDate) <= new Date())) ||
          (reservationType?.groupSize === 1 && !isAttendanceMode)
        }
        showAttendanceButton={isPast || (resource && new Date(resource.localStartDate) <= new Date())}
        onAttendanceClick={handleAttendanceClick}
      >
        <form
          className={classNames({
            'form-loading': isSaving,
          })}
          onSubmit={handleSave}
        >
          {reservationType?.groupSize === 1 && (
            <FormField
              labelTitle='Cliente'
              elementId='reservation-user'
              fullWidth
              error={validationErrors?.externalUsers}
              required={isModifying}
            >
              <ExternalUserField
                isModifying={isCreating}
                user={form?.confirmedExternalUsers?.[0] ?? form?.externalUsers?.[0]}
                onChange={(externalUser) => {
                  const externalUserCoupon = findSelectedExternalUserCoupon(allUserCoupons, [externalUser], true);

                  setFormFields({
                    externalUsers: [externalUser],
                    useCoupon: !!externalUserCoupon,
                    couponId: externalUserCoupon?.id,
                    ...(externalUserCoupon ? { price: 0 } : {}),
                  });
                }}
                error={validationErrors?.externalUsers}
              />
              {!isCreating && form?.confirmedExternalUsers?.[0] && (
                <div className='user-display-container'>{renderUserAttendance(form.confirmedExternalUsers[0])}</div>
              )}
            </FormField>
          )}

          {reservationType?.groupSize && reservationType.groupSize > 1 && (
            <FormField labelTitle='Clientes' fullWidth error={validationErrors?.externalUsers} required={isModifying}>
              {Array.from({ length: reservationType.groupSize }).map((_, index) => {
                const users = (form?.confirmedExternalUsers ?? []).concat(form?.usersBeforeSave ?? []);
                const user = users[index];
                return user ? (
                  <div key={user.id} className='user-display-container'>
                    <div className='user-info'>
                      <UserDisplay
                        name={user.name}
                        surnames={user.surnames}
                        avatarUrl={user.avatarUrl}
                        buttonProps={getGroupExternalUserButtonProps(user, true)}
                      />
                    </div>
                    {renderUserAttendance(user)}
                    {form?.usersBeforeSave?.some((u) => u.id === user.id) && (
                      <span className='pending-user-note'>(no guardado)</span>
                    )}
                  </div>
                ) : (
                  <UserDisplay key={index} fullName='Plaza libre' isPlaceholder />
                );
              })}
              {isModifying &&
                !isPast &&
                (form?.confirmedExternalUsers ?? []).concat(form?.usersBeforeSave ?? []).length <
                  (reservationType?.groupSize ?? 0) && (
                  <div className='button-container'>
                    <ExternalUsersDropdownButton
                      disabled={
                        (form?.externalUsers?.length ?? 0) + (form?.confirmedExternalUsers?.length ?? 0) >=
                        (reservationType?.groupSize ?? 0)
                      }
                      onSelect={(user) => {
                        setFormFields({ usersBeforeSave: [...(form?.usersBeforeSave ?? []), user] });
                      }}
                      excludedUserIds={[
                        ...(form?.externalUsers ?? []),
                        ...(form?.confirmedExternalUsers ?? []),
                        ...(form?.queuedExternalUsers ?? []),
                        ...(canceledUsers ?? []),
                        ...(form?.usersBeforeSave ?? []),
                      ].map((user) => user.id)}
                    />
                  </div>
                )}
              {form?.queuedExternalUsers && form.queuedExternalUsers.length > 0 && (
                <div className='queued-users-section'>
                  <div
                    className={classNames('queued-users-header', { expanded: showQueuedUsers })}
                    onClick={() => setShowQueuedUsers(!showQueuedUsers)}
                  >
                    <i className='pi pi-chevron-right' />
                    <span>
                      Lista de espera ({form.queuedExternalUsers.length}{' '}
                      {form.queuedExternalUsers.length === 1 ? 'usuario' : 'usuarios'})
                    </span>
                  </div>
                  {showQueuedUsers && (
                    <div className='queued-users-list'>
                      {form.queuedExternalUsers.map((user) => (
                        <UserDisplay key={user.id} name={user.name} surnames={user.surnames} avatarUrl={user.avatarUrl} />
                      ))}
                    </div>
                  )}
                </div>
              )}
            </FormField>
          )}

          {(isModifying || resource.couponId) &&
            reservationType?.hasPrice &&
            reservationType?.canUseCoupon &&
            (!isAttendanceMode || !isPast) && (
              <FormField
                labelTitle='Bono'
                elementId='reservation-coupon'
                fullWidth
                error={validationErrors?.couponId}
                required={isModifying && reservationType.priceRequired}
              >
                {hasSelectedExternalUsers && isModifying && !couponWasPurchased && !couponIsExpired && (
                  <Switch
                    id='reservation-coupon-toggle'
                    label='Pagar sesión con bono'
                    className={classNames('reservation-coupon-toggle', {
                      inactive: !form?.useCoupon,
                    })}
                    primeSwitchProps={{
                      checked: !!form?.useCoupon,
                      onChange: (e) => {
                        const isChecked = e.value;
                        let price = undefined;
                        let newCouponId;
                        if (isEditing && !resource?.couponId) {
                          const newCoupon = findSelectedExternalUserCoupon(allUserCoupons, form?.externalUsers, true);
                          newCouponId = newCoupon?.id;
                          price = newCoupon?.price;
                        }
                        if (selectedExternalUserCoupon && isChecked) {
                          price = resource?.couponId ? resource?.price : 0;
                        }
                        if (!selectedExternalUserCoupon && isChecked && form?.couponId) {
                          price = coupons?.find((coupon) => coupon.id === form.couponId)?.price;
                        }
                        setFormFields({
                          useCoupon: isChecked,
                          price,
                          ...(newCouponId ? { couponId: newCouponId } : {}),
                        });
                      },
                      invalid: !!validationErrors?.useCoupon,
                    }}
                  />
                )}
                {hasSelectedExternalUsers &&
                  form?.useCoupon &&
                  (selectedExternalUserCoupon &&
                  ((isCreating && selectedCouponHasUses) ||
                    (!isCreating && resource?.couponId) ||
                    (isEditing && !resource?.couponId && selectedCouponHasUses)) ? (
                    <CouponDisplay coupon={selectedExternalUserCoupon} />
                  ) : (
                    <Dropdown
                      id='reservation-coupon'
                      options={getCouponOptions(coupons)}
                      value={form?.couponId}
                      onChange={(e) => {
                        const couponId = e.value;
                        const price = couponId ? coupons?.find((coupon) => coupon.id === couponId)?.price : undefined;
                        setFormFields({ couponId, price });
                      }}
                      invalid={!!validationErrors?.couponId}
                      emptyMessage='No hay bonos'
                      emptyFilterMessage='No se han encontrado bonos'
                      placeholder='Elige un bono'
                      loading={couponsLoading}
                    />
                  ))}

                {renderCouponNote()}
                {!hasSelectedExternalUsers && <FormFieldMessage message='Selecciona un cliente' />}
              </FormField>
            )}

          {reservationType?.hasPrice && (
            <FormField
              labelTitle='Precio de la sesión'
              elementId='reservation-price'
              fullWidth
              error={validationErrors?.price}
              required={isModifying && reservationType.priceRequired}
            >
              {isModifying && (!isAttendanceMode || !isPast) ? (
                <>
                  {hasSelectedExternalUsers && (
                    <div className='p-inputgroup'>
                      <InputNumber
                        id='reservation-price'
                        invalid={!!validationErrors?.price}
                        useGrouping={false}
                        placeholder={`Escribe el precio de la reserva`}
                        value={form?.price}
                        disabled={form?.useCoupon}
                        onChange={(e) =>
                          setFormFields({
                            price: e.value ?? undefined,
                          })
                        }
                      />
                      <span className='p-inputgroup-addon'>€</span>
                    </div>
                  )}
                  {!hasSelectedExternalUsers && <FormFieldMessage message='Selecciona un cliente' />}
                </>
              ) : (
                <div className='form-field-row'>
                  <span className='price-value'>{form?.price || form?.price === 0 ? `${form.price} €` : '-'}</span>
                  {form?.couponId && (
                    <small className='price-info note'>{resource?.price ? 'Bono adquirido' : 'Pagado con bono'}</small>
                  )}
                </div>
              )}
            </FormField>
          )}

          {reservationType?.hasComment && (
            <FormField labelTitle='Comentario' elementId='reservation-comment' fullWidth error={validationErrors?.comment}>
              {isModifying && (!isAttendanceMode || !isPast) ? (
                <InputTextarea
                  id='reservation-comment'
                  className={classNames({
                    'p-invalid': validationErrors?.comment,
                  })}
                  placeholder={`Escribe un comentario sobre la reserva`}
                  autoResize
                  value={form?.comment ?? ''}
                  onChange={(e: any) =>
                    setFormFields({
                      comment: e.target.value,
                    })
                  }
                  rows={3}
                />
              ) : (
                <p>{form?.comment || '-'}</p>
              )}
            </FormField>
          )}

          <FormField elementId='reservation-branch' labelTitle='Sucursal' required={isModifying} error={validationErrors?.branch}>
            {isCreating ? (
              <BranchesSelect
                id='reservation-branch'
                isSingle
                error={validationErrors?.branch}
                value={form?.branch?.id}
                onChange={(branch) => {
                  // Si hay un usuario seleccionado, verificar si pertenece a la nueva sucursal
                  const userBelongsToBranch = branch?.id && form?.user?.branchIds?.includes(branch.id);
                  if (form?.user && (!branch || !userBelongsToBranch)) {
                    setTemporaryEvent(null);
                    clearInitialTemporaryEvent();
                    setFormFields({
                      branch,
                      user: undefined,
                    });
                  } else {
                    setFormFields({ branch });
                  }
                }}
              />
            ) : (
              <p>{form?.branch?.name}</p>
            )}
          </FormField>

          {reservationType?.hasAssignedUsers && (
            <FormField
              labelTitle='Empleado'
              elementId='reservation-employee'
              fullWidth
              error={validationErrors?.user}
              required={isModifying}
            >
              {isModifying && !isPast ? (
                <UsersSelect
                  id='reservation-employee'
                  value={form?.user?.id}
                  isSingle
                  onChange={(user) => {
                    setFormFields({
                      user: user,
                      localStartDate: undefined,
                      localEndDate: undefined,
                    });
                  }}
                  error={validationErrors?.user}
                  filterByBranchIds={form?.branch?.id ? [form?.branch?.id] : undefined}
                  disabled={!form?.branch?.id}
                />
              ) : (
                <UserDisplay name={form?.user?.name} surnames={form?.user?.surnames} avatarUrl={form?.user?.avatarUrl} />
              )}
            </FormField>
          )}

          <FormField
            labelTitle='Día'
            elementId='reservation-day'
            fullWidth
            error={validationErrors?.selectedDay}
            required={isModifying}
          >
            {(isCreating || (isEditing && reservationType?.canModifyDate)) && !isPast ? (
              <Calendar
                id='reservation-day'
                value={form?.selectedDay}
                invalid={!!validationErrors?.selectedDay}
                placeholder='Selecciona fecha de la reserva'
                onChange={(e) => {
                  if (e.value) {
                    const newStartDate = form?.localStartDate
                      ? set(form.localStartDate, { date: e.value.getDate() }).toISOString()
                      : undefined;
                    const newEndDate = form?.localEndDate
                      ? set(form.localEndDate, { date: e.value.getDate() }).toISOString()
                      : undefined;
                    setFormFields({
                      selectedDay: middleOfDay(e.value),
                      localStartDate: newStartDate,
                      localEndDate: newEndDate,
                    });
                    if (newStartDate && newEndDate) {
                      setTemporaryEvent({
                        id: 'temporary',
                        title: getHourPickerEventTitle(resource, reservationType, true),
                        start: new Date(newStartDate),
                        end: new Date(newEndDate),
                      });
                      clearInitialTemporaryEvent();
                    }
                  }
                }}
                showIcon
                dateFormat='dd/mm/yy'
                minDate={new Date()}
              />
            ) : (
              getDayDisplay(form?.localStartDate)
            )}
          </FormField>

          <FormField
            labelTitle='Hora'
            elementId='reservation-hours'
            fullWidth
            error={
              validationErrors?.localStartDate ||
              validationErrors?.localEndDate ||
              (invalidHourSelection ? 'La hora seleccionada no está disponible' : undefined)
            }
            required={isModifying}
            className='reservation-hour-container'
          >
            {(isCreating || (isEditing && reservationType?.canModifyDate)) && !isPast ? (
              canSelectHour ? (
                <div className='daily-calendar-container' id='reservation-hours'>
                  {existingReservationsLoading ? (
                    <Skeleton height='100%' />
                  ) : (
                    <DragAndDropCalendar
                      localizer={calendarLocalizer}
                      date={form?.selectedDay}
                      scrollToTime={scrollTime}
                      onNavigate={() => {}}
                      defaultView={Views.DAY}
                      views={[Views.DAY]}
                      events={[
                        ...(blockedEvents ?? []),
                        ...(temporaryEvent ? [temporaryEvent] : []),
                        ...(initialTemporaryEvent && !temporaryEvent ? [initialTemporaryEvent] : []),
                      ]}
                      step={5}
                      timeslots={6}
                      min={set(new Date(), { hours: 7, minutes: 0 })}
                      max={set(new Date(), { hours: 22, minutes: 0 })}
                      selectable
                      toolbar={false}
                      eventPropGetter={(event: ReservationEvent) => ({
                        className: classNames({
                          'blocked-event': event.isBlocked,
                          'temporary-event': event.id === 'temporary',
                        }),
                      })}
                      onSelecting={() => !reservationType?.fixedDuration}
                      onSelectSlot={(slotInfo: SlotInfo) => {
                        // Si es un arrastre y la duración es fija, no permitimos crear el evento
                        if (slotInfo.action === 'select' && reservationType?.fixedDuration) {
                          return;
                        }

                        // Si es un arrastre y la duración no es fija, usamos el rango seleccionado
                        if (slotInfo.action === 'select' && !reservationType?.fixedDuration) {
                          const start = new Date(slotInfo.start);
                          start.setMilliseconds(0);
                          const end = new Date(slotInfo.end);
                          end.setMilliseconds(0);

                          const newEvent = {
                            id: 'temporary',
                            title: getHourPickerEventTitle(resource, reservationType, !isEditing),
                            start,
                            end,
                          };

                          if (!isSlotAvailable(newEvent.start, newEvent.end, blockedEvents)) {
                            return;
                          }

                          setTemporaryEvent(newEvent);
                          clearInitialTemporaryEvent();
                          setFormFields({
                            localStartDate: newEvent.start.toISOString(),
                            localEndDate: newEvent.end.toISOString(),
                          });
                          return;
                        }

                        // Si es un click, buscamos el slot más cercano disponible
                        const slot = findNearestAvailableSlot(slotInfo.start, blockedEvents);
                        if (!slot) return;

                        // Si la duración es fija, verificar que el slot tiene exactamente 1 hora
                        if (reservationType?.fixedDuration) {
                          const duration = (slot.end.getTime() - slot.start.getTime()) / (1000 * 60); // duración en minutos
                          if (duration < 60) return; // Si el slot es más corto de 1 hora, no permitimos crear el evento
                        }

                        const newEvent = {
                          id: 'temporary',
                          title: getHourPickerEventTitle(resource, reservationType, !isEditing),
                          start: slot.start,
                          end: slot.end,
                        };

                        if (!isSlotAvailable(newEvent.start, newEvent.end, blockedEvents)) {
                          return;
                        }

                        setTemporaryEvent(newEvent);
                        clearInitialTemporaryEvent();
                        setFormFields({
                          localStartDate: newEvent.start.toISOString(),
                          localEndDate: newEvent.end.toISOString(),
                        });
                      }}
                      resizable={!reservationType?.fixedDuration}
                      onEventResize={({ event, start, end }: EventInteractionArgs<ReservationEvent>) => {
                        if (event.isBlocked || reservationType?.fixedDuration) return;

                        if (start instanceof Date && end instanceof Date) {
                          if (!isSlotAvailable(start, end, blockedEvents)) {
                            return;
                          }

                          if (event.id === 'temporary') {
                            setTemporaryEvent({
                              ...event,
                              start,
                              end,
                            });
                            clearInitialTemporaryEvent();
                          }
                          setFormFields({
                            localStartDate: start.toISOString(),
                            localEndDate: end.toISOString(),
                          });
                        }
                      }}
                      onEventDrop={({ event, start, end }: EventInteractionArgs<ReservationEvent>) => {
                        if (event.isBlocked) return;

                        if (start instanceof Date && end instanceof Date) {
                          if (!isSlotAvailable(start, end, blockedEvents)) {
                            return;
                          }

                          if (event.id === 'temporary') {
                            setTemporaryEvent({
                              ...event,
                              start,
                              end,
                            });
                            clearInitialTemporaryEvent();
                          }
                          setFormFields({
                            localStartDate: start.toISOString(),
                            localEndDate: end.toISOString(),
                          });
                        }
                      }}
                      className={classNames('daily-calendar', {
                        'p-invalid':
                          !!validationErrors?.localStartDate || !!validationErrors?.localEndDate || invalidHourSelection,
                        'invalid-selection': invalidHourSelection,
                      })}
                      messages={calendarMessages}
                    />
                  )}
                </div>
              ) : (
                <FormFieldMessage message='Elige empleado y fecha' />
              )
            ) : (
              getHourDisplay(form?.localStartDate, form?.localEndDate)
            )}
          </FormField>
        </form>
      </Panel>
    </>
  );
};
