import { Branch } from '../types/branch';
import { CouponWithUsers } from '../types/coupon';
import { ExerciseShort } from '../types/Fisiofit/exercise';
import { Role } from '../types/role';

export const ALL_SELECT_OPTION = 'all';

export const getRoleOptions = (roles?: Role[]) => {
  return roles?.map((role) => ({
    label: role.name,
    value: role.id,
  }));
};

export const getBranchOptions = (branches?: Branch[], selectAll = false) => {
  return [
    ...(selectAll ? [{ label: 'Todas las sucursales', value: ALL_SELECT_OPTION }] : []),
    ...(branches?.map((branch) => ({
      label: branch.name,
      value: branch.id,
    })) || []),
  ];
};

export const getExerciseOptions = (exercises?: ExerciseShort[]) => {
  return exercises?.map((exercise) => ({
    label: exercise.name,
    value: exercise.id,
  }));
};

export const getCouponOptions = (coupons?: CouponWithUsers[]) => {
  return coupons?.map((coupon) => ({
    label: coupon.name,
    value: coupon.id,
  }));
};
