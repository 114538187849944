import { PrimeIcons } from 'primereact/api';
import { Reservation } from '../types/reservation';
import { USER_ROUTINE_EVENTS } from '../types/externalUser';
import { SummaryItem } from '../components/CalendarToolbar/CalendarToolbar';

export enum PaymentType {
  CASH = 'cash',
  BONUS = 'bonus',
}

interface ExpenseEvent {
  amount: number;
  paymentType: 'cash' | 'bonus';
}

interface RoutineEvent {
  eventType: USER_ROUTINE_EVENTS;
}

interface ExpenseEventDto {
  id: string;
  title: string;
  start: string;
  amount: number;
  paymentType: PaymentType;
  reservationId: string;
  employee?: {
    id: string;
    name: string;
    surnames: string;
  };
}

interface ExpenseSummaryTotals {
  totalAmount: number;
  totalCashAmount: number;
  totalBonusAmount: number;
}

export const createReservationSummaryItems = (events: Reservation[]): SummaryItem[] => {
  const totalReservations = events.length;
  const upcomingReservations = events.filter((event) => new Date(event.localStartDate) > new Date()).length;
  const pastReservations = events.filter((event) => new Date(event.localStartDate) <= new Date()).length;

  return [
    {
      icon: PrimeIcons.CALENDAR,
      label: 'Total',
      value: totalReservations,
      highlight: true,
    },
    {
      icon: PrimeIcons.CALENDAR_PLUS,
      label: 'Próximas',
      value: upcomingReservations,
    },
    {
      icon: PrimeIcons.CALENDAR_MINUS,
      label: 'Pasadas',
      value: pastReservations,
    },
  ];
};

export const createRoutineSummaryItems = (events: RoutineEvent[]): SummaryItem[] => {
  const totalRoutines = events.length;
  const currentRoutines = events.filter((event) => event.eventType === USER_ROUTINE_EVENTS.CURRENT_ROUTINE).length;
  const updatedRoutines = events.filter((event) => event.eventType === USER_ROUTINE_EVENTS.UPDATE).length;

  return [
    {
      icon: PrimeIcons.LIST,
      label: 'Total',
      value: totalRoutines,
      highlight: true,
    },
    {
      icon: PrimeIcons.CHECK_CIRCLE,
      label: 'Actuales',
      value: currentRoutines,
    },
    {
      icon: PrimeIcons.SYNC,
      label: 'Actualizaciones',
      value: updatedRoutines,
    },
  ];
};

export const createExpenseSummaryItems = (events: ExpenseEventDto[], totals: ExpenseSummaryTotals): SummaryItem[] => {
  return [
    {
      icon: PrimeIcons.EURO,
      label: 'Total',
      value: totals.totalAmount,
      suffix: '€',
      highlight: true,
    },
    {
      icon: PrimeIcons.MONEY_BILL,
      label: 'Efectivo',
      value: totals.totalCashAmount,
      suffix: '€',
    },
    {
      icon: PrimeIcons.TICKET,
      label: 'Bonos',
      value: totals.totalBonusAmount,
      suffix: '€',
    },
  ];
};
