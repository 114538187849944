import { Dispatch, SetStateAction, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { areFormObjectsDifferent } from '../utils/object-comparer';
import { useConfirmBeforeExit } from './useConfirmBeforeExit';

export const VALIDATION_MESSAGES = {
  EMPTY: 'El campo no puede estar vacío',
  EMPTY_IMAGE: 'Debes añadir una imagen',
  NOT_EMAIL: 'Debes introducir un correo electrónico',
  INVALID_PASSWORD: 'La contraseña no es válida',
  MAX_LENGTH: (max: number) => `El campo no puede exceder ${max} caracteres`,
  ONLY_LETTERS_AND_SPACES: 'El campo solo puede contener letras y espacios',
  ONLY_LETTERS_SPACES_AND_NUMBERS: 'El campo solo puede contener letras, espacios y números',
  NOT_PHONE: 'Debes introducir un teléfono',
  NOT_DATE_OF_BIRTH: 'Debes introducir una fecha de nacimiento',
  NOT_BRANCHES: 'Debes seleccionar al menos una sucursal',
  NOT_ROLE: 'Debes seleccionar un rol',
  NOT_ROLE_NAME: 'Debes introducir un nombre para el rol',
  NOT_ROLE_FEATURES: 'Debes seleccionar al menos una característica',
  NOT_PRICE: 'Debes introducir un precio',
  NOT_USAGE_LIMIT: 'Debes introducir un límite de uso',
  NOT_FEATURES: 'Debes seleccionar al menos una característica',
  NOT_MUSCLE_GROUPS: 'Debes seleccionar al menos un grupo muscular',
  NOT_EXERCISE_TYPE: 'Debes seleccionar un tipo de ejercicio',
  NOT_EXERCISES: 'Debes introducir al menos un ejercicio',
  NOT_EXTERNAL_USER: 'Debes seleccionar un usuario',
  NOT_START_DATE: 'Debes seleccionar una fecha de inicio',
  NOT_END_DATE: 'Debes seleccionar una fecha de fin',
  NOT_USER: 'Debes seleccionar un usuario',
  NOT_BRANCH: 'Debes seleccionar una sucursal',
  NOT_NAME: 'Debes introducir un nombre',
  NOT_SURNAMES: 'Debes introducir apellidos',
  NOT_GENDER: 'Debes introducir un sexo',
  NOT_DESCRIPTION: 'Debes introducir una descripción',
  NOT_DURATION: 'Debes introducir una duración',
  NOT_URL: 'Debes introducir una URL',
  NOT_ALT: 'Debes introducir una descripción alternativa',
  NOT_TITLE: 'Debes introducir un título',
  NOT_CONTENT: 'Debes introducir contenido',
  NOT_PASSWORD: 'Debes introducir una contraseña',
  NOT_USERS: 'Debes seleccionar al menos un usuario',
  NOT_SUBJECT: 'Debes introducir un asunto',
  NOT_PROMOTIONS_EMAIL: 'Debes introducir un email',
  NOT_PROMOTIONS_EMAIL_PASSWORD: 'Debes introducir una contraseña',
} as const;

// eslint-disable-next-line no-use-before-define
type Validator<T> = (form: DeepPartial<T>) => string | FormValidators<any> | undefined;

export type FormValidators<T> = {
  [K in keyof T]?: T[K] extends (infer U)[] ? Validator<T> : T[K] extends object ? FormValidators<T[K]> : Validator<T>;
};

export type ValidationErrors<T> = {
  [K in keyof T]?: T[K] extends object ? ValidationErrors<T[K]> : string | ValidationErrors<any>[] | undefined;
};

export type FormFieldError = string | ValidationErrors<any>[] | undefined;

type UseFormReturn<T> = {
  form: T;
  setForm: Dispatch<SetStateAction<T>>;
  setFormFields: (updatedField: DeepPartial<T>) => void;
  isSaving: boolean;
  setIsSaving: Dispatch<SetStateAction<boolean>>;
  hasChanged: boolean;
  validationErrors: ValidationErrors<T> | undefined;
  validate: (newForm?: T) => ValidationErrors<T> | undefined;
  resetForm: () => void;
};

const getValidationErrors = <T,>(
  formToValidate: T,
  validatorsToCheck: FormValidators<T> | undefined,
): ValidationErrors<T> | undefined => {
  if (!validatorsToCheck) return;

  const errors = Object.keys(validatorsToCheck).reduce<ValidationErrors<T>>((errorObj, key) => {
    const keyToValidate = key as keyof T;
    const validator = validatorsToCheck[keyToValidate];

    if (typeof validator === 'function') {
      const result = validator(formToValidate);
      if (result) {
        if (Array.isArray(formToValidate[keyToValidate])) {
          const array = formToValidate[keyToValidate] as any[];
          if (array.length === 0) {
            // Si el array está vacío, usamos el mensaje de error directamente
            errorObj[keyToValidate] = result as any;
          } else if (typeof result === 'string') {
            // Si es un mensaje de error, lo aplicamos a todos los elementos
            const arrayErrors = array.map(() => result);
            errorObj[keyToValidate] = arrayErrors as any;
          } else {
            // Si es un validador, lo aplicamos a cada elemento
            const arrayValidators = result as FormValidators<T[keyof T]>;
            const arrayErrors = array.map((item) => {
              return getValidationErrors(item, arrayValidators);
            });
            if (arrayErrors.some(Boolean)) {
              errorObj[keyToValidate] = arrayErrors as any;
            }
          }
        } else {
          errorObj[keyToValidate] = result as any;
        }
      }
    } else if (typeof validator === 'object') {
      const nestedField = formToValidate[keyToValidate];
      if (nestedField) {
        const nestedErrors = getValidationErrors(nestedField, validator as FormValidators<T[keyof T]>);
        if (nestedErrors) {
          errorObj[keyToValidate] = nestedErrors as any;
        }
      }
    }

    return errorObj;
  }, {});

  if (!Object.keys(errors).length) return;

  return errors;
};

const focusFirstError = (formRef?: React.MutableRefObject<HTMLFormElement | null>) => {
  const queryTarget = formRef?.current ? formRef.current : document;
  const firstErrorElement = queryTarget.querySelector('.p-invalid') as HTMLElement | null;

  const isInputWrapper = firstErrorElement?.classList.contains('p-inputwrapper');
  if (isInputWrapper) {
    const input = firstErrorElement?.querySelector('input');
    input?.focus();
  } else {
    firstErrorElement?.focus();
    firstErrorElement?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
};

export const useForm = <T,>(
  initialValue: T,
  noExitPrompt = false,
  validators?: FormValidators<T>,
  formRef?: React.MutableRefObject<HTMLFormElement | null>,
): UseFormReturn<T> => {
  const [form, setForm] = useState<T>(initialValue);
  const [isSaving, setIsSaving] = useState(false);
  const [validating, setValidating] = useState(false);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors<T>>();
  const [shouldFocusErrors, setShouldFocusErrors] = useState(false);

  useLayoutEffect(() => {
    if (shouldFocusErrors) {
      focusFirstError(formRef);
      setShouldFocusErrors(false);
    }
  }, [shouldFocusErrors, setShouldFocusErrors]);

  const hasChanged = useMemo(
    () => !!form && !!initialValue && areFormObjectsDifferent(form, initialValue, true),
    [form, initialValue],
  );

  useConfirmBeforeExit(hasChanged && !noExitPrompt);

  useEffect(() => {
    if (initialValue) {
      setForm(initialValue);
    }
  }, [initialValue]);

  const validate = (newForm?: T) => {
    if (!newForm) {
      setShouldFocusErrors(true);
    }
    const newErrors = getValidationErrors(newForm ?? form, validators);
    setValidationErrors(newErrors);

    if (newErrors) {
      setValidating(true);
    } else {
      setValidating(false);
    }

    return newErrors;
  };

  const setFormFields = (updatedField: DeepPartial<T>) => {
    if (!form || !updatedField) return;
    const newForm = { ...form, ...updatedField };
    if (validating) {
      validate(newForm);
    }
    setForm(newForm);
  };

  const resetForm = () => {
    setForm(initialValue);
    setValidationErrors(undefined);
    setValidating(false);
  };

  return {
    form,
    setForm,
    setFormFields,
    isSaving,
    setIsSaving,
    hasChanged,
    validationErrors,
    validate,
    resetForm,
  };
};
