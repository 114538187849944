import { PrimeIcons } from 'primereact/api';
import React from 'react';

interface Props {
  message: string;
  icon?: string;
}

export const FormFieldMessage = ({ message, icon = PrimeIcons.INFO_CIRCLE }: Props) => {
  return (
    <div className='form-field-row'>
      <i className={`pi ${icon} primary-icon`} />
      <small>{message}</small>
    </div>
  );
};
