const css = `.preview-container {
  margin: 16px 0;
  width: 100%;
  height: 400px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--surface-ground);
}
.preview-container.placeholder {
  border: 2px dashed var(--surface-border);
}
.preview-container.placeholder .placeholder-text {
  color: var(--text-color-secondary);
  font-style: italic;
}
.preview-container .preview-video {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: black;
}
.preview-container .preview-video:not(video) {
  border: none;
  background-color: transparent;
}
.preview-container .preview-image {
  max-width: 100%;
  max-height: 100%;
}
.preview-container .preview-image img {
  max-width: 100%;
  object-fit: contain;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9yZW5kZXIvcHJvamVjdC9zcmMvc3JjL2NvbXBvbmVudHMvRXhlcmNpc2VQYW5lbCIsInNvdXJjZXMiOlsiRXhlcmNpc2VQYW5lbC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUVBO0VBQ0U7O0FBRUE7RUFDRTtFQUNBOztBQUlKO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7O0FBRUE7RUFDRTtFQUNBOztBQUlKO0VBQ0U7RUFDQTs7QUFDQTtFQUNFO0VBQ0EiLCJzb3VyY2VzQ29udGVudCI6WyIucHJldmlldy1jb250YWluZXIge1xuICBtYXJnaW46IDE2cHggMDtcbiAgd2lkdGg6IDEwMCU7XG4gIGhlaWdodDogNDAwcHg7XG4gIGJvcmRlci1yYWRpdXM6IDhweDtcbiAgb3ZlcmZsb3c6IGhpZGRlbjtcbiAgZGlzcGxheTogZmxleDtcbiAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7XG4gIGJhY2tncm91bmQtY29sb3I6IHZhcigtLXN1cmZhY2UtZ3JvdW5kKTtcblxuICAmLnBsYWNlaG9sZGVyIHtcbiAgICBib3JkZXI6IDJweCBkYXNoZWQgdmFyKC0tc3VyZmFjZS1ib3JkZXIpO1xuXG4gICAgLnBsYWNlaG9sZGVyLXRleHQge1xuICAgICAgY29sb3I6IHZhcigtLXRleHQtY29sb3Itc2Vjb25kYXJ5KTtcbiAgICAgIGZvbnQtc3R5bGU6IGl0YWxpYztcbiAgICB9XG4gIH1cblxuICAucHJldmlldy12aWRlbyB7XG4gICAgd2lkdGg6IDEwMCU7XG4gICAgaGVpZ2h0OiAxMDAlO1xuICAgIG9iamVjdC1maXQ6IGNvbnRhaW47XG4gICAgYmFja2dyb3VuZC1jb2xvcjogYmxhY2s7XG5cbiAgICAmOm5vdCh2aWRlbykge1xuICAgICAgYm9yZGVyOiBub25lO1xuICAgICAgYmFja2dyb3VuZC1jb2xvcjogdHJhbnNwYXJlbnQ7XG4gICAgfVxuICB9XG5cbiAgLnByZXZpZXctaW1hZ2Uge1xuICAgIG1heC13aWR0aDogMTAwJTtcbiAgICBtYXgtaGVpZ2h0OiAxMDAlO1xuICAgIGltZyB7XG4gICAgICBtYXgtd2lkdGg6IDEwMCU7XG4gICAgICBvYmplY3QtZml0OiBjb250YWluO1xuICAgIH1cbiAgfVxufVxuIl19 */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
