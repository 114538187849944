import React from 'react';
import { classNames } from 'primereact/utils';
import { Dropdown, DropdownProps } from 'primereact/dropdown';
import { Button, ButtonProps } from 'primereact/button';

import './DropdownButton.scss';

interface Props {
  isLoading?: boolean;
  buttonProps?: ButtonProps;
  className?: string;
  dropdownProps?: DropdownProps;
  small?: boolean;
}

export const DropdownButton = ({ isLoading, buttonProps, className, dropdownProps, small }: Props) => {
  const dropdownRef = React.useRef<Dropdown>(null);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dropdownRef.current?.show();
  };

  return (
    <div className={classNames('dropdown-button-container', className, { small })}>
      <Dropdown className='dropdown-button-dropdown' {...dropdownProps} ref={dropdownRef} />
      <Button loading={isLoading} onClick={handleClick} {...buttonProps} />
    </div>
  );
};
