import React, { useCallback, useMemo, useState } from 'react';
import useSWR from 'swr';
import { ClientRequest, useClient } from '../../hooks/useClient';
import { Routine } from '../../types/Fisiofit/routine';
import { useOrganizationContext } from '../../context/OrganizationContext';
import { PrimeIcons } from 'primereact/api';
import { ItemDisplay } from '../../types/responses';
import { useNotifications } from '../../context/NotificationContext';
import { ToastMessage } from 'primereact/toast';
import { confirmDialog } from 'primereact/confirmdialog';
import { DropdownButton } from '../DropdownButton/DropdownButton';
import { DropdownChangeEvent } from 'primereact/dropdown';

const fetchRoutine = async (
  routineDisplay: ItemDisplay,
  get: ClientRequest,
  showToast: ((message: ToastMessage | ToastMessage[]) => void) | undefined,
) => {
  const routine = await get<Routine>(`/routines/${routineDisplay.id}`);

  if (!routine) {
    showToast?.({
      severity: 'error',
      summary: 'Error copiando ejercicios',
      detail: `No se pudieron copiar los ejercicios de la rutina "${routineDisplay.name}"`,
    });
  }

  return routine;
};

const getMenuOptions = (routines: ItemDisplay[] | undefined) => {
  if (!routines) return [];
  return routines.map((routine) => ({
    value: routine.id,
    label: routine.name,
  }));
};

interface Props {
  onSelectRoutine: (routine: Routine) => void;
  showConfirmation?: boolean;
}

export const CopyRoutineButton = ({ onSelectRoutine, showConfirmation = false }: Props) => {
  const { showToast } = useNotifications() ?? {};
  const { organization } = useOrganizationContext() ?? {};
  const { get } = useClient();
  const [isCopying, setIsCopying] = useState(false);

  const { data: routines, isLoading } = useSWR(`/organizations/${organization?.id}/routines?short=true`, get<ItemDisplay[]>);

  const selectRoutine = useCallback(
    async (routine: ItemDisplay) => {
      setIsCopying(true);
      const fullRoutine = await fetchRoutine(routine, get, showToast);
      if (fullRoutine) {
        onSelectRoutine(fullRoutine);
        if (routine) {
          showToast?.({
            severity: 'info',
            summary: 'Ejercicios copiados',
            detail: `Se han copiado los ejercicios de la rutina "${routine.name}"`,
          });
        }
      }
      setIsCopying(false);
    },
    [get, onSelectRoutine, showToast],
  );

  const routineOptions = useMemo(() => getMenuOptions(routines), [routines]);

  const handleChange = (e: DropdownChangeEvent) => {
    const routine = routines?.find((r) => r.id === e.value);
    if (!routine) return;
    if (showConfirmation) {
      confirmDialog({
        message: '¿Deseas copiar los ejercicios de otra rutina? Esto sobreescribirá los ejercicios actuales.',
        header: 'Confirmar copia de ejercicios',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Sí, copiar',
        rejectLabel: 'No, cancelar',
        accept: () => {
          selectRoutine(routine);
        },
        acceptClassName: 'p-button p-button-text',
        rejectClassName: 'p-button-secondary p-button-text',
      });
    } else {
      selectRoutine(routine);
    }
  };

  return (
    <DropdownButton
      isLoading={isCopying}
      dropdownProps={{
        options: routineOptions,
        filter: true,
        emptyMessage: 'No hay rutinas disponibles',
        emptyFilterMessage: 'No se han encontrado rutinas',
        filterPlaceholder: 'Buscar rutina...',
        onChange: handleChange,
      }}
      buttonProps={{ label: 'Copiar ejercicios', icon: PrimeIcons.COPY, text: true, disabled: isLoading || isCopying }}
    />
  );
};
