import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import { Button } from 'primereact/button';
import BusinessHoursSection from '../../components/BusinessHoursSection/BusinessHoursSection';
import { PageTemplate } from '../../layout/PageTemplate/PageTemplate';
import { Card } from 'primereact/card';
import './Settings.scss';
import { FormField } from '../../components/FormField/FormField';
import { InputText } from 'primereact/inputtext';
import { PrimeIcons } from 'primereact/api';
import { useClient } from '../../hooks/useClient';
import { useOrganizationContext } from '../../context/OrganizationContext';
import { Branch } from '../../types/branch';
import { useForm } from '../../hooks/useForm';
import { classNames } from 'primereact/utils';
import { Organization } from '../../types/organization';
import { ReservationTypeWithColor, useReservationTypes } from '../../hooks/AppData/useReservationTypes';
import { Password } from 'primereact/password';

import {
  PROMOTION_SETTINGS_FORM_VALIDATORS,
  PromotionSettingsForm,
  RESERVATION_HOURS_FORM_VALIDATORS,
  ReservationHoursForm,
} from './validators';
import { BranchesSelect } from '../../components/BranchesSelect/BranchesSelect';
import { HolidaysDialog } from '../../components/HolidaysDialog/HolidaysDialog';
import { HolidaysList } from '../../components/HolidaysList/HolidaysList';

const getInitialReservationHoursForm = (
  branchReservationTypes: ReservationTypeWithColor[] | undefined,
): ReservationHoursForm | undefined => {
  return branchReservationTypes?.reduce<ReservationHoursForm>((acc, reservationType) => {
    acc[reservationType.id] = {
      hours: reservationType.hours,
      closedDays: reservationType.closedDays.map((date) => new Date(date)) || [],
    };
    return acc;
  }, {});
};

const getInitialPromotionSettingsForm = (org?: Organization): PromotionSettingsForm => {
  const { promotionsEmail, promotionsEmailPassword } = org ?? {};
  return {
    promotionsEmail,
    promotionsEmailPassword,
  };
};

const getBranchFromId = (id: string | undefined, branches: Branch[] | undefined) => {
  if (!id || !branches) return;
  return branches.find((_) => _.id === id);
};

const getBranchReservationTypes = (branchId: string | undefined, reservationTypes: ReservationTypeWithColor[] | undefined) => {
  if (!branchId) return;
  return reservationTypes?.filter((_) => _.branchId === branchId);
};

export const Settings = () => {
  const { patch } = useClient();
  const { organization, organizationLoading, orgBranches, setOrganization } = useOrganizationContext() ?? {};
  const [selectedBranchId, setSelectedBranchId] = useState<string>();
  const [showHolidaysDialog, setShowHolidaysDialog] = useState(false);
  const [selectedReservationType, setSelectedReservationType] = useState<ReservationTypeWithColor>();

  const { reservationTypes, mutateReservationTypes } = useReservationTypes();
  const branchReservationTypes = useMemo(() => {
    return getBranchReservationTypes(selectedBranchId, reservationTypes);
  }, [selectedBranchId, reservationTypes]);
  const reservationTypesWithRestrictedDates = useMemo(
    () => branchReservationTypes?.filter((_) => !_.allowAnyDate),
    [branchReservationTypes],
  );

  useEffect(() => {
    if (!selectedBranchId && reservationTypes && orgBranches?.length) {
      const firstBranchWithReservationTypes = orgBranches.find((_) => getBranchReservationTypes(_.id, reservationTypes)?.length);
      setSelectedBranchId(firstBranchWithReservationTypes?.id);
    }
  }, [orgBranches, selectedBranchId, reservationTypes]);

  const initialReservationHoursForm = useMemo(
    () => getInitialReservationHoursForm(branchReservationTypes),
    [branchReservationTypes],
  );
  const {
    form: reservationHours,
    setFormFields: setReservationHoursField,
    isSaving: reservationHoursSaving,
    setIsSaving: setReservationHoursSaving,
    hasChanged: reservationHoursChanged,
    validationErrors: reservationHoursErrors,
    validate: reservationHoursValidate,
  } = useForm<ReservationHoursForm | undefined>(initialReservationHoursForm, false, RESERVATION_HOURS_FORM_VALIDATORS);

  const handleSaveReservationHours = async (e: FormEvent) => {
    e.preventDefault();
    const errors = reservationHoursValidate(reservationHours);
    if (errors) return;
    const selectedBranch = getBranchFromId(selectedBranchId, orgBranches);
    if (!reservationHours || !selectedBranchId || !selectedBranch || !branchReservationTypes) return;
    setReservationHoursSaving(true);

    const updatedReservationTypes = branchReservationTypes.map((reservationType) => {
      const { color, branchId, tagColor, ...rest } = reservationType;
      return {
        ...rest,
        hours: reservationHours[reservationType.id].hours,
        closedDays: reservationHours[reservationType.id].closedDays,
      };
    });

    await patch(`/branches/${selectedBranchId}/reservation-types`, {
      body: { updatedReservationTypes },
      errorMessages: {
        summary: 'Error al cambiar horario',
        defaultDetail: 'No se ha podido modificar el horario de apertura',
      },
      successMessage: {
        summary: 'Horario editado',
        detail: 'El horario de apertura se ha modificado correctamente',
      },
      handlers: {
        defaultSuccess: () => mutateReservationTypes(),
      },
    });

    setReservationHoursSaving(false);
  };

  const initialPromotionSettingsForm = useMemo(() => getInitialPromotionSettingsForm(organization), [organization]);
  const {
    form: promotionSettings,
    setFormFields: setPromotionSettingsField,
    isSaving: promotionSettingsSaving,
    setIsSaving: setPromotionSettingsSaving,
    hasChanged: promotionSettingsChanged,
    validationErrors: promotionSettingsErrors,
    validate: promotionValidate,
  } = useForm<PromotionSettingsForm | undefined>(initialPromotionSettingsForm, false, PROMOTION_SETTINGS_FORM_VALIDATORS);

  const handlePromotionSettingsSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const errors = promotionValidate(promotionSettings);
    if (errors) return;
    if (!promotionSettings || !organization) return;
    setPromotionSettingsSaving(true);

    const updatedOrg = await patch<Organization>(`/organizations/${organization.id}`, {
      body: { ...promotionSettings },
      errorMessages: {
        summary: 'Error al configurar promociones',
        defaultDetail: 'No se ha podido modificar la configuración de promociones',
      },
      successMessage: {
        summary: 'Configuración de promociones modificada',
        detail: 'La configuración de promociones se ha modificado correctamente',
      },
    });

    if (updatedOrg) {
      setOrganization?.(updatedOrg);
    }
    setPromotionSettingsSaving(false);
  };

  const handleOpenHolidaysDialog = (reservationType: ReservationTypeWithColor) => {
    setSelectedReservationType(reservationType);
    setShowHolidaysDialog(true);
  };

  const handleHolidaysChange = (dates: Date[]) => {
    if (!selectedReservationType) return;

    const currentHours = reservationHours?.[selectedReservationType.id]?.hours;
    if (!currentHours) return;

    setReservationHoursField({
      [selectedReservationType.id]: {
        hours: currentHours,
        closedDays: dates,
      },
    });
  };

  const handleRemoveHoliday = (reservationType: ReservationTypeWithColor, dateToRemove: Date) => {
    const currentHours = reservationHours?.[reservationType.id]?.hours;
    const currentHolidays = reservationHours?.[reservationType.id]?.closedDays ?? [];
    if (!currentHours) return;

    setReservationHoursField({
      [reservationType.id]: {
        hours: currentHours,
        closedDays: currentHolidays.filter((date) => date.toISOString() !== dateToRemove.toISOString()),
      },
    });
  };

  return (
    <PageTemplate className='settings-page' title='Configuración'>
      <Card>
        <form
          className={classNames({
            'form-loading': organizationLoading || reservationHoursSaving,
          })}
          onSubmit={handleSaveReservationHours}
        >
          <FormField
            elementId='config-branch'
            labelTitle='Sucursal'
            error={reservationHoursErrors?.branchId as unknown as string}
          >
            <BranchesSelect
              id='config-branch'
              isSingle
              value={selectedBranchId}
              onChange={(branch) => branch && setSelectedBranchId(branch.id)}
              selectAllOption
            />
          </FormField>
          <FormField fullWidth>
            <h4 className='reservation-hours-title'>Horarios de reservas</h4>
          </FormField>
          {reservationTypesWithRestrictedDates?.map((reservationType) => (
            <FormField
              className={classNames({ 'full-width': reservationTypesWithRestrictedDates.length === 1 })}
              key={reservationType.id}
            >
              <BusinessHoursSection
                onChange={(value) =>
                  setReservationHoursField({
                    [reservationType.id]: {
                      ...reservationHours?.[reservationType.id],
                      hours: value,
                    },
                  })
                }
                value={reservationHours?.[reservationType.id]?.hours}
                title={reservationType.name ?? 'individuales'}
              />
              <FormField fullWidth>
                <h4 className='closed-days-title'>Días de cierre</h4>
              </FormField>
              <FormField fullWidth>
                <Button
                  type='button'
                  className='holidays-selector-button'
                  onClick={() => handleOpenHolidaysDialog(reservationType)}
                  icon='pi pi-calendar'
                  label='Seleccionar días de cierre'
                />
                <HolidaysList
                  holidays={reservationHours?.[reservationType.id]?.closedDays ?? []}
                  onRemove={(date) => handleRemoveHoliday(reservationType, date)}
                />
              </FormField>
            </FormField>
          ))}
          <Button
            type='submit'
            disabled={!reservationHoursChanged}
            className='pull-right pull-down'
            label='Guardar'
            icon={PrimeIcons.SAVE}
          />
        </form>
      </Card>
      <Card>
        <form
          className={classNames({
            'form-loading': organizationLoading || promotionSettingsSaving,
          })}
          onSubmit={handlePromotionSettingsSubmit}
        >
          <FormField fullWidth>
            <h4>Configuración de promociones</h4>
          </FormField>
          <FormField
            elementId='email'
            labelTitle='Correo electrónico para promociones'
            error={promotionSettingsErrors?.promotionsEmail}
            required
          >
            <InputText
              placeholder='Correo electrónico para promociones'
              id='email'
              className={classNames({ 'p-invalid': promotionSettingsErrors?.promotionsEmail })}
              value={promotionSettings?.promotionsEmail ?? ''}
              autoComplete='email'
              onChange={(e) => setPromotionSettingsField({ promotionsEmail: e.target.value })}
            />
          </FormField>
          <FormField
            elementId='email-server-password'
            labelTitle='Contraseña del servidor de correo'
            infoTooltip='TODO'
            error={promotionSettingsErrors?.promotionsEmailPassword}
          >
            <Password
              id='email-server-password'
              toggleMask
              feedback={false}
              placeholder='Contraseña del servidor de correo'
              value={promotionSettings?.promotionsEmailPassword ?? ''}
              className={classNames({ 'p-invalid': promotionSettingsErrors?.promotionsEmailPassword })}
              onChange={(e) =>
                setPromotionSettingsField({
                  promotionsEmailPassword: e.target.value,
                })
              }
            />
          </FormField>
          <Button
            type='submit'
            disabled={!promotionSettingsChanged}
            className='pull-right pull-down'
            label='Guardar'
            icon={PrimeIcons.SAVE}
          />
        </form>
      </Card>
      <HolidaysDialog
        visible={showHolidaysDialog}
        onHide={() => setShowHolidaysDialog(false)}
        value={selectedReservationType ? (reservationHours?.[selectedReservationType.id]?.closedDays ?? []) : []}
        onChange={handleHolidaysChange}
        title={`Días de cierre - ${selectedReservationType?.name ?? ''}`}
        currentReservationType={selectedReservationType}
        otherReservationTypes={branchReservationTypes?.filter((rt) => rt.id !== selectedReservationType?.id)}
        reservationHours={reservationHours}
      />
    </PageTemplate>
  );
};
