import React, { useState, useMemo, useEffect } from 'react';
import './UsersList.scss';

import { useNavigate, useLocation } from 'react-router-dom';
import { ResponsiveTable } from '../../../components/ResponsiveTable/ResponsiveTable';
import { TableColumn, TableAction, TagColor } from '../../../types/responsiveTable';
import { EXTERNAL_USER_STATUSES, ExternalUserTableEntry, USER_CURRENT_ROUTINE_STATUS } from '../../../types/externalUser';
import useSWR from 'swr';
import { useOrganizationContext } from '../../../context/OrganizationContext';
import { useClient } from '../../../hooks/useClient';
import { Button } from 'primereact/button';
import { PrimeIcons } from 'primereact/api';
import { ExternalUserPanel } from '../../../components/ExternalUserPanel/ExternalUserPanel';
import { useResourcePanel } from '../../../hooks/useResourcePanel';
import { Organization } from '../../../types/organization';
import { ALL_FEATURES, Feature } from '../../../config/features';
import { useAuthContext } from '../../../context/AuthContext';
import { BranchesSelect } from '../../../components/BranchesSelect/BranchesSelect';

const ROUTINE_STATUS_LABELS: Record<USER_CURRENT_ROUTINE_STATUS, string> = {
  [USER_CURRENT_ROUTINE_STATUS.NOT_STARTED]: 'No iniciada',
  [USER_CURRENT_ROUTINE_STATUS.IN_PROGRESS]: 'En curso',
  [USER_CURRENT_ROUTINE_STATUS.EXPIRED]: 'Caducada',
  [USER_CURRENT_ROUTINE_STATUS.NO_ROUTINE]: 'Sin rutina',
};

const ROUTINE_STATUS_COLORS: Record<USER_CURRENT_ROUTINE_STATUS, TagColor> = {
  [USER_CURRENT_ROUTINE_STATUS.NOT_STARTED]: 'contrast',
  [USER_CURRENT_ROUTINE_STATUS.IN_PROGRESS]: 'info',
  [USER_CURRENT_ROUTINE_STATUS.EXPIRED]: 'danger',
  [USER_CURRENT_ROUTINE_STATUS.NO_ROUTINE]: 'warning',
};

const getTableColumns = (organization: Organization | undefined, features: Feature[] | undefined) => {
  const tableColumns: TableColumn<ExternalUserTableEntry>[] = [
    {
      label: '',
      accessor: 'avatarUrl',
      type: 'avatar',
    },
    {
      label: 'Nombre',
      accessor: 'name',
      type: 'string',
      isTitle: true,
      valueFromCallBack: (name, row) => `${name} ${row.surnames}${row.isInvitation ? ' (pendiente)' : ''}`,
    },
    {
      label: 'Sucursal',
      accessor: 'branch',
      type: 'dropdown',
      valueFromCallBack: (branch) => branch?.name,
    },
    {
      label: 'Supervisor',
      accessor: 'supervisor',
      type: 'dropdown',
      valueFromCallBack: (supervisor) => supervisor?.name,
      hideColumn: !organization?.config?.hasSupervisor,
    },
    {
      label: 'Rutina',
      accessor: 'currentRoutine',
      type: 'tag',
      textKeyFromCallBack: (_, { currentRoutine }) => {
        if (!currentRoutine) return;
        return ROUTINE_STATUS_LABELS[currentRoutine?.status];
      },
      colorFromCallBack: (_, { currentRoutine }) => {
        if (!currentRoutine) return;
        return ROUTINE_STATUS_COLORS[currentRoutine?.status];
      },
      hideColumn: !organization?.features.includes(ALL_FEATURES.ROUTINES) || !features?.includes(ALL_FEATURES.ROUTINES),
    },
    {
      label: 'Última reserva',
      accessor: 'latestReservationDate',
      type: 'calendar',
    },
    {
      label: 'Estado',
      accessor: 'status',
      textKeyFromCallBack: (status: EXTERNAL_USER_STATUSES) => (status === EXTERNAL_USER_STATUSES.ACTIVE ? 'Activo' : 'Inactivo'),
      colorFromCallBack: (status: EXTERNAL_USER_STATUSES) => {
        return status === EXTERNAL_USER_STATUSES.ACTIVE ? 'success' : 'danger';
      },
      type: 'tag',
    },
  ];

  return tableColumns;
};

const UsersList = () => {
  const navigate = useNavigate();
  const { get } = useClient();
  const { organization, organizationLoading, orgBranches } = useOrganizationContext() ?? {};
  const { features, featuresLoading } = useAuthContext() ?? {};
  const [selectedBranch, setSelectedBranch] = useState<string>();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const { data, isLoading } = useSWR(
    organization
      ? `/organizations/${organization.id}/external-users${selectedBranch ? `?branches=${selectedBranch}` : ''}`
      : null,
    get<ExternalUserTableEntry[]>,
  );
  const { isEditing, setIsEditing, panelVisible, closePanel, openPanel, isDeleting } = useResourcePanel(data);

  const initialFormData = useMemo(() => {
    const name = searchParams.get('name');
    const surnames = searchParams.get('surnames');
    const phone = searchParams.get('phone');
    const branchId = searchParams.get('branchId');

    if (!name && !surnames && !phone) return undefined;

    return {
      name: name ?? undefined,
      surnames: surnames ?? undefined,
      phone: phone ?? undefined,
      branchId: branchId ?? undefined,
    };
  }, [searchParams]);

  useEffect(() => {
    if (initialFormData) {
      openPanel(false);
    }
  }, [initialFormData, openPanel]);

  const tableColumns = useMemo(() => {
    return getTableColumns(organization, features);
  }, [features, organization]);

  const options: TableAction[] = [
    {
      label: 'Ver perfil',
      onClick: (id) => {
        navigate(`profile/${id}`);
      },
    },
    {
      label: 'Ver rutina',
    },
    {
      label: 'Crear cita fisioterapia',
    },
    {
      label: 'Dar de baja',
    },
  ];

  const handleClose = () => {
    closePanel();
    if (initialFormData) {
      navigate('/users', { replace: true });
    }
  };

  return (
    <>
      <ResponsiveTable
        data={data || []}
        columns={tableColumns}
        options={options}
        loading={organizationLoading || isLoading || featuresLoading}
        headerOptions={
          <div className='table-header-options'>
            <Button label='Añadir usuario' outlined rounded icon={PrimeIcons.PLUS} onClick={() => openPanel(false)} />
            <BranchesSelect
              isSingle
              value={selectedBranch}
              onChange={(branch) => {
                setSelectedBranch(branch?.id);
              }}
              selectAllOption
            />
          </div>
        }
        onRowClick={(externalUser: ExternalUserTableEntry) => {
          navigate(`profile/${externalUser.id}`);
        }}
      />

      <ExternalUserPanel
        resource={undefined}
        selectedBranchId={selectedBranch}
        visible={panelVisible}
        onHide={handleClose}
        onIsEditingChange={setIsEditing}
        isEditing={isEditing}
        onDeleteResource={() => Promise.resolve(true)}
        isDeleting={isDeleting}
        initialFormData={initialFormData}
      />
    </>
  );
};

export default UsersList;
