import React from 'react';
import { EventProps } from 'react-big-calendar';
import { USER_ROUTINE_EVENTS } from '../../types/externalUser';
import { PrimeIcons } from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import './RoutineCalendarEvent.scss';

interface RoutineEvent {
  id: string;
  title: string;
  eventType: USER_ROUTINE_EVENTS;
  routineId: string;
  fieldsUpdated?: string[];
  start: Date;
}

const getRoutineEventIcon = (eventType: USER_ROUTINE_EVENTS) => {
  if (eventType === USER_ROUTINE_EVENTS.NEXT_ROUTINE) return PrimeIcons.CALENDAR_PLUS;
  if (eventType === USER_ROUTINE_EVENTS.CURRENT_ROUTINE) return PrimeIcons.CALENDAR;
  if (eventType === USER_ROUTINE_EVENTS.CURRENT_ROUTINE_NOT_STARTED) return PrimeIcons.CALENDAR_PLUS;
  if (eventType === USER_ROUTINE_EVENTS.PAST_ROUTINE) return PrimeIcons.CALENDAR_MINUS;
  if (eventType === USER_ROUTINE_EVENTS.UPDATE) return PrimeIcons.COG;
  return '';
};

const getEventTypeLabel = (eventType: USER_ROUTINE_EVENTS) => {
  switch (eventType) {
    case USER_ROUTINE_EVENTS.CURRENT_ROUTINE:
      return 'Rutina actual';
    case USER_ROUTINE_EVENTS.NEXT_ROUTINE:
      return 'Próxima rutina';
    case USER_ROUTINE_EVENTS.PAST_ROUTINE:
      return 'Rutina pasada';
    case USER_ROUTINE_EVENTS.CURRENT_ROUTINE_NOT_STARTED:
      return 'Nueva rutina';
    case USER_ROUTINE_EVENTS.UPDATE:
      return 'Actualización';
    default:
      return 'Rutina';
  }
};

export const RoutineCalendarEvent = ({ event }: EventProps<RoutineEvent>) => {
  const getEventColor = (eventType: USER_ROUTINE_EVENTS) => {
    switch (eventType) {
      case USER_ROUTINE_EVENTS.CURRENT_ROUTINE:
        return 'green';
      case USER_ROUTINE_EVENTS.NEXT_ROUTINE:
        return 'blue';
      case USER_ROUTINE_EVENTS.PAST_ROUTINE:
        return 'orange';
      case USER_ROUTINE_EVENTS.UPDATE:
        return 'yellow';
      default:
        return 'primary';
    }
  };

  const color = getEventColor(event.eventType);
  const tooltipId = `routine-${event.routineId}-${event.start.getTime()}`;

  const getUpdatesDescription = (fieldsUpdated?: string[]) => {
    if (!fieldsUpdated?.length) return '';
    const updates = [];
    if (fieldsUpdated.includes('exercises')) updates.push('ejercicios');
    if (fieldsUpdated.includes('duration')) updates.push('duración');
    return updates.length > 0 ? `Se actualizaron: ${updates.join(' y ')}` : '';
  };

  return (
    <>
      <div id={tooltipId} className='routine-calendar-event'>
        <div className='event-type' style={{ backgroundColor: `var(--${color}-500)` }}>
          <i className={getRoutineEventIcon(event.eventType)} />
        </div>
        <div className='event-content'>
          <span>{event.title}</span>
        </div>
      </div>
      <Tooltip target={`#${tooltipId}`} position='right' showDelay={50} hideDelay={0}>
        <div className='event-tooltip'>
          <strong>{getEventTypeLabel(event.eventType)}</strong>
          <div className='event-details'>
            <div>
              <i className='pi pi-calendar' />
              <span>{format(event.start, "EEEE, d 'de' MMMM", { locale: es })}</span>
            </div>
            {event.title && (
              <div>
                <i className='pi pi-info-circle' />
                <span>{event.title}</span>
              </div>
            )}
            {event.eventType === USER_ROUTINE_EVENTS.UPDATE && (
              <div>
                <i className='pi pi-cog' />
                <span>{getUpdatesDescription(event.fieldsUpdated)}</span>
              </div>
            )}
          </div>
        </div>
      </Tooltip>
    </>
  );
};
