import React, { ReactNode, useMemo, useRef, useState } from 'react';
import './ResponsiveTable.scss';
import { Button } from 'primereact/button';
import { PrimeIcons } from 'primereact/api';
import { Menu } from 'primereact/menu';
import { Paginator } from 'primereact/paginator';
import { Sidebar } from 'primereact/sidebar';
import { filterData, paginateData } from './tableUtils';
import { TableFilter } from '../TableFilter/TableFilter';
import { TableRows } from '../TableRows/TableRows';
import { TableColumn, TableAction } from '../../types/responsiveTable';
import { Card } from 'primereact/card';
import { Dropdown } from 'primereact/dropdown';

interface Props<T extends Record<string, any>> {
  columns: TableColumn<T>[];
  data: T[];
  options: TableAction[];
  loading?: boolean;
  headerOptions?: ReactNode;
  onRowClick?: (row: any) => void;
}

export const ResponsiveTable = <T extends Record<string, any>>({
  columns,
  data = [],
  options,
  loading,
  headerOptions,
  onRowClick,
}: Props<T>) => {
  const menu = useRef(null);
  const optionRowId = useRef<string>('');
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [showFilters, setShowFilters] = useState(false);
  const [showSliderFilters, setShowSliderFilters] = useState(false);
  const [filters, setFilters] = useState<Record<string, any>>({});
  const [sliderFilters, setSliderFilters] = useState<Record<string, any>>({});

  const hasAppliedFilters = useMemo(
    () => Object.keys(filters).length > 0 || Object.keys(sliderFilters).length > 0,
    [filters, sliderFilters],
  );

  const visibleColumns = useMemo(() => {
    return columns.filter((column) => !column.hideColumn);
  }, [columns]);

  const onPageChange = (event: any) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const items = [
    {
      items: options.map((opt) => {
        return {
          label: opt.label,
          command: () => opt.onClick && opt.onClick(optionRowId.current),
        };
      }),
    },
  ];

  const onOptionsClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
    optionRowId.current = id;
    if (menu.current) {
      (menu.current as any).toggle(event);
    }
  };

  const handleFilterChange = (key: string, values: any[], isSliderFilters = false) => {
    if (isSliderFilters) {
      setSliderFilters((prev) => ({
        ...prev,
        [key]: values,
      }));
    } else {
      setFilters((prev) => ({
        ...prev,
        [key]: values,
      }));
    }
  };

  const filteredData = filterData(data, visibleColumns, filters);
  const paginatedData = paginateData(filteredData, first, rows);

  const onHide = () => {
    setShowSliderFilters(false);
  };

  const applySliderFilters = () => {
    setFilters((prev) => ({
      ...prev,
      ...sliderFilters,
    }));
    onHide();
  };
  const clearFilters = () => {
    setFilters({});
    setSliderFilters({});
    onHide();
  };

  return (
    <Card
      className='responsive-table'
      header={
        <>
          <div className='header-left'>{headerOptions}</div>
          <div className='header-right'>
            {hasAppliedFilters && (
              <Button
                label='Limpiar filtros'
                text
                icon={PrimeIcons.FILTER_SLASH}
                iconPos='right'
                onClick={clearFilters}
                className='clear-filters clear-filters-table'
                severity='secondary'
              />
            )}
            <Button
              label={(!showFilters ? 'Mostrar' : 'Esconder') + ' filtros'}
              text
              icon={PrimeIcons.FILTER}
              iconPos='right'
              onClick={() => setShowFilters(!showFilters)}
              className='show-filters-button'
            />
          </div>
        </>
      }
    >
      <div style={{ position: 'relative' }}>
        {!loading && (
          <>
            <Button
              icon={PrimeIcons.FILTER}
              rounded
              className='floating-button p-button-secondary'
              onClick={() => setShowSliderFilters(true)}
              aria-label='Filter'
            />
          </>
        )}
        <div style={{ overflowY: 'auto' }}>
          <table>
            <thead>
              <tr>
                <TableFilter
                  columns={visibleColumns}
                  filters={filters}
                  data={data}
                  onFilterChange={handleFilterChange}
                  loading={loading}
                  showFilters={showFilters}
                ></TableFilter>
                {!loading && <th className='option'></th>}
              </tr>
            </thead>
            <tbody>
              <TableRows
                data={paginatedData}
                columns={visibleColumns}
                onOptionsClick={onOptionsClick}
                loading={loading}
                onRowClick={onRowClick}
              ></TableRows>
              <Menu model={items} ref={menu} popup />
            </tbody>
          </table>
        </div>
        <Paginator
          first={first}
          rows={rows}
          totalRecords={(filteredData || []).length}
          onPageChange={onPageChange}
          template={{
            layout:
              'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink JumpToPageInput RowsPerPageDropdown',
            PageLinks: (options) => {
              if (
                (options.view.startPage === options.page && options.view.startPage !== 0) ||
                (options.view.endPage === options.page && options.page + 1 !== options.totalPages)
              ) {
                return <></>;
              }
              return (
                <button type='button' className={options.className} onClick={options.onClick}>
                  {options.page + 1}
                </button>
              );
            },
            CurrentPageReport: (options) => {
              return (
                <small className='current-page-report'>
                  Página {options.currentPage} de {options.totalPages}
                  <br />
                  Filas {options.first} - {options.last} de {options.totalRecords}
                </small>
              );
            },
            JumpToPageInput: () => {
              return <div className='paginator-responsive-separator'></div>;
            },
            RowsPerPageDropdown: (options) => {
              const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 25, value: 25 },
                { label: 50, value: 50 },
              ];

              return (
                <div className='rows-per-page-dropdown'>
                  <small>Filas por página</small>
                  <Dropdown
                    panelClassName='paginator-page-size-menu'
                    value={options.value}
                    options={dropdownOptions}
                    onChange={options.onChange}
                  />
                </div>
              );
            },
          }}
        />
        {!loading && (
          <Sidebar className='filters-sidebar' position='right' visible={showSliderFilters} onHide={onHide}>
            <Button
              label='Limpiar filtros'
              text
              icon={PrimeIcons.FILTER_SLASH}
              iconPos='right'
              onClick={clearFilters}
              className='clear-filters'
            />
            <div className='filters-sidebar-container'>
              <TableFilter
                columns={visibleColumns}
                filters={sliderFilters}
                data={data}
                onFilterChange={handleFilterChange}
                isSlider={true}
                showFilters={true}
              ></TableFilter>
            </div>
            <Button label='Aplicar filtros' onClick={applySliderFilters} className='apply-filters' />
          </Sidebar>
        )}
      </div>
    </Card>
  );
};
