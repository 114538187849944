import React, { FormEvent, useMemo, useState } from 'react';
import './ExercisePanel.scss';

import { useOrganizationContext } from '../../context/OrganizationContext';
import { useForm } from '../../hooks/useForm';
import { FormField } from '../FormField/FormField';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Image } from 'primereact/image';
import { NotificationMessage, RequestErrorMessages, useClient } from '../../hooks/useClient';
import { Exercise, EXERCISE_TYPE } from '../../types/Fisiofit/exercise';
import { InputTextarea } from 'primereact/inputtextarea';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { resourceMutator } from '../../utils/resourceMutation';
import { mutate } from 'swr';
import { MUSCLE_GROUPS } from '../../types/Fisiofit/fisiofitConst';
import { Panel, ResourcePanelImplProps } from '../Panel/Panel';
import { EXERCISE_FORM_VALIDATORS, ExerciseForm } from './validators';
import { EncodedImage } from '../../types/filedata';
import { FileUpload } from '../FileUpload/FileUpload';
import { capitalize } from '../../utils/textUtils';

const getToastMessages = (
  isEditing: boolean,
): {
  successMessage: NotificationMessage;
  errorMessages: RequestErrorMessages;
} => {
  return {
    successMessage: {
      summary: isEditing ? 'Ejercicio modificado' : 'Ejercicio creado',
      detail: isEditing ? 'El ejercicio se ha modificado correctamente' : 'Se ha creado el nuevo ejercicio',
    },
    errorMessages: {
      summary: isEditing ? 'Error modificando el ejercicio' : 'Error al crear el ejercicio',
      defaultDetail: isEditing ? 'No se ha podido modificar el ejercicio' : 'No se ha podido crear el ejercicio',
    },
  };
};

const EXERCISE_TYPE_OPTIONS = [
  { label: 'Repeticiones', value: EXERCISE_TYPE.REPS },
  { label: 'Tiempo', value: EXERCISE_TYPE.TIME },
];

const MUSCLE_GROUP_OPTIONS = Object.values(MUSCLE_GROUPS).map((value) => ({
  label: capitalize(value),
  value: value,
}));

const getInitialExerciseForm = (exercise?: Exercise): ExerciseForm => {
  const { muscleGroups, name, description, videoUrl, imageUrl, exerciseType } = exercise ?? {};
  return {
    name,
    muscleGroups: new Set(muscleGroups),
    description,
    video: videoUrl,
    image: imageUrl,
    exerciseType: exerciseType ?? EXERCISE_TYPE.REPS,
  };
};

export const ExercisePanel = ({
  resource,
  visible,
  onHide,
  onIsEditingChange,
  onDeleteResource,
  isEditing = false,
  isDeleting = false,
}: ResourcePanelImplProps<Exercise>) => {
  const { organization } = useOrganizationContext() ?? {};
  const { post, patch } = useClient();
  const [imageFile, setImageFile] = useState<File>();

  const isCreating = !resource;

  const initialForm = useMemo(() => getInitialExerciseForm(resource), [resource]);
  const { form, setFormFields, isSaving, setIsSaving, hasChanged, validationErrors, resetForm, validate } = useForm<ExerciseForm>(
    initialForm,
    !visible,
    EXERCISE_FORM_VALIDATORS,
  );

  const handleImageUpload = (encodedImage: EncodedImage, file: File) => {
    setImageFile(file);
    setFormFields({
      image: encodedImage,
    });
  };

  const handleSave = async (e?: FormEvent): Promise<boolean> => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    const errors = validate();
    const { name, description, muscleGroups, image: _, video, exerciseType } = form ?? {};
    if (!name || !exerciseType || !organization || errors) return false;
    setIsSaving(true);

    const formData = new FormData();
    const exerciseData = {
      name,
      description,
      muscleGroups: Array.from(muscleGroups ?? []),
      videoUrl: video,
      exerciseType,
      ...(isCreating && { organizationId: organization.id }),
    };

    formData.append('data', JSON.stringify(exerciseData));
    if (imageFile) {
      formData.append('image', imageFile);
    }

    const { successMessage, errorMessages } = getToastMessages(isEditing);
    const request = {
      body: formData,
      successMessage,
      errorMessages,
    };

    const response =
      isEditing && resource
        ? await patch<Exercise>(`/exercises/${resource.id}`, request)
        : await post<Exercise>(`/exercises`, request);

    if (response) {
      mutate(`/organizations/${organization.id}/exercises`, resourceMutator<Exercise>(response), false);
    }

    setIsSaving(false);
    return !!response;
  };

  const getYoutubeEmbedUrl = (url: string) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? `https://www.youtube.com/embed/${match[2]}` : url;
  };

  return (
    <Panel
      panelType='resourcePanel'
      resourceName='ejercicio'
      visible={visible}
      onHide={onHide}
      onDelete={() => onDeleteResource(resource?.id)}
      isDeleting={isDeleting}
      onSave={handleSave}
      onIsEditingChange={onIsEditingChange}
      hasChanged={hasChanged}
      isSaving={isSaving}
      isEditing={isEditing}
      isCreating={isCreating}
      resetForm={resetForm}
    >
      <form
        className={classNames({
          'form-loading': isSaving,
        })}
      >
        <FormField
          labelTitle='Nombre'
          elementId='exercise-name'
          fullWidth
          error={validationErrors?.name}
          required={isCreating || isEditing}
        >
          {isEditing || isCreating ? (
            <InputText
              id='exercise-name'
              className={classNames({
                'p-invalid': validationErrors?.name,
              })}
              placeholder={`Escribe el nombre del ejercicio`}
              value={form?.name ?? ''}
              onChange={(e: any) =>
                setFormFields({
                  name: e.target.value,
                })
              }
            />
          ) : (
            <p>{form?.name}</p>
          )}
        </FormField>

        <FormField
          labelTitle='Tipo de ejercicio'
          elementId='exercise-type'
          fullWidth
          error={validationErrors?.exerciseType}
          required={isCreating || isEditing}
        >
          {isEditing || isCreating ? (
            <Dropdown
              id='exercise-type'
              className={classNames({
                'p-invalid': validationErrors?.exerciseType,
              })}
              value={form?.exerciseType}
              options={EXERCISE_TYPE_OPTIONS}
              onChange={(e) =>
                setFormFields({
                  exerciseType: e.value,
                })
              }
              placeholder='Selecciona el tipo de ejercicio'
            />
          ) : (
            <p>{form?.exerciseType === EXERCISE_TYPE.REPS ? 'Repeticiones' : 'Tiempo'}</p>
          )}
        </FormField>

        <FormField
          labelTitle='Descripción'
          elementId='exercise-description'
          fullWidth
          error={validationErrors?.description}
          required={isCreating || isEditing}
        >
          {isEditing || isCreating ? (
            <InputTextarea
              id='exercise-description'
              className={classNames({
                'p-invalid': validationErrors?.description,
              })}
              placeholder={`Escribe la descripción del ejercicio`}
              autoResize
              value={form?.description ?? ''}
              onChange={(e: any) =>
                setFormFields({
                  description: e.target.value,
                })
              }
              rows={3}
            />
          ) : (
            <p>{form?.description}</p>
          )}
        </FormField>

        <FormField
          labelTitle='Músculos implicados'
          elementId='exercise-muscles'
          fullWidth
          error={validationErrors?.muscleGroups}
          required={isCreating || isEditing}
        >
          {isEditing || isCreating ? (
            <MultiSelect
              filter
              value={Array.from(form?.muscleGroups ?? [])}
              className={classNames({
                'p-invalid': validationErrors?.muscleGroups,
              })}
              options={MUSCLE_GROUP_OPTIONS}
              onChange={(e) =>
                setFormFields({
                  muscleGroups: new Set(e.value),
                })
              }
              emptyMessage='No hay músculos'
              emptyFilterMessage='No se han encontrado músculos'
            />
          ) : (
            <p>
              {Array.from(form?.muscleGroups ?? [])
                .map(capitalize)
                .join(', ')}
            </p>
          )}
        </FormField>

        <FormField labelTitle='URL del vídeo' elementId='video' error={validationErrors?.video} fullWidth>
          {(isEditing || isCreating) && (
            <InputText
              id='video'
              value={form?.video ?? ''}
              onChange={(e) => setFormFields({ video: e.target.value })}
              placeholder='Introduce la URL del vídeo (YouTube, Drive, etc.)'
              className={classNames({
                'p-invalid': validationErrors?.video,
              })}
            />
          )}
          {form?.video || isEditing || isCreating ? (
            <div
              className={classNames('preview-container', {
                placeholder: !form?.video,
              })}
            >
              {form?.video ? (
                form.video.includes('youtube') || form.video.includes('youtu.be') ? (
                  <iframe
                    className='preview-video'
                    src={getYoutubeEmbedUrl(form.video)}
                    title='Video del ejercicio'
                    frameBorder='0'
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                    allowFullScreen
                  />
                ) : (
                  <video className='preview-video' controls>
                    <source src={form.video} type='video/mp4' />
                    Tu navegador no soporta el elemento de vídeo.
                  </video>
                )
              ) : (
                <p>No hay vídeo asociado</p>
              )}
            </div>
          ) : (
            <p>No hay vídeo asociado</p>
          )}
        </FormField>

        <FormField labelTitle='Imagen' elementId='image' error={validationErrors?.image} fullWidth>
          {(isEditing || isCreating) && (
            <FileUpload
              chooseLabel='Subir imagen'
              mode='basic'
              accept='image/*'
              value={form?.image}
              invalid={!!validationErrors?.image}
              onImageUpload={handleImageUpload}
            />
          )}
          {form?.image || isEditing || isCreating ? (
            <div
              className={classNames('preview-container', {
                placeholder: !form?.image,
              })}
            >
              {form?.image ? (
                <Image className='preview-image' src={form.image} preview={true} alt='Vista previa del ejercicio' />
              ) : (
                <p>No hay imagen asociada</p>
              )}
            </div>
          ) : (
            <p>No hay imagen asociada</p>
          )}
        </FormField>
      </form>
    </Panel>
  );
};
