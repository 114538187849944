import React, { useMemo } from 'react';
import { Card } from 'primereact/card';
import { DataView } from 'primereact/dataview';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { useWebsiteContext } from '../../../context/WebsiteContext';
import { useClient } from '../../../hooks/useClient';
import { Skeleton } from 'primereact/skeleton';
import { PrimeIcons } from 'primereact/api';
import './BlogPosts.scss';
import { format } from 'date-fns';
import { Tag } from 'primereact/tag';
import { Post } from '../../../types/post';
import { usePageLocaleContext } from '../../../context/PageLocaleContext';
import { getLocalePost } from '../../../utils/postUtils';
import { LOCALES } from '../../../config/locales';
import { PageLocalePicker } from '../../../components/PageLocalePicker.tsx/PageLocalePicker';

const POSTS_LOADING_ARRAY = [1, 2, 3, 4, 5, 6];

const filterAndSortPosts = (posts: Post[], pageLocale: LOCALES) => {
  return posts?.filter((post) => !!post.localePosts.some((_) => _.locale === pageLocale));
  /*const onSortChange = (event: DropdownProps) => {
    const { value } = event;
    setSortKey(value);
    const sorted = [...(sortedPosts as PostResponse[])].sort((a, b) => {
      let localeToUse: "es" | "en" | undefined = undefined;

      if (localeKey) {
        localeToUse = localeKey;
      } else {
        website?.locales.forEach((locale) => {
          if (
            (a[locale as "en" | "es"] && !localeToUse) ||
            (locale === website.locales[0] && a[locale as "en" | "es"])
          ) {
            localeToUse = locale as "es" | "en";
          }
        });
      }
      if (value === "title")
        return a[localeToUse as "es" | "en"].title.localeCompare(
          b[localeToUse as "es" | "en"].title
        );
      return a.creationDate < b.creationDate ? 1 : -1;
    });
    setSortedPosts(sorted);
  };*/
};

export const BlogPosts = () => {
  // const [sortKey, setSortKey] = useState(null);
  const { pageLocale, setPageLocale } = usePageLocaleContext() ?? {};
  const navigate = useNavigate();
  const { get } = useClient();
  const { website, websitesLoading } = useWebsiteContext() ?? {};

  const { data, isLoading, error } = useSWR(website ? `/websites/${website?.id}/posts` : null, get<Post[]>);
  const posts = useMemo(() => {
    if (!data) return POSTS_LOADING_ARRAY;
    return pageLocale ? filterAndSortPosts(data, pageLocale) : data;
  }, [data, pageLocale]);

  const renderGridItem = (post: Post) => {
    const localeToUse =
      pageLocale ??
      website?.locales.find((locale) => {
        const localePost = getLocalePost(post, locale);
        return localePost || locale === website.locales[0];
      });

    if (!localeToUse) return null;
    const postToUse = getLocalePost(post, localeToUse);
    if (!postToUse) return null;

    const { title, description, image, readTime } = postToUse;
    return (
      <div key={title} className='post-card-container'>
        <Card
          className='post-card'
          style={{ borderRadius: '10px' }}
          onClick={() => {
            navigate(`post/${post.id}`);
            if (!localeToUse) return;
            setPageLocale?.(localeToUse);
          }}
        >
          <div className='post-image'>
            <img src={image.dashboardUrl}></img>
            <div className='locale-tag-container'>
              {post.localePosts.map(({ locale }) => (
                <Tag key={locale} className='locale-tag-item' value={locale}></Tag>
              ))}
            </div>
          </div>
          <div className='post-content'>
            <h4 className='post-title'>{title}</h4>
            <p className='post-description'>{description}</p>
            <div className='post-footer'>
              <p>{readTime} min</p>
              <p>{format(new Date(post.creationDate), "d 'de' LLLL, yyyy")}</p>
            </div>
          </div>
        </Card>
      </div>
    );
  };

  const renderGridSkeleton = (index: number) => {
    return (
      <div key={index} className='post-card-container'>
        <Card className='post-card' style={{ borderRadius: '10px' }}>
          <Skeleton height='140%' className='post-image'></Skeleton>
          <div className='post-content'>
            <Skeleton width='100%' height='33px' className='mb-2 post-title'></Skeleton>
            <Skeleton width='100%' height='60px' className='post-description'></Skeleton>
            <div className='post-footer'>
              <Skeleton width='20%' height='16px'></Skeleton>
              <Skeleton width='50%' height='16px'></Skeleton>
            </div>
          </div>
        </Card>
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div className='blog-filter-header'>
        <Button outlined rounded icon={PrimeIcons.PLUS} onClick={() => navigate('post/new')}>
          Nuevo artículo
        </Button>
        {/*<Dropdown
          options={[
            { label: "Ordenar por nombre", value: "title" },
            { label: "Ordenar por Fecha", value: "date" },
          ]}
          value={sortKey}
          onChange={onSortChange}
          placeholder="Ordenar por"
        />*/}
        {website && website.locales.length > 1 && <PageLocalePicker canSelectAll />}
      </div>
    );
  };

  return (
    <DataView
      className='blog-posts-page'
      value={posts}
      layout='grid'
      emptyMessage={'No hay ningún artículo en tu blog'}
      header={renderHeader()}
      itemTemplate={isLoading || websitesLoading ? renderGridSkeleton : renderGridItem}
      paginator
      rows={6}
    />
  );
};

export default BlogPosts;
