import React from 'react';
import { PageTemplate } from '../../layout/PageTemplate/PageTemplate';

import './Exercises.scss';

import { Button } from 'primereact/button';
import { ResponsiveTable } from '../../components/ResponsiveTable/ResponsiveTable';
import { TableColumn, TableAction } from '../../types/responsiveTable';
import { Exercise, EXERCISE_TYPE } from '../../types/Fisiofit/exercise';
import { useClient } from '../../hooks/useClient';
import { useOrganizationContext } from '../../context/OrganizationContext';
import useSWR, { mutate } from 'swr';
import { PrimeIcons } from 'primereact/api';
import { ExercisePanel } from '../../components/ExercisePanel/ExercisePanel';
import { useResourcePanel } from '../../hooks/useResourcePanel';
import { showDeleteDialog } from '../../utils/dialogUtils';
import { resourceDeleter } from '../../utils/resourceMutation';
import { capitalize } from '../../utils/textUtils';

const tableHeaders: TableColumn<Exercise>[] = [
  {
    label: 'Nombre',
    accessor: 'name',
    type: 'string',
    isTitle: true,
  },
  {
    label: 'Usos en rutina',
    accessor: 'usesCount',
    type: 'number',
  },
  {
    label: 'Músculos',
    accessor: 'muscleGroups',
    type: 'dropdown',
    valueFromCallBack: (_, row) => {
      return row.muscleGroups?.map(capitalize) ?? [];
    },
  },
  {
    label: 'Tipo',
    accessor: 'exerciseType',
    type: 'tag',
    colorFromCallBack: (_, { exerciseType }) => {
      return exerciseType === EXERCISE_TYPE.REPS ? 'info' : 'secondary';
    },
    textKeyFromCallBack: (_, { exerciseType }) => {
      return exerciseType === EXERCISE_TYPE.REPS ? 'Repeticiones' : 'Tiempo';
    },
  },
];

export const Exercises = () => {
  const { get, delete: del } = useClient();
  const { organization, organizationLoading } = useOrganizationContext() ?? {};

  const { data, isLoading } = useSWR(organization ? `/organizations/${organization.id}/exercises` : null, get<Exercise[]>);
  const { isEditing, setIsEditing, panelVisible, closePanel, selectedResource, openPanel, isDeleting, setIsDeleting } =
    useResourcePanel(data);

  const handleDelete = async (id: string | undefined) => {
    if (!id || !organization?.id) return false;
    setIsDeleting(true);
    const url = `/exercises/${id}`;
    const response = await del<Exercise>(url, {
      errorMessages: {
        summary: 'No se pudo eliminar el ejercicio',
        defaultDetail: 'Hubo un error al intentar borrar el ejercicio',
        [409]: 'El ejercicio no se puede eliminar porque está siendo usado',
      },
      successMessage: {
        summary: 'Ejercicio eliminado',
        detail: 'El ejercicio se ha eliminado correctamente',
      },
      handlers: {
        defaultSuccess: () => {
          mutate(`/organizations/${organization.id}/exercises`, resourceDeleter(id));
        },
      },
    });

    setIsDeleting(false);
    return !!response;
  };

  const options: TableAction[] = [
    {
      label: 'Ver',
      onClick: (id) => {
        openPanel(false, id);
      },
    },
    {
      label: 'Editar',
      onClick: (id) => {
        openPanel(true, id);
      },
    },
    {
      label: 'Eliminar',
      onClick: (id) => {
        showDeleteDialog(() => handleDelete(id));
      },
    },
  ];

  return (
    <PageTemplate className='employees-page' title='Ejercicios'>
      <ResponsiveTable
        data={data || []}
        columns={tableHeaders}
        options={options}
        loading={organizationLoading || isLoading}
        headerOptions={
          <Button
            label='Añadir ejercicio'
            outlined
            rounded
            icon={PrimeIcons.PLUS}
            onClick={() => {
              openPanel(false);
            }}
          />
        }
        onRowClick={(row) => {
          openPanel(false, row.id);
        }}
      ></ResponsiveTable>
      <ExercisePanel
        resource={selectedResource}
        visible={panelVisible}
        onHide={closePanel}
        onIsEditingChange={setIsEditing}
        onDeleteResource={handleDelete}
        isDeleting={isDeleting}
        isEditing={isEditing}
      ></ExercisePanel>
    </PageTemplate>
  );
};
