import { confirmDialog } from 'primereact/confirmdialog';
import { PrimeIcons } from 'primereact/api';

export const showDeleteDialog = (onDelete: () => void) => {
  confirmDialog({
    message: '¿Deseas confirmar la eliminación de este elemento?',
    header: 'Confirmar eliminación',
    icon: PrimeIcons.EXCLAMATION_CIRCLE,
    acceptLabel: 'Sí, eliminar',
    rejectLabel: 'No, cancelar',
    accept: onDelete,
    acceptClassName: 'p-button-danger',
    defaultFocus: 'reject',
  });
};

export const showCancelDialog = (onCancel: () => void) => {
  confirmDialog({
    message: '¿Deseas cancelar la edición? Perderás los cambios no guardados.',
    header: 'Confirmar cancelación',
    icon: 'pi pi-exclamation-triangle',
    acceptLabel: 'Sí, cancelar',
    rejectLabel: 'No, continuar editando',
    accept: onCancel,
    acceptClassName: 'p-button p-button-text',
    rejectClassName: 'p-button-secondary p-button-text',
  });
};
