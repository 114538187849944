const css = `.time-range {
  display: flex;
  flex-direction: row;
  align-items: end;
  gap: 16px;
}
.time-range:not(.time-range:last-of-type) {
  margin-bottom: 8px;
}
.time-range .switch-text {
  margin-left: auto;
}
.time-range .switch {
  align-self: center;
  text-transform: capitalize;
  width: 140px;
  min-width: 140px;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9yZW5kZXIvcHJvamVjdC9zcmMvc3JjL2NvbXBvbmVudHMvVGltZVJhbmdlIiwic291cmNlcyI6WyJUaW1lUmFuZ2Uuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFO0VBQ0E7RUFDQTtFQUNBOztBQUNBO0VBQ0U7O0FBR0Y7RUFDRTs7QUFFRjtFQUNFO0VBQ0E7RUFDQTtFQUNBIiwic291cmNlc0NvbnRlbnQiOlsiLnRpbWUtcmFuZ2Uge1xuICBkaXNwbGF5OiBmbGV4O1xuICBmbGV4LWRpcmVjdGlvbjogcm93O1xuICBhbGlnbi1pdGVtczogZW5kO1xuICBnYXA6IDE2cHg7XG4gICY6bm90KCY6bGFzdC1vZi10eXBlKSB7XG4gICAgbWFyZ2luLWJvdHRvbTogOHB4O1xuICB9XG5cbiAgLnN3aXRjaC10ZXh0IHtcbiAgICBtYXJnaW4tbGVmdDogYXV0bztcbiAgfVxuICAuc3dpdGNoIHtcbiAgICBhbGlnbi1zZWxmOiBjZW50ZXI7XG4gICAgdGV4dC10cmFuc2Zvcm06IGNhcGl0YWxpemU7XG4gICAgd2lkdGg6IDE0MHB4O1xuICAgIG1pbi13aWR0aDogMTQwcHg7XG4gIH1cbn1cbiJdfQ== */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
