import React, { useRef, useState, useMemo, useCallback } from 'react';
import { Calendar, Views, EventProps } from 'react-big-calendar';
import { getDay, parseISO, set, isSameDay } from 'date-fns';
import { ContextMenu } from 'primereact/contextmenu';
import './Reservations.scss';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Card } from 'primereact/card';
import { PageTemplate } from '../../layout/PageTemplate/PageTemplate';
import { Button } from 'primereact/button';
import { PrimeIcons } from 'primereact/api';
import useSWR, { mutate } from 'swr';
import { Reservation } from '../../types/reservation';
import { useOrganizationContext } from '../../context/OrganizationContext';
import { useClient } from '../../hooks/useClient';
import { useAuthContext } from '../../context/AuthContext';
import { RESERVATION_COLORS, useReservationTypes } from '../../hooks/AppData/useReservationTypes';
import { ReservationPanel, ReservationEvent } from '../../components/ReservationPanel/ReservationPanel';
import { useResourcePanel } from '../../hooks/useResourcePanel';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { User } from '../../types/user';
import { EmployeeSelect } from '../../components/EmployeeSelect/EmployeeSelect';
import { Toast } from 'primereact/toast';
import { resourceDeleter } from '../../utils/resourceMutation';
import { ReservationType } from '../../types/reservationType';
import { calendarLocalizer, calendarMessages } from '../../utils/calendarUtils';
import { ReservationCalendarEvent } from '../../components/ReservationCalendarEvent/ReservationCalendarEvent';
import { SplitButton } from 'primereact/splitbutton';
import { Skeleton } from 'primereact/skeleton';
import { TimeSlot } from '../../types/reservationType';

export interface InitialHours {
  start: string;
  end: string;
}

interface CalendarRestrictions {
  minTime: Date;
  maxTime: Date;
  closedDays: number[];
  closedDates: string[];
  restHour?: TimeSlot;
}

interface RestHourEvent extends ReservationEvent {
  isRestHour: boolean;
}

type CalendarEvent = Reservation | RestHourEvent;

const parseTimeString = (timeStr: string | undefined): Date | undefined => {
  if (!timeStr) return undefined;
  const [hours, minutes] = timeStr.split(':').map(Number);
  const date = new Date(2000, 0, 1); // Usar una fecha fija
  return set(date, { hours, minutes, seconds: 0, milliseconds: 0 });
};

const getCommonRestrictions = (
  reservationTypes: ReservationType[],
  selectedBranchId: string | null,
  selectedReservationType: ReservationType | null,
): CalendarRestrictions => {
  if (!reservationTypes.length) {
    const defaultDate = new Date();
    return {
      minTime: set(defaultDate, { hours: 7, minutes: 0, seconds: 0 }),
      maxTime: set(defaultDate, { hours: 22, minutes: 0, seconds: 0 }),
      closedDays: [],
      closedDates: [],
    };
  }

  // Filtrar tipos de reserva según los filtros aplicados
  let filteredTypes = [...reservationTypes];
  if (selectedBranchId) {
    filteredTypes = filteredTypes.filter((type) => type.branchId === selectedBranchId);
  }
  if (selectedReservationType) {
    filteredTypes = filteredTypes.filter((type) => type.name === selectedReservationType.name);
  }

  // Obtener horas de apertura y cierre
  const openingHours = filteredTypes
    .map((type) => {
      const hours = type.hours;
      const weekHours = [
        hours.monday,
        hours.tuesday,
        hours.wednesday,
        hours.thursday,
        hours.friday,
        hours.saturday,
        hours.sunday,
      ];
      return weekHours.filter(Boolean).map((slot) => slot?.start);
    })
    .flat()
    .filter(Boolean)
    .map(parseTimeString)
    .filter(Boolean) as Date[];

  const closingHours = filteredTypes
    .map((type) => {
      const hours = type.hours;
      const weekHours = [
        hours.monday,
        hours.tuesday,
        hours.wednesday,
        hours.thursday,
        hours.friday,
        hours.saturday,
        hours.sunday,
      ];
      return weekHours.filter(Boolean).map((slot) => slot?.end);
    })
    .flat()
    .filter(Boolean)
    .map(parseTimeString)
    .filter(Boolean) as Date[];

  // Encontrar la hora de apertura más temprana y la de cierre más tardía
  const minTime = new Date(Math.min(...openingHours.map((d) => d.getTime())));
  const maxTime = new Date(Math.max(...closingHours.map((d) => d.getTime())));

  // Encontrar días cerrados comunes
  const closedDays = Array.from({ length: 7 }, (_, i) => i).filter((day) => {
    // Un día está cerrado si:
    // 1. Está marcado como cerrado en closedDays
    // 2. O no tiene horas definidas para ese día
    return filteredTypes.every((type) => {
      const daysClosed = type.closedDays?.map((d) => Number(d)) || [];
      const dayHours = (() => {
        switch (day) {
          case 0:
            return type.hours.sunday;
          case 1:
            return type.hours.monday;
          case 2:
            return type.hours.tuesday;
          case 3:
            return type.hours.wednesday;
          case 4:
            return type.hours.thursday;
          case 5:
            return type.hours.friday;
          case 6:
            return type.hours.saturday;
        }
      })();

      return daysClosed.includes(day) || !dayHours;
    });
  });

  // Verificar si todos los tipos tienen el mismo horario de descanso
  const restHours = filteredTypes.map((type) => type.hours.restTime).filter(Boolean);
  const restHour =
    restHours.length === filteredTypes.length &&
    restHours.every((rest) => rest?.start === restHours[0]?.start && rest?.end === restHours[0]?.end)
      ? restHours[0]
      : undefined;

  // Encontrar fechas específicas cerradas (usando closedDays temporalmente hasta que tengamos closedDates)
  const closedDates = filteredTypes
    .map((type) => type.closedDays ?? [])
    .reduce((acc: string[], dates) => {
      if (acc.length === 0) return dates;
      return acc.filter((date) => dates.includes(date));
    }, []);

  return {
    minTime,
    maxTime,
    closedDays,
    closedDates,
    restHour,
  };
};

export const Reservations = () => {
  const menu = useRef<ContextMenu>(null);
  const toast = useRef<Toast>(null);
  const { get, post } = useClient();
  const { user, userLoading } = useAuthContext() ?? {};
  const { organization, organizationLoading, orgBranches } = useOrganizationContext() ?? {};

  const [selectedReservation, setSelectedReservation] = useState<Reservation>();
  const [selectedBranchId, setSelectedBranchId] = useState<string | null>(null);
  const [selectedEmployee, setSelectedEmployee] = useState<User | null>(null);
  const [showOnlyMyReservations, setShowOnlyMyReservations] = useState(false);
  const [selectedView, setSelectedView] = useState<(typeof Views)[keyof typeof Views]>(Views.WEEK);
  const [selectedReservationType, setSelectedReservationType] = useState<ReservationType | null>(null);
  const [createButtonReservationType, setCreateButtonReservationType] = useState<ReservationType | null>(null);
  const [showFilters, setShowFilters] = useState(false);
  const [initialHours, setInitialHours] = useState<InitialHours | undefined>();
  const [initialTemporaryEvent, setInitialTemporaryEvent] = useState<ReservationEvent | undefined>();

  const { reservationTypesLoading, reservationTypeFromId, uniqueReservationTypes } = useReservationTypes();

  const {
    data,
    isLoading: reservationsLoading,
    mutate: mutateReservations,
  } = useSWR(organization ? `/organizations/${organization.id}/reservations` : null, get<Reservation[]>);
  const { isEditing, setIsEditing, panelVisible, closePanel, selectedResource, openPanel, isDeleting, setIsDeleting } =
    useResourcePanel(data);

  const createButtonItems = useMemo(() => {
    return uniqueReservationTypes.map((type) => ({
      label: type.name,
      icon: PrimeIcons.PLUS,
      command: () => {
        setCreateButtonReservationType(type);
        openPanel(false);
      },
    }));
  }, [openPanel, uniqueReservationTypes]);

  const handleCreateButtonClick = () => {
    if (!createButtonReservationType) {
      setCreateButtonReservationType(uniqueReservationTypes[0]);
    }
    openPanel(false);
  };

  const handleDelete = useCallback(
    async (id: string | undefined) => {
      // TODO cancel reservation instead of deleting
      if (!id || !organization?.id) return false;
      setIsDeleting(true);
      const url = `/reservations/${id}/cancel`;
      const response = await post<Reservation>(url, {
        errorMessages: {
          summary: 'No se pudo cancelar la reserva',
          defaultDetail: 'Hubo un error al intentar cancelar la reserva',
        },
        successMessage: {
          summary: 'Reserva cancelada',
          detail: 'La reserva se ha cancelado correctamente',
        },
        handlers: {
          defaultSuccess: () => {
            mutate(`/organizations/${organization.id}/reservations`, resourceDeleter(id));
          },
        },
      });

      setIsDeleting(false);
      return !!response;
    },
    [organization?.id, post, setIsDeleting],
  );

  const onView = useCallback(
    (reservation: Reservation | undefined) => {
      if (reservation) openPanel(false, reservation.id);
    },
    [openPanel],
  );

  const onEdit = useCallback(
    (reservation: Reservation | undefined) => {
      if (reservation) openPanel(true, reservation.id);
    },
    [openPanel],
  );

  const filteredReservations = useMemo(() => {
    if (!data) return [];

    let filtered = [...data];

    // TODO move into a single loop
    // Filtro de mis reservas
    if (showOnlyMyReservations && user) {
      filtered = filtered.filter((reservation) => reservation.user?.id === user.id);
    }

    // Filtro de sucursal
    if (selectedBranchId) {
      filtered = filtered.filter((reservation) => reservation.branch?.id === selectedBranchId);
    }

    // Filtro de empleado
    if (selectedEmployee) {
      filtered = filtered.filter((reservation) => reservation.user?.id === selectedEmployee.id);
    }

    // Filtro de tipo de reserva por nombre
    if (selectedReservationType) {
      filtered = filtered.filter((reservation) => {
        const reservationType = reservationTypeFromId(reservation.reservationTypeId);
        return reservationType?.name === selectedReservationType.name;
      });
    }

    return filtered;
  }, [data, showOnlyMyReservations, selectedBranchId, selectedEmployee, selectedReservationType, user, reservationTypeFromId]);

  const calendarRestrictions = useMemo(() => {
    return getCommonRestrictions(uniqueReservationTypes, selectedBranchId, selectedReservationType);
  }, [uniqueReservationTypes, selectedBranchId, selectedReservationType]);

  const events = useMemo(() => {
    return filteredReservations;
  }, [filteredReservations]);

  const eventPropGetter = useCallback(
    (event: CalendarEvent) => {
      if ('isRestHour' in event) {
        return {
          style: {
            backgroundColor: 'var(--surface-200)',
            border: '1px solid var(--surface-400)',
            cursor: 'not-allowed',
            pointerEvents: 'none' as const,
          },
        };
      }

      const reservation = event as Reservation;
      const reservationType = reservationTypeFromId(reservation.reservationTypeId);
      const branchIndex = orgBranches?.findIndex((b) => b.id === reservation.branch.id) ?? 0;
      const color = reservationType?.color ?? RESERVATION_COLORS[branchIndex % RESERVATION_COLORS.length];
      return {
        style: {
          backgroundColor: `var(--${color}-100)`,
          border: `1px solid var(--${color}-300)`,
        },
      };
    },
    [reservationTypeFromId, orgBranches],
  );

  const slotPropGetter = useCallback(
    (date: Date) => {
      // Comprobar si la hora está en el periodo de descanso
      if (calendarRestrictions.restHour) {
        const [restStartHour, restStartMinute] = calendarRestrictions.restHour.start.split(':').map(Number);
        const [restEndHour, restEndMinute] = calendarRestrictions.restHour.end.split(':').map(Number);

        const slotHour = date.getHours();
        const slotMinute = date.getMinutes();

        const isInRestHour =
          (slotHour > restStartHour || (slotHour === restStartHour && slotMinute >= restStartMinute)) &&
          (slotHour < restEndHour || (slotHour === restEndHour && slotMinute < restEndMinute));

        if (isInRestHour) {
          return {
            className: 'disabled-day',
            style: { backgroundColor: '#f0f0f0', color: '#ccc' },
          };
        }
      }
      return {};
    },
    [calendarRestrictions.restHour],
  );

  const dayPropGetter = useCallback(
    (date: Date) => {
      const day = getDay(date);
      const isClosedDay = calendarRestrictions.closedDays.includes(day);
      const isClosedDate = calendarRestrictions.closedDates.some((closedDate) => {
        return isSameDay(date, closedDate);
      });

      if (isClosedDay || isClosedDate) {
        return {
          className: 'disabled-day',
          style: { backgroundColor: '#f0f0f0', color: '#ccc' },
        };
      }
      return {};
    },
    [calendarRestrictions.closedDays, calendarRestrictions.closedDates],
  );

  const clearFilters = useCallback(() => {
    setSelectedBranchId(null);
    setSelectedEmployee(null);
    setShowOnlyMyReservations(false);
    setSelectedReservationType(null);
  }, []);

  const hasAppliedFilters = useMemo(
    () => selectedBranchId !== null || selectedEmployee !== null || showOnlyMyReservations || selectedReservationType !== null,
    [selectedBranchId, selectedEmployee, showOnlyMyReservations, selectedReservationType],
  );

  const handleViewChange = useCallback((view: (typeof Views)[keyof typeof Views]) => {
    setSelectedView(view);
  }, []);

  const handleClose = () => {
    setInitialHours(undefined);
    setInitialTemporaryEvent(undefined);
    closePanel();
  };

  const CalendarEventComponent = useCallback(
    (props: EventProps<CalendarEvent>) => {
      if ('isRestHour' in props.event) {
        return (
          <div style={{ height: '100%', padding: '2px' }}>
            <div style={{ height: '100%', backgroundColor: 'var(--surface-200)', padding: '4px' }}>{props.event.title}</div>
          </div>
        );
      }

      const reservation = props.event as Reservation;
      const reservationType = reservationTypeFromId(reservation.reservationTypeId);
      if (!reservationType) return null;
      return <ReservationCalendarEvent {...(props as EventProps<Reservation>)} reservationType={reservationType} />;
    },
    [reservationTypeFromId],
  );

  return (
    <PageTemplate className='reservation-page' title='Reservas'>
      <Toast ref={toast} />
      <Card
        className='reservation-card'
        header={
          <div className='reservation-header'>
            <div className='header-left'>
              {!reservationTypesLoading &&
                uniqueReservationTypes &&
                (uniqueReservationTypes.length > 1 ? (
                  <SplitButton
                    label={`Nueva reserva de ${createButtonReservationType?.name ?? uniqueReservationTypes[0]?.name}`}
                    outlined
                    rounded
                    onClick={handleCreateButtonClick}
                    model={createButtonItems}
                  />
                ) : (
                  <Button
                    label={`Nueva reserva de ${createButtonReservationType?.name ?? uniqueReservationTypes[0]?.name}`}
                    outlined
                    rounded
                    onClick={handleCreateButtonClick}
                    icon={PrimeIcons.PLUS}
                  />
                ))}
              {reservationTypesLoading && <Skeleton width='280px' height='40px' />}
            </div>
            <div className='header-right'>
              {hasAppliedFilters && (
                <Button
                  label='Limpiar filtros'
                  text
                  icon={PrimeIcons.FILTER_SLASH}
                  iconPos='right'
                  onClick={clearFilters}
                  className='clear-filters'
                  severity='secondary'
                />
              )}
              <Button
                label={(!showFilters ? 'Mostrar' : 'Esconder') + ' filtros'}
                text
                icon={PrimeIcons.FILTER}
                iconPos='right'
                onClick={() => setShowFilters(!showFilters)}
                className='show-filters-button'
              />
            </div>
          </div>
        }
      >
        <div className='filters-container' style={{ display: showFilters ? 'flex' : 'none', gap: '1rem', marginBottom: '1rem' }}>
          <Dropdown
            value={selectedBranchId}
            options={[
              { label: 'Todas las sucursales', value: null },
              ...(orgBranches?.map((branch) => ({
                label: branch.name,
                value: branch.id,
              })) || []),
            ]}
            onChange={(e) => setSelectedBranchId(e.value === null ? undefined : e.value)}
            placeholder='Seleccionar sucursal'
            className='branch-select'
          />
          <EmployeeSelect
            onChange={setSelectedEmployee}
            value={selectedEmployee}
            placeholder='Seleccionar empleado'
            className='employee-select'
            appendTo='self'
          />
          <Dropdown
            value={selectedReservationType}
            onChange={(e) => setSelectedReservationType(e.value)}
            options={uniqueReservationTypes}
            optionLabel='name'
            placeholder='Tipo de reserva'
            className='type-select'
          />
          <div className='switch-container'>
            <label htmlFor='my-reservations'>Ver mis reservas</label>
            <InputSwitch
              id='my-reservations'
              checked={showOnlyMyReservations}
              onChange={(e) => setShowOnlyMyReservations(e.value)}
            />
          </div>
        </div>
        <Calendar<CalendarEvent>
          className='reservation-calendar no-event-label'
          dayLayoutAlgorithm='no-overlap'
          localizer={calendarLocalizer}
          events={events}
          startAccessor={(event) => ('localStartDate' in event ? parseISO(event.localStartDate) : event.start)}
          endAccessor={(event) => ('localEndDate' in event ? parseISO(event.localEndDate) : event.end)}
          step={30}
          timeslots={1}
          messages={calendarMessages}
          view={selectedView}
          onView={handleViewChange}
          views={[Views.MONTH, Views.WEEK, Views.DAY]}
          max={calendarRestrictions.maxTime}
          min={calendarRestrictions.minTime}
          defaultDate={new Date()}
          scrollToTime={(() => {
            const adjustedTime = new Date(new Date().setHours(new Date().getHours() - 1, 0, 0, 0));
            return adjustedTime < calendarRestrictions.minTime ? calendarRestrictions.minTime : adjustedTime;
          })()}
          eventPropGetter={eventPropGetter}
          components={{
            event: CalendarEventComponent,
          }}
          onSelectEvent={(event, e) => {
            if (!('isRestHour' in event)) {
              onView(event);
            }
          }}
          slotPropGetter={slotPropGetter}
          dayPropGetter={dayPropGetter}
          selectable
          onSelectSlot={(slotInfo) => {
            if (!createButtonReservationType) {
              setCreateButtonReservationType(uniqueReservationTypes[0]);
            }

            const start = new Date(slotInfo.start);
            const day = getDay(start);

            // Comprobar si el día está cerrado (por día de la semana o fecha específica)
            const isClosedDay = calendarRestrictions.closedDays.includes(day);
            const isClosedDate = calendarRestrictions.closedDates.some((closedDate) => {
              return isSameDay(start, closedDate);
            });

            if (isClosedDay || isClosedDate) {
              return;
            }

            // Comprobar si la hora está en periodo de descanso
            if (calendarRestrictions.restHour) {
              const [restStartHour, restStartMinute] = calendarRestrictions.restHour.start.split(':').map(Number);
              const [restEndHour, restEndMinute] = calendarRestrictions.restHour.end.split(':').map(Number);
              const slotHour = start.getHours();
              const slotMinute = start.getMinutes();

              const isInRestHour =
                (slotHour > restStartHour || (slotHour === restStartHour && slotMinute >= restStartMinute)) &&
                (slotHour < restEndHour || (slotHour === restEndHour && slotMinute < restEndMinute));

              if (isInRestHour) {
                return;
              }
            }

            // Cuando es un click, ajustar la duración a 1 hora
            if (slotInfo.action === 'click') {
              const endTime = new Date(slotInfo.start);
              endTime.setHours(endTime.getHours() + 1);
              slotInfo.end = endTime;
            }

            start.setMilliseconds(0);
            const end = new Date(slotInfo.end);
            end.setMilliseconds(0);

            setInitialHours({ start: start.toISOString(), end: end.toISOString() });
            setInitialTemporaryEvent({
              id: 'temporary',
              title: 'Nueva reserva',
              start,
              end,
            });
            openPanel(false);
          }}
        />
      </Card>
      <ReservationPanel
        resource={selectedResource}
        visible={panelVisible}
        onHide={handleClose}
        onIsEditingChange={setIsEditing}
        onDeleteResource={handleDelete}
        clearInitialTemporaryEvent={() => setInitialTemporaryEvent(undefined)}
        isDeleting={isDeleting}
        isEditing={isEditing}
        createModeReservationTypeId={createButtonReservationType?.id}
        initialHours={initialHours}
        initialTempEventOverride={initialTemporaryEvent}
      />
    </PageTemplate>
  );
};
