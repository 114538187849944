import { FormValidators, VALIDATION_MESSAGES } from '../../hooks/useForm';
import { ExternalUserShort } from '../../types/externalUser';

const { NOT_USERS, NOT_SUBJECT, NOT_CONTENT } = VALIDATION_MESSAGES;

export interface PromotionsForm {
  users: ExternalUserShort[];
  subject: string;
  content: string;
}

export const PROMOTIONS_FORM_VALIDATORS: FormValidators<PromotionsForm> = {
  users: (form) => {
    if (!form.users || form.users.length === 0) return NOT_USERS;
  },
  subject: (form) => {
    if (!form.subject) return NOT_SUBJECT;
  },
  content: (form) => {
    if (!form.content || !form.content.length) return NOT_CONTENT;
  },
};
