import { PrimeIcons } from 'primereact/api';
import React, { useMemo } from 'react';
import { Tooltip } from 'react-tooltip';

interface Props {
  content: string;
}

export const InfoIcon = ({ content }: Props) => {
  const tooltipId = useMemo(
    () => `tooltip-${Math.random().toString(36).substring(2, 16)}`,
    [content]
  );

  return (
    <>
      <i id={tooltipId} className={PrimeIcons.QUESTION_CIRCLE} />
      <Tooltip anchorSelect={`#${tooltipId}`} content={content} />
    </>
  );
};
