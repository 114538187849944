import React, { FormEvent } from 'react';
import './Support.scss';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { useForm } from '../../hooks/useForm';
import { SUPPORT_FORM_VALIDATORS, SupportForm } from './validators';
import { FormField } from '../../components/FormField/FormField';
import { InputMask } from 'primereact/inputmask';

const INITIAL_FORM: SupportForm = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  message: '',
  services: [],
};

export const Support = () => {
  const { form, setFormFields, validationErrors, validate, isSaving, setIsSaving } = useForm<SupportForm>(
    INITIAL_FORM,
    false,
    SUPPORT_FORM_VALIDATORS,
  );

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const newErrors = validate();
    if (newErrors) return;

    try {
      setIsSaving(true);
      console.log('Form submitted:', form);
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className='support-page'>
      <div className='support-content'>
        <div className='form-section'>
          <h1>Contacta con nosotros</h1>
          <p className='subtitle'>
            Tienes alguna pregunta sobre el producto o sobre cómo escalar en nuestra plataforma? Nosotros estamos aquí para
            ayudarte. Escribenos y nos pondremos en contacto contigo lo antes posible.
          </p>

          <form onSubmit={handleSubmit} className={classNames({ 'form-loading': isSaving })}>
            <div className='form-row'>
              <FormField labelTitle='Nombre' error={validationErrors?.firstName} required fullWidth>
                <InputText
                  value={form.firstName}
                  onChange={(e) => setFormFields({ firstName: e.target.value })}
                  className={classNames({ 'p-invalid': validationErrors?.firstName })}
                  placeholder='Nombre'
                />
              </FormField>

              <FormField labelTitle='Apellido' error={validationErrors?.lastName} required fullWidth>
                <InputText
                  value={form.lastName}
                  onChange={(e) => setFormFields({ lastName: e.target.value })}
                  className={classNames({ 'p-invalid': validationErrors?.lastName })}
                  placeholder='Apellido'
                />
              </FormField>
            </div>

            <FormField labelTitle='Email' error={validationErrors?.email} fullWidth required>
              <InputText
                value={form.email}
                onChange={(e) => setFormFields({ email: e.target.value })}
                className={classNames({ 'p-invalid': validationErrors?.email })}
                placeholder='tu@correo.com'
              />
            </FormField>

            <FormField labelTitle='Teléfono' fullWidth>
              <InputMask
                id='phone'
                name='phone'
                mask='(+99) 999-999-999'
                placeholder='(+__) ___-___-___'
                value={form?.phoneNumber ?? ''}
                onChange={(e) =>
                  setFormFields({
                    phoneNumber: e.target.value ?? undefined,
                  })
                }
              />
            </FormField>

            <FormField labelTitle='Mensaje' error={validationErrors?.message} fullWidth required>
              <InputTextarea
                value={form.message}
                onChange={(e) => setFormFields({ message: e.target.value })}
                className={classNames({ 'p-invalid': validationErrors?.message })}
                rows={5}
                placeholder='Dejanos un mensaje...'
              />
            </FormField>

            <Button label='Enviar mensaje' type='submit' loading={isSaving} />
          </form>
        </div>

        <div className='contact-section'>
          <div className='contact-method'>
            <h2>Chatea con nosotros</h2>
            <p>Envíanos un correo y nos pondremos en contacto contigo lo antes posible.</p>
            <Button
              label='no.gravity.corporation@gmail.com'
              className='p-button-text'
              icon='pi pi-envelope'
              onClick={() => window.open('https://mail.google.com/mail/?view=cm&fs=1&to=no.gravity.corporation@gmail.com')}
            />
          </div>

          <div className='contact-method'>
            <h2>Llámanos</h2>
            <p>Llama a nuestro equipo de lunes a viernes de 9am a 9pm.</p>
            <Button
              label='+34 611 70 75 26'
              className='p-button-text'
              icon='pi pi-whatsapp'
              onClick={() => window.open('https://wa.me/34611707526')}
            />
            <Button
              label='+34 611 70 81 63'
              className='p-button-text'
              icon='pi pi-whatsapp'
              onClick={() => window.open('https://wa.me/34611708163')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
