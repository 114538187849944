export enum EXERCISE_TYPE {
  REPS = 'reps',
  TIME = 'time',
}

export interface Exercise {
  id: string;
  name: string;
  description: string;
  videoUrl?: string;
  imageUrl?: string;

  difficulty?: string;
  categories?: string[];
  muscleGroups?: string[];

  usesCount?: number;
  exerciseType: EXERCISE_TYPE;

  // Routine exercise fields
  weight?: number;
  sets?: number;
  repetitions?: number;
  duration?: number;

  // Refs
  organizationId: string;
}

export interface ExerciseShort {
  id: string;
  name: string;
  exerciseType: EXERCISE_TYPE;
  imageUrl?: string;
}
