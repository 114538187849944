import React, { useState, useEffect } from 'react';
import { PageTemplate } from '../../layout/PageTemplate/PageTemplate';
import { Card } from 'primereact/card';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Chart } from 'primereact/chart';
import { KpiCard } from '../../components/KpiCard/KpiCard';
import { PrimeIcons } from 'primereact/api';
import { addLocale } from 'primereact/api';
import './BusinessAnalytics.scss';
import { es } from 'date-fns/locale';
import { ChartDetail } from './components/ChartDetail';
import {
  generateMockBusinessData,
  getBusinessChartOptions,
  BusinessAnalyticsData,
  filterDataByTypeAndBranch,
} from '../../utils/chart-utils';

// Configuración del locale
addLocale('es', {
  firstDayOfWeek: 1,
  dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
  dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
  dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
  monthNames: [
    'enero',
    'febrero',
    'marzo',
    'abril',
    'mayo',
    'junio',
    'julio',
    'agosto',
    'septiembre',
    'octubre',
    'noviembre',
    'diciembre',
  ],
  monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
  today: 'Hoy',
  clear: 'Limpiar',
  dateFormat: 'mm/yy',
  weekHeader: 'Sm',
});

export const BusinessAnalytics = () => {
  const [selectedMonth, setSelectedMonth] = useState<Date>(new Date());
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedChart, setSelectedChart] = useState<string | null>(null);
  const [data, setData] = useState<BusinessAnalyticsData>(generateMockBusinessData(selectedMonth));

  useEffect(() => {
    const filteredData = filterDataByTypeAndBranch(generateMockBusinessData(selectedMonth), selectedType, selectedBranch);
    setData(filteredData);
  }, [selectedType, selectedBranch, selectedMonth]);

  const branchOptions = [
    { label: 'Todas las sucursales', value: null },
    { label: 'Sucursal A', value: 'a' },
    { label: 'Sucursal B', value: 'b' },
  ];

  const typeOptions = [
    { label: 'Todos los tipos', value: null },
    { label: 'Fisioterapia', value: 'physio' },
    { label: 'Gimnasio', value: 'gym' },
  ];

  const handleChartClick = (chartType: string) => {
    setSelectedChart(chartType);
  };

  return (
    <PageTemplate className='business-analytics-page' title='Analíticas de Negocio'>
      <div className='filters-container'>
        <Calendar
          value={selectedMonth}
          onChange={(e) => setSelectedMonth(e.value as Date)}
          view='month'
          dateFormat='MM yy'
          showIcon
          className='month-picker'
          locale='es'
        />
        <Dropdown
          value={selectedBranch}
          options={branchOptions}
          onChange={(e) => setSelectedBranch(e.value)}
          placeholder='Seleccionar sucursal'
          className='branch-select'
        />
        <Dropdown
          value={selectedType}
          options={typeOptions}
          onChange={(e) => setSelectedType(e.value)}
          placeholder='Seleccionar tipo'
          className='type-select'
        />
      </div>

      <div className='kpi-container'>
        <div className='kpi-card'>
          <KpiCard
            title='Cancelaciones'
            value={data.kpis.cancellations.value}
            previousPercentage={data.kpis.cancellations.previousPercentage}
            icon={PrimeIcons.TIMES}
            iconColor='var(--pink-500)'
            iconBkgColor='var(--pink-100)'
          />
        </div>
        <div className='kpi-card'>
          <KpiCard
            title='Asistencias'
            value={data.kpis.attendance.value}
            previousPercentage={data.kpis.attendance.previousPercentage}
            icon={PrimeIcons.CHECK}
            iconColor='var(--green-500)'
            iconBkgColor='var(--green-100)'
          />
        </div>
        <div className='kpi-card'>
          <KpiCard
            title='Bajas'
            value={data.kpis.unsubscribes.value}
            previousPercentage={data.kpis.unsubscribes.previousPercentage}
            icon={PrimeIcons.USER_MINUS}
            iconColor='var(--orange-500)'
            iconBkgColor='var(--orange-100)'
          />
        </div>
        <div className='kpi-card'>
          <KpiCard
            title='Altas'
            value={data.kpis.newUsers.value}
            previousPercentage={data.kpis.newUsers.previousPercentage}
            icon={PrimeIcons.USER_PLUS}
            iconColor='var(--blue-500)'
            iconBkgColor='var(--blue-100)'
          />
        </div>
        <div className='kpi-card'>
          <KpiCard
            title='Sin rutinas'
            value={data.kpis.usersWithoutRoutines.value}
            previousPercentage={data.kpis.usersWithoutRoutines.previousPercentage}
            icon={PrimeIcons.EXCLAMATION_TRIANGLE}
            iconColor='var(--yellow-500)'
            iconBkgColor='var(--yellow-100)'
          />
        </div>
      </div>

      <div className='charts-container'>
        <Card title='Evolución de Usuarios' className='chart-card full-width' onClick={() => handleChartClick('users-evolution')}>
          <Chart type='line' data={data.charts.usersTimeSeries} options={getBusinessChartOptions('line')} />
        </Card>

        <Card title='Actividad Mensual' className='chart-card full-width' onClick={() => handleChartClick('activity')}>
          <Chart type='line' data={data.charts.combinedActivityTimeSeries} options={getBusinessChartOptions('line')} />
        </Card>

        <Card title='Evolución de Altas' className='chart-card' onClick={() => handleChartClick('new-users')}>
          <Chart type='line' data={data.charts.newUsersTimeSeries} options={getBusinessChartOptions('line')} />
        </Card>

        <Card title='Evolución de Bajas' className='chart-card' onClick={() => handleChartClick('unsubscribes')}>
          <Chart type='line' data={data.charts.unsubscribesTimeSeries} options={getBusinessChartOptions('line')} />
        </Card>

        <Card title='Motivos de Cancelación' className='chart-card' onClick={() => handleChartClick('cancellations')}>
          <Chart type='pie' data={data.charts.cancellationReasons} options={getBusinessChartOptions('pie')} />
        </Card>

        <Card title='Templates Más Usados' className='chart-card' onClick={() => handleChartClick('templates')}>
          <Chart type='bar' data={data.charts.templateUsage} options={getBusinessChartOptions('bar')} />
        </Card>
      </div>

      <ChartDetail visible={!!selectedChart} onHide={() => setSelectedChart(null)} chartType={selectedChart} data={data} />
    </PageTemplate>
  );
};
