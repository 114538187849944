import { FormValidators, VALIDATION_MESSAGES } from '../../hooks/useForm';
import { validateEmail, validateLettersAndSpaces } from '../../utils/validationUtils';

const { NOT_BRANCH, NOT_NAME, NOT_SURNAMES, NOT_GENDER, NOT_DATE_OF_BIRTH, NOT_EMAIL } = VALIDATION_MESSAGES;

export interface ExternalUserForm {
  branchId?: string;
  name?: string;
  surnames?: string;
  gender?: string;
  dateOfBirth?: string;
  email?: string;
  avatarUrl?: string;
  phone?: string;
}

export const EXTERNAL_USER_FORM_VALIDATORS: FormValidators<ExternalUserForm> = {
  branchId: (externalUser) => {
    if (!externalUser.branchId) return NOT_BRANCH;
  },
  name: (externalUser) => {
    if (!externalUser.name) return NOT_NAME;
    if (!validateLettersAndSpaces(externalUser.name)) return NOT_NAME;
  },
  surnames: (externalUser) => {
    if (!externalUser.surnames) return NOT_SURNAMES;
    if (!validateLettersAndSpaces(externalUser.surnames)) return NOT_SURNAMES;
  },
  email: (externalUser) => {
    if (!externalUser.email) return NOT_EMAIL;
    if (!validateEmail(externalUser.email)) return NOT_EMAIL;
  },
  // TODO: consent: (form) => (form.consent ? undefined : 'Debes firmar el consentimiento'),
};
