import React from 'react';
import { EventProps } from 'react-big-calendar';
import { Tooltip } from 'primereact/tooltip';
import { parseISO, format } from 'date-fns';
import { es } from 'date-fns/locale';
import { Reservation } from '../../types/reservation';
import { ReservationType } from '../../types/reservationType';
import { capitalize } from '../../utils/textUtils';
import './ReservationCalendarEvent.scss';
import { useParams } from 'react-router-dom';

interface Props extends EventProps<Reservation> {
  reservationType: ReservationType & { tagColor?: string; color?: string };
  showAttendance?: boolean;
}

const getSingleEventTitle = (reservation: Reservation) => {
  const eventExternalUser = reservation.confirmedExternalUsers?.[0] ?? reservation.unregisteredClient;
  return `${eventExternalUser?.name} ${eventExternalUser?.surnames}`;
};

const getGroupEventTitle = (reservation: Reservation, reservationType: ReservationType) => {
  return `${reservation.confirmedExternalUsers?.length ?? 0}/${reservationType.groupSize} personas`;
};

export const ReservationCalendarEvent: React.FC<Props> = ({ event: reservation, reservationType, showAttendance = false }) => {
  const { userId } = useParams();
  const tooltipId = `event-${reservation.id}`;
  const isGroup = reservationType.groupSize > 1;
  const isPast = showAttendance && new Date(reservation.localEndDate) <= new Date();

  const title = isGroup ? 'Clase grupal' : getSingleEventTitle(reservation);
  const capacity = isGroup ? getGroupEventTitle(reservation, reservationType) : null;

  const time = `${parseISO(reservation.localStartDate).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  })} - ${parseISO(reservation.localEndDate).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  })}`;

  const hasAttendanceRecords =
    showAttendance && reservation.confirmedExternalUsers?.some((user) => user.attendance !== undefined);
  const currentUser = reservation.confirmedExternalUsers?.find((user) => user.id === userId);
  const currentUserDidNotAttend = currentUser && currentUser.attendance === false;

  return (
    <div id={tooltipId} className='calendar-event-container'>
      <div className='calendar-event-inside'>
        <div className='event-header'>
          {isPast && hasAttendanceRecords && currentUserDidNotAttend && (
            <i className='pi pi-times-circle' style={{ color: 'var(--red-500)' }} />
          )}
          <div className='event-time'>{time}</div>
        </div>
        <div
          className='event-type'
          style={{
            backgroundColor: `var(--${reservationType.color || 'primary'}-500)`,
            border: `1px solid var(--${reservationType.color || 'primary'}-500)`,
            color: 'var(--surface-0)',
          }}
        >
          {capitalize(reservationType.name)}
        </div>
        <div className='event-title'>{title}</div>
        {reservation.user && <div className='event-professional'>{reservation.user.name}</div>}
        {capacity && <div className='event-capacity'>{capacity}</div>}
      </div>
      <Tooltip target={`#${tooltipId}`} position='right' showDelay={50} hideDelay={0}>
        <div className='event-tooltip'>
          <strong>{capitalize(reservationType.name)}</strong>
          <div className='event-details'>
            <div>
              <i className='pi pi-calendar' />
              <span>{format(parseISO(reservation.localStartDate), "EEEE, d 'de' MMMM", { locale: es })}</span>
            </div>
            <div>
              <i className='pi pi-clock' />
              <span>{time}</span>
            </div>
            {isGroup ? (
              <>
                <div>
                  <i className='pi pi-users' />
                  <span>Clase grupal ({capacity})</span>
                </div>
                <div>
                  <i className='pi pi-list' />
                  <span>
                    Asistentes:{' '}
                    {reservation.confirmedExternalUsers
                      ?.map((user) => {
                        const attendance =
                          showAttendance && isPast && user.attendance !== undefined
                            ? ` (${user.attendance ? 'Asistió' : 'No asistió'})`
                            : '';
                        const isCurrentUser = user.id === userId;
                        return `${user.name} ${user.surnames}${attendance}${isCurrentUser ? ' (Tú)' : ''}`;
                      })
                      .join(', ') ?? '-'}
                  </span>
                </div>
                {showAttendance && isPast && currentUser?.attendance !== undefined && (
                  <div>
                    <i
                      className={`pi ${currentUser.attendance ? 'pi-check-circle' : 'pi-times-circle'}`}
                      style={{ color: currentUser.attendance ? 'var(--green-500)' : 'var(--red-500)' }}
                    />
                    <span>Tu asistencia: {currentUser.attendance ? 'Asististe' : 'No asististe'}</span>
                  </div>
                )}
              </>
            ) : (
              <>
                <div>
                  <i className='pi pi-user' />
                  <span>{title}</span>
                </div>
                {showAttendance && isPast && currentUser?.attendance !== undefined && (
                  <div>
                    <i
                      className={`pi ${currentUser.attendance ? 'pi-check-circle' : 'pi-times-circle'}`}
                      style={{ color: currentUser.attendance ? 'var(--green-500)' : 'var(--red-500)' }}
                    />
                    <span>{currentUser.attendance ? 'Asististe' : 'No asististe'}</span>
                  </div>
                )}
              </>
            )}
            {reservation.user && (
              <div>
                <i className='pi pi-id-card' />
                <span>
                  Profesional: {reservation.user.name} {reservation.user.surnames}
                </span>
              </div>
            )}
            <div>
              <i className='pi pi-map-marker' />
              <span>{reservation.branch.name}</span>
            </div>
          </div>
        </div>
      </Tooltip>
    </div>
  );
};
