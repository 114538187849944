import React, { useMemo } from 'react';
import { MultiSelect, MultiSelectChangeEvent, MultiSelectProps } from 'primereact/multiselect';
import { ExternalUserShort } from '../../types/externalUser';
import useSWR from 'swr';
import { useClient } from '../../hooks/useClient';
import { useOrganizationContext } from '../../context/OrganizationContext';
import { getHashMap } from '../../utils/objectTransformers';
import { Dropdown, DropdownChangeEvent, DropdownProps } from 'primereact/dropdown';
import { UserDisplay } from '../UserDisplay/UserDisplay';
import { ValidationErrors } from '../../hooks/useForm';

const getExternalUsersUrl = (organizationId: string, params: { short?: boolean; forRoutine?: boolean; couponData?: boolean }) => {
  const queryParams = Object.entries(params)
    .filter(([_, value]) => value)
    .map(([key]) => `${key}=true`);

  return `/organizations/${organizationId}/external-users${queryParams.length ? '?' + queryParams.join('&') : ''}`;
};

interface UserSelectItem extends ExternalUserShort {
  label: string;
  value: string;
}

const getUserOptions = (users?: ExternalUserShort[]): UserSelectItem[] | undefined => {
  if (!users) return undefined;

  return users
    .sort((a, b) => {
      const nameA = `${a.name} ${a.surnames}`.toLowerCase();
      const nameB = `${b.name} ${b.surnames}`.toLowerCase();
      return nameA.localeCompare(nameB);
    })
    .map((user) => ({
      label: `${user.name} ${user.surnames}`,
      value: user.id,
      ...user,
    }));
};

const userTemplate = (option: UserSelectItem) => {
  return <UserDisplay fullName={option.label} avatarUrl={option.avatarUrl} />;
};

interface BaseProps {
  onChange: ((externalUsers: ExternalUserShort[]) => void) | ((externalUser: ExternalUserShort) => void);
  value?: string[] | string;
  id?: string;
  error?: string | (ValidationErrors<ExternalUserShort> | undefined)[];
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  searchPlaceholder?: string;
  isSingle: boolean;
  excludedUserIds?: string[];
  filterAvailableForNewRoutine?: boolean;
  withCouponData?: boolean;
}

interface MultiProps extends BaseProps {
  isSingle: false;
  onChange: (externalUsers: ExternalUserShort[]) => void;
  value?: string[];
}
interface SingleSelectProps extends BaseProps {
  isSingle: true;
  onChange: (externalUser: ExternalUserShort) => void;
  value?: string;
}

type Props = MultiProps | SingleSelectProps;

export const ExternalUsersSelect = ({
  onChange,
  id = 'users-dropdown',
  value,
  error,
  className,
  placeholder = 'Elige usuarios',
  searchPlaceholder = 'Busca usuarios',
  isSingle,
  filterAvailableForNewRoutine = false,
  disabled = false,
  excludedUserIds,
  withCouponData,
}: Props) => {
  const { get } = useClient();
  const { organization, organizationLoading } = useOrganizationContext() ?? {};

  const { data: externalUsers, isLoading: externalUsersLoading } = useSWR(
    organization
      ? getExternalUsersUrl(organization.id, {
          short: true,
          forRoutine: filterAvailableForNewRoutine,
          couponData: withCouponData,
        })
      : null,
    get<ExternalUserShort[]>,
  );

  const filteredExternalUsers = useMemo(() => {
    if (!externalUsers) return;
    return externalUsers.filter((user) => !excludedUserIds?.includes(user.id));
  }, [externalUsers, excludedUserIds]);

  const options = useMemo(() => getUserOptions(filteredExternalUsers), [filteredExternalUsers]);

  const usersHashMap = useMemo(() => getHashMap(filteredExternalUsers ?? [], 'id'), [filteredExternalUsers]);

  const handleMultiChange = (e: MultiSelectChangeEvent) => {
    if (isSingle) return;
    const newExternalUsers: ExternalUserShort[] = e.value?.map((externalUserId: string) => usersHashMap[externalUserId]);
    onChange(newExternalUsers);
  };

  const handleSingleChange = (e: DropdownChangeEvent) => {
    if (!isSingle) return;
    const newExternalUser = usersHashMap[e.value];
    if (!newExternalUser) return;
    onChange(newExternalUser);
  };

  const commonProps: DropdownProps & MultiSelectProps = {
    filter: true,
    id,
    placeholder,
    value,
    className,
    invalid: !!error,
    options,
    itemTemplate: userTemplate,
    emptyMessage: 'No hay usuarios',
    emptyFilterMessage: 'No se han encontrado usuarios',
    filterPlaceholder: searchPlaceholder,
    loading: externalUsersLoading || organizationLoading,
    disabled,
  };

  return isSingle ? (
    <Dropdown {...commonProps} onChange={handleSingleChange} />
  ) : (
    <MultiSelect {...commonProps} onChange={handleMultiChange} />
  );
};
