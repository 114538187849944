export type HashMap<K extends string, V> = {
  [key in K]: V | undefined;
};
export const getHashMap = <K extends string, V>(array: V[], keyProperty: keyof V): HashMap<K, V> => {
  return array.reduce((map, item) => {
    const key = item[keyProperty];
    if (typeof key !== 'string' && typeof key !== 'number' && typeof key !== 'symbol') {
      throw new Error(`Property ${String(keyProperty)} must be a string, number, or symbol.`);
    }
    map[key as K] = item;
    return map;
  }, {} as HashMap<K, V>);
};
export const hashMapToArray = <K extends string, V>(hashMap: HashMap<K, V>): V[] => {
  return Object.values(hashMap).filter((value): value is V => value !== undefined);
};
