import React from 'react';
import { PageTemplate } from '../../layout/PageTemplate/PageTemplate';
import { NavElement } from '../../types/navigation';
import { Route } from 'react-router-dom';
import UsersList from './UsersList/UsersList';
import UserProfile from './UserProfile/UserProfile';

const usersRoutes: NavElement[] = [
  {
    label: 'Usuarios',
    element: <UsersList />,
    url: '/',
  },
  {
    label: 'Ver perfil',
    element: <UserProfile />,
    url: '/profile/:userId',
  },
];

export const Users = () => {
  return (
    <PageTemplate title='Usuarios' parentRoute='/users' routes={usersRoutes} withPageLocaleContext>
      {usersRoutes.map(({ element, url }) => (
        <Route key={url} path={url} element={element} />
      ))}
    </PageTemplate>
  );
};
