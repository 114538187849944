import React from 'react';
import './CouponDisplay.scss';

import { Tag } from 'primereact/tag';
import { UserCoupon } from '../../types/coupon';
import { classNames } from 'primereact/utils';

interface Props {
  coupon: UserCoupon;
  className?: string;
}

export const CouponDisplay = ({ coupon, className }: Props) => {
  return (
    <div className={classNames('coupon-display', className)}>
      <Tag
        {...(coupon.usesRemaining === 0 ? (coupon.expired ? { severity: 'danger' } : { severity: 'warning' }) : {})}
        value={
          <>
            <i className='pi pi-money-bill' />
            {coupon.name}
          </>
        }
      />
      <small
        className={classNames('note', {
          warning: coupon.usesRemaining === 0,
          error: coupon.expired,
        })}
      >
        {coupon.expired ? 'Bono expirado' : `${coupon.usesRemaining} de ${coupon.usageLimit} usos restantes`}
      </small>
    </div>
  );
};
