import { isWithinInterval, startOfDay } from 'date-fns';
import { TableColumn, TagColor, TagColumn } from '../../types/responsiveTable';

export const getProgressTruncatedValue = (value: number | null, truncate = false) => {
  if (value === null) return null;
  return truncate ? Math.round(value) : value;
};

export const filterData = <T extends Record<any, string>>(data: T[], columns: TableColumn<T>[], filters: Record<string, any>) => {
  return data.filter((row) =>
    columns.every((column, index: number) => {
      const filterValue: any[] = filters[`${column.accessor}-${index}`];

      if (!filterValue || filterValue.length === 0) return true;

      switch (column.type) {
        case 'dropdown': {
          const dropdownValue = (
            column.valueFromCallBack
              ? (column.valueFromCallBack(row[column.accessor], row) ?? ['-'])
              : (row[column.accessor] ?? ['-'])
          ) as string[];
          return filterValue.every((v) => dropdownValue.includes(v));
        }
        case 'boolean': {
          const boolValue = column.valueFromCallBack ? column.valueFromCallBack(row[column.accessor], row) : row[column.accessor];
          if (!filterValue || filterValue.length === 0) return true;
          return filterValue.includes(boolValue);
        }
        case 'string': {
          const stringValue = column.valueFromCallBack
            ? (column.valueFromCallBack(row[column.accessor], row) ?? '-')
            : (row[column.accessor] ?? '-');
          return filterValue.every((filterVal: string) =>
            stringValue?.toString().toLowerCase().includes(filterVal.toLowerCase()),
          );
        }
        case 'tag': {
          return filterValue.includes(
            column.textKeyFromCallBack ? column.textKeyFromCallBack(row[column.accessor], row) : (row[column.accessor] ?? '-'),
          );
        }
        case 'number': {
          let numberValue = column.valueFromCallBack
            ? (column.valueFromCallBack(row[column.accessor], row) ?? '0')
            : (row[column.accessor] ?? '0');
          // por si acaso nos equivocamos desde el valueFromCallback y devolvemos este string
          if (numberValue === '-') {
            numberValue = '0';
          }

          return filterValue.every((filterVal: { mode: string; value: string }) => {
            if (filterVal.value === '' || filterVal.value === null) return true;
            if (filterVal.mode === 'equal') return numberValue == filterVal.value;
            if (filterVal.mode === 'greater-than') return numberValue > filterVal.value;
            if (filterVal.mode === 'less-than') return numberValue < filterVal.value;
            return true;
          });
        }
        case 'calendar': {
          const date = column.valueFromCallBack
            ? (column.valueFromCallBack(row[column.accessor], row) ?? '-')
            : (row[column.accessor] ?? '-');
          if (!filterValue.every((v: Date | null) => v !== null)) return true;
          return isWithinInterval(startOfDay(new Date(date)), {
            start: startOfDay(new Date(filterValue[0])),
            end: startOfDay(new Date(filterValue[1])),
          });
        }
        case 'progress': {
          const percentage = column.percentageValueFromCallBack
            ? column.percentageValueFromCallBack(row[column.accessor], row)
            : row[column.accessor];
          if (!filterValue || filterValue.length !== 2) return true;
          if (typeof percentage !== 'number') return false;
          const displayValue = getProgressTruncatedValue(percentage, column.truncate);

          if (displayValue === null) return false;

          return displayValue >= filterValue[0] && displayValue <= filterValue[1];
        }
        default:
          return true;
      }
    }),
  );
};

export const paginateData = (data: any[], first: number, rows: number) => {
  return data.slice(first, first + rows);
};

export interface TagFilterOption {
  label: string;
  value: string;
  color: TagColor;
}

export const getTagFilterOptions = <T extends Record<string, any>>(data: T[], column: TagColumn<T>): TagFilterOption[] => {
  const optionsMap = new Map<string, TagFilterOption>();

  data.forEach((row) => {
    const value = column.textKeyFromCallBack ? column.textKeyFromCallBack(row[column.accessor], row) : row[column.accessor];
    if (value && value !== '-') {
      optionsMap.set(value, {
        value,
        label: value,
        color: column.colorFromCallBack(row[column.accessor], row),
      });
    }
  });

  return Array.from(optionsMap.values());
};
