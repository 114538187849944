import React from 'react';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Tag } from 'primereact/tag';
import { Skeleton } from 'primereact/skeleton';
import { TableColumn, TagColumn } from '../../types/responsiveTable';
import { NumericFilter } from '../NumericFilter/NumericFilter';
import { Calendar } from 'primereact/calendar';
import { PrimeIcons } from 'primereact/api';
import { getTagFilterOptions, TagFilterOption } from '../ResponsiveTable/tableUtils';
import { Slider } from 'primereact/slider';
import { classNames } from 'primereact/utils';

const renderTagFilterItem = (option: TagFilterOption | undefined) => {
  if (!option) return <div className='p-multiselect-label-empty'>empty</div>;

  return option.value === '-' ? (
    '-'
  ) : (
    <Tag value={option.label} {...(option.color === 'primary' ? {} : { severity: option.color })} />
  );
};

const renderTagFilterSelectedItem = (data: any[], column: TagColumn<any>) => (value: string) => {
  const allOptions = getTagFilterOptions(data, column);
  const option = allOptions.find((o) => o.value === value);
  return option ? renderTagFilterItem(option) : <div className='p-multiselect-label-empty'>empty</div>;
};

interface TableFilterProps<T extends Record<string, any>> {
  columns: TableColumn<T>[];
  filters: Record<string, any>;
  data: any[];
  loading?: boolean;
  onFilterChange: (key: string, values: any[], isSliderFilters?: boolean) => void;
  isSlider?: boolean;
  showFilters: boolean;
}

export const TableFilter = <T extends Record<string, any>>({
  columns: headers,
  filters,
  data,
  loading,
  onFilterChange,
  isSlider = false,
  showFilters,
}: TableFilterProps<T>) => {
  const handleOnFilterChange = (column: TableColumn<T>, value: any, index: number) => {
    const key = `${column.accessor}-${index}`;
    let values: any[];

    switch (column.type) {
      case 'calendar':
      case 'dropdown':
      case 'tag':
      case 'boolean':
      case 'progress':
        values = value;
        break;
      default:
        values = value ? [value] : [];
    }

    onFilterChange(key, values, isSlider);
  };

  const getDropDownOptions = (column: TableColumn<T>) => {
    const dropdownOptions: string[] = [];
    data.forEach((row) => {
      const value =
        'valueFromCallBack' in column
          ? (column.valueFromCallBack?.(row[column.accessor], row) ?? ['-'])
          : (row[column.accessor] ?? ['-']);
      if (Array.isArray(value)) {
        value.forEach((v) => dropdownOptions.push(v));
      } else {
        dropdownOptions.push(value);
      }
    });
    return [...new Set(dropdownOptions)].filter((option) => option !== '-').sort();
  };

  const filtersConfig = (column: TableColumn<T>, index: number) => {
    const filterValue = filters[`${column.accessor}-${index}`];
    return (
      <>
        {column.type === 'string' && (
          <InputText value={filterValue || ''} onChange={(e) => handleOnFilterChange(column, e.target.value, index)} />
        )}
        {column.type === 'boolean' && (
          <MultiSelect
            value={filterValue || []}
            options={[
              { label: column.trueText || 'Sí', value: true },
              { label: column.falseText || 'No', value: false },
              { label: 'No aplica', value: null },
            ]}
            onChange={(e) => handleOnFilterChange(column, e.value, index)}
            itemTemplate={(option) => (
              <span className={`boolean-value ${option.value === true ? 'true' : option.value === false ? 'false' : 'na'}`}>
                <i
                  className={`pi ${
                    option.value === true
                      ? column.trueIcon || PrimeIcons.CHECK_CIRCLE
                      : option.value === false
                        ? column.falseIcon || PrimeIcons.TIMES_CIRCLE
                        : PrimeIcons.MINUS_CIRCLE
                  }`}
                ></i>
                <span className='boolean-text'>{option.label}</span>
              </span>
            )}
          />
        )}
        {column.type === 'tag' && (
          <MultiSelect
            className='tag-multiselect'
            value={filterValue || []}
            options={getTagFilterOptions(data, column)}
            itemTemplate={renderTagFilterItem}
            selectedItemTemplate={renderTagFilterSelectedItem(data, column)}
            onChange={(e) => handleOnFilterChange(column, e.value, index)}
          />
        )}
        {column.type === 'dropdown' && (
          <MultiSelect
            value={filterValue || []}
            options={getDropDownOptions(column)}
            itemTemplate={(option) => (option[0] === '-' ? '-' : option.toString())}
            onChange={(e) => handleOnFilterChange(column, e.value, index)}
            showSelectAll
            filter
            filterPlaceholder='Buscar...'
          />
        )}
        {column.type === 'number' && (
          <NumericFilter value={filterValue || []} onChange={(value) => handleOnFilterChange(column, value, index)} />
        )}
        {column.type === 'calendar' && (
          <Calendar
            value={filterValue || ''}
            onChange={(e) => e.value && e.value.length === 2 && handleOnFilterChange(column, e.value, index)}
            selectionMode='range'
            readOnlyInput
            showIcon
            showButtonBar
            todayButtonClassName='p-button-secondary'
            clearButtonClassName='p-button-secondary'
            maxDate={new Date()}
            dateFormat='dd/mm/yy'
            showTime={false}
          />
        )}
        {column.type === 'progress' && (
          <div className='progress-filter'>
            <Slider
              value={filterValue}
              onChange={(e) => {
                const [min, max] = e.value as number[];
                if (min <= max) {
                  handleOnFilterChange(column, e.value, index);
                }
              }}
              range
              min={0}
              max={100}
              step={1}
            />
            <div className={classNames('progress-filter-values', { inactive: !filterValue?.[0] && !filterValue?.[1] })}>
              <small>{filterValue?.[0] || '0'}%</small>
              <small>{filterValue?.[1] || '100'}%</small>
            </div>
          </div>
        )}
      </>
    );
  };

  const getFilters = (header: TableColumn<T>, index: number) => (
    <div className='table-header' key={header.label}>
      <div className='header-label'>{header.label}</div>
      {showFilters && filtersConfig(header, index)}
    </div>
  );

  return loading
    ? Array.from({ length: 4 }).map((_, colIndex) => (
        <th key={colIndex}>
          <Skeleton width='70%' height='1.5rem' />
        </th>
      ))
    : headers.map((header, index) =>
        isSlider ? (
          getFilters(header, index)
        ) : (
          <th className={header.type} key={header.label}>
            {getFilters(header, index)}
          </th>
        ),
      );
};
