import { Capacitor } from '@capacitor/core';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

// Solo importamos Capacitor si estamos en nativo
const isNative = Capacitor.isNativePlatform();
let PushNotifications: any;
let LocalNotifications: any;

if (isNative) {
  import('@capacitor/push-notifications').then((module) => {
    PushNotifications = module.PushNotifications;
  });
  import('@capacitor/local-notifications').then((module) => {
    LocalNotifications = module.LocalNotifications;
  });
}

// Configuración de Firebase
const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
};

class NotificationService {
  private isNative = Capacitor.isNativePlatform();
  private hasPermission = false;
  private firebaseApp;
  private messaging: any;

  constructor() {
    try {
      this.firebaseApp = initializeApp(firebaseConfig);
      if (!this.isNative) {
        this.messaging = getMessaging(this.firebaseApp);
      }
    } catch (error) {
      console.error('Error initializing Firebase:', error);
    }
  }

  async initialize(): Promise<string | null> {
    return this.isNative ? this.initializeNative() : this.initializeWeb();
  }

  private async initializeNative(): Promise<string | null> {
    if (!PushNotifications) {
      console.error('PushNotifications no está disponible');
      return null;
    }

    try {
      const permissionStatus = await PushNotifications.checkPermissions();

      if (permissionStatus.receive === 'prompt') {
        const permission = await PushNotifications.requestPermissions();
        if (permission.receive !== 'granted') {
          return null;
        }
      } else if (permissionStatus.receive !== 'granted') {
        return null;
      }

      this.hasPermission = true;
      await PushNotifications.register();

      return new Promise((resolve) => {
        const registrationListener = PushNotifications.addListener('registration', (token: any) => {
          console.log('FCM registration success:', token.value);
          registrationListener.remove();
          resolve(token.value);
        });

        // Configurar otros listeners
        PushNotifications.addListener('pushNotificationReceived', (notification: any) => this.handleNotification(notification));

        PushNotifications.addListener('pushNotificationActionPerformed', (notification: any) =>
          this.handleNotificationAction(notification),
        );

        // Timeout después de 5 segundos si no recibimos el token
        setTimeout(() => {
          registrationListener.remove();
          resolve(null);
        }, 5000);
      });
    } catch (error) {
      console.error('Error initializing push notifications:', error);
      return null;
    }
  }

  private async initializeWeb(): Promise<string | null> {
    try {
      if (!('Notification' in window)) {
        console.log('Este navegador no soporta notificaciones');
        return null;
      }

      const permission = await Notification.requestPermission();
      if (permission !== 'granted') {
        return null;
      }

      this.hasPermission = true;

      if (!('serviceWorker' in navigator)) {
        console.log('Este navegador no soporta Service Workers');
        return null;
      }

      try {
        // Registrar el service worker
        const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js', {
          scope: '/',
        });

        // Esperar a que el service worker esté activo
        if (registration.installing) {
          console.log('Service Worker instalando...');
          await new Promise<void>((resolve) => {
            registration.installing?.addEventListener('statechange', (e) => {
              if ((e.target as ServiceWorker).state === 'activated') {
                resolve();
              }
            });
          });
        }

        // Asegurarse de que el service worker esté activo
        await navigator.serviceWorker.ready;

        // Obtener el token de FCM
        const currentToken = await getToken(this.messaging, {
          vapidKey: process.env.FIREBASE_VAPID_KEY,
          serviceWorkerRegistration: registration,
        });

        if (currentToken) {
          console.log('FCM Token:', currentToken);

          // Configurar el manejador de mensajes en primer plano
          onMessage(this.messaging, (payload) => {
            this.handleNotification(payload);
          });

          return currentToken;
        }
        console.log('No se pudo obtener el token de FCM');
        return null;
      } catch (error) {
        console.error('Error al obtener el token de FCM:', error);
        return null;
      }
    } catch (error) {
      console.error('Error initializing web notifications:', error);
      return null;
    }
  }

  private handleNotification(notification: any) {
    if (this.isNative && LocalNotifications) {
      LocalNotifications.schedule({
        notifications: [
          {
            title: notification.title || notification.notification?.title,
            body: notification.body || notification.notification?.body,
            id: notification.id || Date.now(),
            extra: notification.data,
          },
        ],
      });
    } else if ('Notification' in window) {
      const title = notification.notification?.title || notification.title;
      const options = {
        body: notification.notification?.body || notification.body,
        icon: '/icon.png',
        data: notification.data,
      };
      new Notification(title, options);
    }
  }

  private handleNotificationAction(actionData: any) {
    console.log('Notification action:', actionData);
  }
}

export const notificationService = new NotificationService();
