import { Dispatch, SetStateAction, useState } from 'react';

export interface UseResourcePanelValue<T extends Record<string, any>> {
  selectedResource: T | undefined;
  setSelectedResource: Dispatch<SetStateAction<T | undefined>>;
  panelVisible: boolean;
  closePanel: () => void;
  isEditing: boolean;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
  openPanel: (isEditing: boolean, resourceOrId?: T | string) => void;
  isDeleting: boolean;
  setIsDeleting: Dispatch<SetStateAction<boolean>>;
}

export const useResourcePanel = <T extends Record<string, any>>(data?: T[]): UseResourcePanelValue<T> => {
  // TODO unify into one single state but maintain the same api for consumers
  const [selectedResource, setSelectedResource] = useState<T>();
  const [panelVisible, setPanelVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const openPanel = (isEditing: boolean, resourceOrId?: T | string) => {
    setPanelVisible(true);
    setIsEditing(isEditing);
    if (typeof resourceOrId === 'string') {
      setSelectedResource(data?.find((user) => user.id === resourceOrId));
    } else {
      setSelectedResource(resourceOrId);
    }
  };

  const closePanel = () => {
    setPanelVisible(false);
  };

  return {
    selectedResource,
    setSelectedResource,
    panelVisible,
    closePanel,
    isEditing,
    setIsEditing,
    openPanel,
    isDeleting,
    setIsDeleting,
  };
};
