const css = `.table-header-options {
  display: flex;
  gap: 16px;
  align-items: center;
  flex-wrap: wrap;
}
.table-header-options .branch-filter {
  min-width: 200px;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9yZW5kZXIvcHJvamVjdC9zcmMvc3JjL3BhZ2VzL1VzZXJzL1VzZXJzTGlzdCIsInNvdXJjZXMiOlsiVXNlcnNMaXN0LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRTtFQUNBO0VBQ0E7RUFDQTs7QUFFQTtFQUNFIiwic291cmNlc0NvbnRlbnQiOlsiLnRhYmxlLWhlYWRlci1vcHRpb25zIHtcbiAgZGlzcGxheTogZmxleDtcbiAgZ2FwOiAxNnB4O1xuICBhbGlnbi1pdGVtczogY2VudGVyO1xuICBmbGV4LXdyYXA6IHdyYXA7XG5cbiAgLmJyYW5jaC1maWx0ZXIge1xuICAgIG1pbi13aWR0aDogMjAwcHg7XG4gIH1cbn1cbiJdfQ== */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
