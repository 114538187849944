import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Paginator } from 'primereact/paginator';
import './Notifications.scss';
import { Link } from 'react-router-dom';

interface Notification {
  id: number;
  type: 'joined' | 'message' | 'comment' | 'connect';
  title: string;
  message: string;
  date: Date;
  isRead: boolean;
  link: string;
  user: string;
}

export const Notifications = () => {
  const [notifications, setNotifications] = useState<Notification[]>([
    {
      id: 1,
      type: 'joined',
      title: 'New Registration: Finibus Bonorum et Malorum',
      message: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium',
      date: new Date('2024-03-20T09:30:00'),
      isRead: false,
      link: '/users/1',
      user: 'Allen Deu',
    },
    {
      id: 2,
      type: 'message',
      title: 'Darren Smith sent new message',
      message: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium',
      date: new Date('2024-03-19T15:45:00'),
      isRead: false,
      link: '/messages/1',
      user: 'Darren',
    },
    // Puedes agregar más notificaciones aquí
  ]);

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rows] = useState<number>(10);

  const eliminarNotificacion = (id: number) => {
    setNotifications((prev) => prev.filter((notif) => notif.id !== id));
  };

  const onPageChange = (event: any) => {
    setCurrentPage(event.page);
  };

  const getTypeLabel = (type: string) => {
    const types = {
      joined: 'Joined New User',
      message: 'Message',
      comment: 'Comment',
      connect: 'Connect',
    };
    return types[type as keyof typeof types];
  };

  const getTypeClass = (type: string) => {
    const classes = {
      joined: 'success',
      message: 'warning',
      comment: 'purple',
      connect: 'info',
    };
    return classes[type as keyof typeof classes];
  };

  const paginatedNotifications = notifications.slice(currentPage * rows, (currentPage + 1) * rows);

  return (
    <div className='notifications-page'>
      <h2>Notificaciones</h2>
      <div className='notifications-list'>
        {paginatedNotifications.map((notification) => (
          <div key={notification.id} className='notification-item'>
            <Button
              icon='pi pi-times'
              className='delete-button p-button-text p-button-rounded'
              onClick={() => eliminarNotificacion(notification.id)}
            />
            <div className='notification-content'>
              <div className='notification-header'>
                <span className={`type-badge ${getTypeClass(notification.type)}`}>{getTypeLabel(notification.type)}</span>
                <span className='date'>
                  {notification.date.toLocaleString('es-ES', {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: true,
                  })}
                </span>
              </div>
              <h3>{notification.title}</h3>
              <p className='message'>{notification.message}</p>
              <Link to={notification.link} className='user-link'>
                {notification.user}
              </Link>
            </div>
          </div>
        ))}
      </div>
      <Paginator first={currentPage * rows} rows={rows} totalRecords={notifications.length} onPageChange={onPageChange} />
    </div>
  );
};
