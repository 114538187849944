import React from 'react';
import './Switch.scss';

import { InputSwitch, InputSwitchProps } from 'primereact/inputswitch';
import { classNames } from 'primereact/utils';

interface Props {
  label: string;
  id: string;
  primeSwitchProps: InputSwitchProps;
  className?: string;
}

export const Switch = ({ primeSwitchProps, label, id, className }: Props) => {
  return (
    <div className={classNames('switch', className)}>
      <InputSwitch id={id} {...primeSwitchProps} />
      <label>{label}</label>
    </div>
  );
};
