const css = `.post-editor .post-image {
  margin: 16px auto;
  height: 300px;
  display: flex;
}
.post-editor .post-image img {
  max-height: 300px;
  width: 100%;
}
.post-editor .post-editor-buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9yZW5kZXIvcHJvamVjdC9zcmMvc3JjL3BhZ2VzL0Jsb2cvUG9zdEVkaXRvciIsInNvdXJjZXMiOlsiUG9zdEVkaXRvci5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNFO0VBQ0U7RUFDQTtFQUNBOztBQUNBO0VBQ0U7RUFDQTs7QUFHSjtFQUNFO0VBQ0E7RUFDQTtFQUNBIiwic291cmNlc0NvbnRlbnQiOlsiLnBvc3QtZWRpdG9yIHtcbiAgLnBvc3QtaW1hZ2Uge1xuICAgIG1hcmdpbjogMTZweCBhdXRvO1xuICAgIGhlaWdodDogMzAwcHg7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBpbWcge1xuICAgICAgbWF4LWhlaWdodDogMzAwcHg7XG4gICAgICB3aWR0aDogMTAwJTtcbiAgICB9XG4gIH1cbiAgLnBvc3QtZWRpdG9yLWJ1dHRvbnMge1xuICAgIHdpZHRoOiAxMDAlO1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAganVzdGlmeS1jb250ZW50OiBzcGFjZS1iZXR3ZWVuO1xuICAgIG1hcmdpbi10b3A6IDhweDtcbiAgfVxufVxuIl19 */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
