const css = `.switch {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 4px 0;
}
.switch label {
  color: var(--primary700);
  font-weight: 500;
}
.switch.indent-1 {
  margin-left: var(--gap);
}
.switch.indent-2 {
  margin-left: calc(2 * var(--gap));
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9yZW5kZXIvcHJvamVjdC9zcmMvc3JjL2NvbXBvbmVudHMvU3dpdGNoIiwic291cmNlcyI6WyJTd2l0Y2guc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFO0VBQ0E7RUFDQTtFQUNBOztBQUNBO0VBQ0U7RUFDQTs7QUFHRjtFQUNFOztBQUVGO0VBQ0UiLCJzb3VyY2VzQ29udGVudCI6WyIuc3dpdGNoIHtcbiAgZGlzcGxheTogZmxleDtcbiAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAgZ2FwOiA4cHg7XG4gIG1hcmdpbjogNHB4IDA7XG4gIGxhYmVsIHtcbiAgICBjb2xvcjogdmFyKC0tcHJpbWFyeTcwMCk7XG4gICAgZm9udC13ZWlnaHQ6IDUwMDtcbiAgfVxuXG4gICYuaW5kZW50LTEge1xuICAgIG1hcmdpbi1sZWZ0OiB2YXIoLS1nYXApO1xuICB9XG4gICYuaW5kZW50LTIge1xuICAgIG1hcmdpbi1sZWZ0OiBjYWxjKDIgKiB2YXIoLS1nYXApKTtcbiAgfVxufVxuIl19 */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
