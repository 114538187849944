import { ChartData, ChartOptions } from 'chart.js';
import { DimensionWithMetric, UsersOverTime } from '../types/analytics';
import { format, getDaysInMonth, setDate, startOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';

const CHART_COLORS = ['#6366f1', '#7073e9', '#8c90df', '#a5a8d5', '#c1c4e3', '#d8daf2', '#f0f1fa'];

const getPieColors = () => {
  const documentStyle = getComputedStyle(document.documentElement);

  return [
    documentStyle.getPropertyValue('--primary-400'),
    documentStyle.getPropertyValue('--green-300'),
    documentStyle.getPropertyValue('--yellow-300'),
    documentStyle.getPropertyValue('--blue-300'),
    documentStyle.getPropertyValue('--orange-300'),
    documentStyle.getPropertyValue('--cyan-300'),
    documentStyle.getPropertyValue('--red-300'),
  ];
};

const getColors = () => {
  const documentStyle = getComputedStyle(document.documentElement);
  const textColor = documentStyle.getPropertyValue('--text-color');
  const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
  const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

  return { textColor, textColorSecondary, surfaceBorder };
};

export const lineAggregatedData = (data: UsersOverTime[]) => {
  const { surfaceBorder, textColorSecondary } = getColors();

  // TODO refactorizar y hacer en un solo loop
  const chartData: ChartData = {
    labels: data.map((item) => item.date),
    datasets: [
      {
        label: '',
        data: data.map((item) => item.activeUsers),
        borderColor: CHART_COLORS[0],
        pointBorderColor: CHART_COLORS[0],
        pointBackgroundColor: CHART_COLORS[0],
        backgroundColor: 'transparent',
        pointStyle: false,
        fill: true,
        tension: 0.4,
      },
    ],
  };

  const options: ChartOptions = {
    interaction: {
      intersect: false, // Tooltip appears even when not directly over a point
      mode: 'index',
    },
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    plugins: {
      legend: {
        display: false,
        // labels: {
        //   color: textColor,
        // },
      },
    },
    scales: {
      x: {
        ticks: {
          color: textColorSecondary,
          autoSkip: true,
        },
        grid: {
          color: (ctx) => {
            const index = ctx.tick.value;
            return index % 3 === 0 ? surfaceBorder : 'transparent'; // Show grid line for every 3 ticks
          },
        },
      },
      y: {
        ticks: {
          color: textColorSecondary,
        },
        grid: {
          color: (ctx) => {
            const index = ctx.tick.value;
            return index % 3 === 0 ? surfaceBorder : 'transparent'; // Show grid line for every 3 ticks
          },
        },
      },
    },
  };

  return { chartData, chartOptions: options };
};

export const aggregatedData = (data: DimensionWithMetric[]) => {
  const { surfaceBorder, textColorSecondary } = getColors();

  // TODO refactorizar y hacer en un solo loop
  const colors = data?.map((_: any, index) => CHART_COLORS[index] ?? CHART_COLORS[0]) || [];
  const chartData = {
    labels: data?.map((item: any) => `${item.dimension} (${item.metric})`) || [],
    datasets: [
      {
        label: '',
        data: data?.map((item: any) => item.metric) || [],
        backgroundColor: colors,
        borderColor: colors,
        borderWidth: 1,
      },
    ],
  };

  const chartOptions: ChartOptions = {
    interaction: {
      intersect: false, // Tooltip appears even when not directly over a point
      mode: 'index',
    },
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    plugins: {
      legend: {
        display: false,
        // labels: {
        //   fontColor: textColor,
        // },
      },
    },
    scales: {
      x: {
        ticks: {
          color: textColorSecondary,
          font: {
            weight: 500,
          },
          callback: function (value) {
            const label = this.getLabelForValue(Number(value));
            return label?.length > 16 ? label.substring(0, 16) + '...' : label;
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          color: textColorSecondary,
        },
        grid: {
          color: surfaceBorder,
        },
      },
    },
  };

  return { chartData, chartOptions };
};

export const pieAggregatedData = (data: DimensionWithMetric[]) => {
  const { textColor } = getColors();

  const pieColors = getPieColors();

  // TODO refactorizar y hacer en un solo loop
  const colors = data?.map((_: any, index) => pieColors[index] ?? pieColors[0]) || [];
  const chartData = {
    labels: data?.map((item: any) => `${item.dimension} (${item.metric})`) || [],
    datasets: [
      {
        label: '',
        data: data?.map((item: any) => item.metric) || [],
        backgroundColor: colors,
        borderColor: colors,
        borderWidth: 1,
      },
    ],
  };

  const chartOptions: ChartOptions = {
    interaction: {
      intersect: false, // Tooltip appears even when not directly over a point
      mode: 'nearest',
    },
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          color: textColor,
        },
      },
    },
  };

  return { chartData, chartOptions };
};

export interface BusinessChartData {
  labels: string[];
  datasets: Array<{
    label: string;
    data: number[];
    borderColor: string | string[];
    backgroundColor: string | string[];
    tension?: number;
    fill?: boolean;
  }>;
}

export interface BusinessAnalyticsData {
  kpis: {
    cancellations: { value: string; previousPercentage: number };
    attendance: { value: string; previousPercentage: number };
    unsubscribes: { value: string; previousPercentage: number };
    newUsers: { value: string; previousPercentage: number };
    usersWithoutRoutines: { value: string; previousPercentage: number };
  };
  charts: {
    cancellationReasons: BusinessChartData;
    templateUsage: BusinessChartData;
    cancellationsTimeSeries: BusinessChartData;
    attendanceTimeSeries: BusinessChartData;
    usersTimeSeries: BusinessChartData;
    newUsersTimeSeries: BusinessChartData;
    unsubscribesTimeSeries: BusinessChartData;
    combinedActivityTimeSeries: BusinessChartData;
  };
}

// Función auxiliar para generar datos diarios
const generateDailyData = (date: Date, min: number, max: number) => {
  const daysInMonth = getDaysInMonth(date);
  return Array.from({ length: daysInMonth }, () => Math.floor(Math.random() * (max - min)) + min);
};

export const generateMockTimeSeriesData = (selectedDate: Date) => {
  // Generamos las etiquetas para cada día del mes
  const daysInMonth = getDaysInMonth(selectedDate);
  const monthStart = startOfMonth(selectedDate);

  const labels = Array.from({ length: daysInMonth }, (_, i) => {
    const currentDate = setDate(monthStart, i + 1);
    return format(currentDate, 'd MMM', { locale: es });
  });

  // Generamos datos base para usuarios activos
  const baseUsers = 500;
  const dailyNewUsers = generateDailyData(selectedDate, 1, 5);
  const dailyUnsubscribes = generateDailyData(selectedDate, 0, 3);

  const usersData = dailyNewUsers.reduce((acc, newUsers, index) => {
    const unsubscribes = dailyUnsubscribes[index];
    const previousUsers = index > 0 ? acc[index - 1] : baseUsers;
    return [...acc, previousUsers + newUsers - unsubscribes];
  }, [] as number[]);

  return {
    cancellationsTimeSeries: {
      labels,
      datasets: [
        {
          label: 'Cancelaciones',
          data: generateDailyData(selectedDate, 0, 3),
          borderColor: '#ef4444',
          backgroundColor: '#fee2e2',
          tension: 0.4,
          fill: true,
        },
      ],
    },
    attendanceTimeSeries: {
      labels,
      datasets: [
        {
          label: 'Asistencias',
          data: generateDailyData(selectedDate, 15, 25),
          borderColor: '#22c55e',
          backgroundColor: '#dcfce7',
          tension: 0.4,
          fill: true,
        },
      ],
    },
    newUsersTimeSeries: {
      labels,
      datasets: [
        {
          label: 'Altas',
          data: dailyNewUsers,
          borderColor: '#3b82f6',
          backgroundColor: '#dbeafe',
          tension: 0.4,
          fill: true,
        },
      ],
    },
    unsubscribesTimeSeries: {
      labels,
      datasets: [
        {
          label: 'Bajas',
          data: dailyUnsubscribes,
          borderColor: '#f97316',
          backgroundColor: '#ffedd5',
          tension: 0.4,
          fill: true,
        },
      ],
    },
    usersTimeSeries: {
      labels,
      datasets: [
        {
          label: 'Usuarios Activos',
          data: usersData,
          borderColor: '#6366f1',
          backgroundColor: '#e0e7ff',
          tension: 0.4,
          fill: true,
        },
      ],
    },
    combinedUsersTimeSeries: {
      labels,
      datasets: [
        {
          label: 'Altas',
          data: dailyNewUsers,
          borderColor: '#3b82f6',
          backgroundColor: 'transparent',
          tension: 0.4,
        },
        {
          label: 'Bajas',
          data: dailyUnsubscribes,
          borderColor: '#f97316',
          backgroundColor: 'transparent',
          tension: 0.4,
        },
      ],
    },
    combinedActivityTimeSeries: {
      labels,
      datasets: [
        {
          label: 'Asistencias',
          data: generateDailyData(selectedDate, 15, 25),
          borderColor: '#22c55e',
          backgroundColor: 'transparent',
          tension: 0.4,
        },
        {
          label: 'Cancelaciones',
          data: generateDailyData(selectedDate, 0, 3),
          borderColor: '#ef4444',
          backgroundColor: 'transparent',
          tension: 0.4,
        },
      ],
    },
  };
};

export const generateMockBusinessData = (selectedDate: Date): BusinessAnalyticsData => {
  const timeSeriesData = generateMockTimeSeriesData(selectedDate);

  return {
    kpis: {
      cancellations: { value: '45', previousPercentage: -5 },
      attendance: { value: '890', previousPercentage: 12 },
      unsubscribes: { value: '8', previousPercentage: -15 },
      newUsers: { value: '34', previousPercentage: 25 },
      usersWithoutRoutines: { value: '12', previousPercentage: -30 },
    },
    charts: {
      cancellationReasons: {
        labels: ['Precio', 'Tiempo', 'Cambio de ciudad', 'Insatisfacción', 'Otros'],
        datasets: [
          {
            label: 'Motivos de Cancelación',
            data: [30, 25, 20, 15, 10],
            backgroundColor: getPieColors(),
            borderColor: getPieColors(),
          },
        ],
      },
      templateUsage: {
        labels: ['Template A', 'Template B', 'Template C', 'Template D'],
        datasets: [
          {
            label: 'Usos',
            data: [65, 45, 35, 25],
            backgroundColor: getPieColors(),
            borderColor: getPieColors(),
          },
        ],
      },
      ...timeSeriesData,
    },
  };
};

// Función para filtrar datos por tipo y sucursal
export const filterDataByTypeAndBranch = (
  data: BusinessAnalyticsData,
  type: string | null,
  branch: string | null,
): BusinessAnalyticsData => {
  const multiplier = type === 'physio' ? 0.6 : type === 'gym' ? 0.4 : 1;
  const branchMultiplier = branch ? 0.5 : 1;

  // Función auxiliar para aplicar el multiplicador solo a ciertos datos
  const applyMultiplier = (value: string | number, applyType: boolean = true): string => {
    const numValue = typeof value === 'string' ? parseInt(value) : value;
    const finalMultiplier = (applyType ? multiplier : 1) * branchMultiplier;
    return Math.round(numValue * finalMultiplier).toString();
  };

  return {
    ...data,
    kpis: {
      ...data.kpis,
      // Estos KPIs sí dependen del tipo de servicio
      cancellations: {
        value: applyMultiplier(data.kpis.cancellations.value),
        previousPercentage: data.kpis.cancellations.previousPercentage,
      },
      attendance: {
        value: applyMultiplier(data.kpis.attendance.value),
        previousPercentage: data.kpis.attendance.previousPercentage,
      },
      // Estos KPIs NO dependen del tipo de servicio
      unsubscribes: {
        value: applyMultiplier(data.kpis.unsubscribes.value, false),
        previousPercentage: data.kpis.unsubscribes.previousPercentage,
      },
      newUsers: {
        value: applyMultiplier(data.kpis.newUsers.value, false),
        previousPercentage: data.kpis.newUsers.previousPercentage,
      },
      usersWithoutRoutines: {
        value: applyMultiplier(data.kpis.usersWithoutRoutines.value, false),
        previousPercentage: data.kpis.usersWithoutRoutines.previousPercentage,
      },
    },
    charts: {
      ...data.charts,
      // Aplicar el mismo principio a las series temporales
      cancellationsTimeSeries: {
        ...data.charts.cancellationsTimeSeries,
        datasets: data.charts.cancellationsTimeSeries.datasets.map((dataset) => ({
          ...dataset,
          data: dataset.data.map((value) => parseInt(applyMultiplier(value))),
        })),
      },
      attendanceTimeSeries: {
        ...data.charts.attendanceTimeSeries,
        datasets: data.charts.attendanceTimeSeries.datasets.map((dataset) => ({
          ...dataset,
          data: dataset.data.map((value) => parseInt(applyMultiplier(value))),
        })),
      },
      // Las series de usuarios no se modifican por tipo
      usersTimeSeries: data.charts.usersTimeSeries,
      // Los datos de razones de cancelación y templates sí se modifican
      cancellationReasons: {
        ...data.charts.cancellationReasons,
        datasets: data.charts.cancellationReasons.datasets.map((dataset) => ({
          ...dataset,
          data: dataset.data.map((value) => parseInt(applyMultiplier(value))),
        })),
      },
      templateUsage: {
        ...data.charts.templateUsage,
        datasets: data.charts.templateUsage.datasets.map((dataset) => ({
          ...dataset,
          data: dataset.data.map((value) => parseInt(applyMultiplier(value))),
        })),
      },
    },
  };
};

export const getBusinessChartOptions = (type: 'line' | 'pie' | 'bar') => {
  const { textColor, textColorSecondary, surfaceBorder } = getColors();

  const baseOptions = {
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    plugins: {
      legend: {
        labels: {
          color: textColor,
        },
      },
    },
  };

  switch (type) {
    case 'line':
      return {
        ...baseOptions,
        plugins: {
          ...baseOptions.plugins,
          legend: {
            position: 'bottom',
          },
        },
        scales: {
          x: {
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              color: surfaceBorder,
            },
          },
          y: {
            beginAtZero: true,
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              color: surfaceBorder,
            },
          },
        },
      };

    case 'pie':
      return {
        ...baseOptions,
        plugins: {
          ...baseOptions.plugins,
          legend: {
            position: 'right',
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            right: 100, // Añadir padding para la leyenda
          },
        },
      };

    case 'bar':
      return {
        ...baseOptions,
        plugins: {
          ...baseOptions.plugins,
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              display: false,
            },
          },
          y: {
            beginAtZero: true,
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              color: surfaceBorder,
            },
          },
        },
      };
  }
};
