import { FormValidators, VALIDATION_MESSAGES } from '../../hooks/useForm';
import { validateEmail, validateLettersAndSpaces } from '../../utils/validationUtils';

const { NOT_NAME, NOT_EMAIL, ONLY_LETTERS_AND_SPACES, EMPTY } = VALIDATION_MESSAGES;

export interface SupportForm {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  message: string;
  services: string[];
}

export const SUPPORT_FORM_VALIDATORS: FormValidators<SupportForm> = {
  firstName: (form) => {
    if (!form.firstName?.length) return NOT_NAME;
    if (!validateLettersAndSpaces(form.firstName)) return ONLY_LETTERS_AND_SPACES;
  },
  lastName: (form) => {
    if (!form.lastName?.length) return NOT_NAME;
    if (!validateLettersAndSpaces(form.lastName)) return ONLY_LETTERS_AND_SPACES;
  },
  email: (form) => {
    if (!form.email || !validateEmail(form.email)) return NOT_EMAIL;
  },
  message: (form) => {
    if (!form.message?.length) return EMPTY;
  },
};
