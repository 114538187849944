import React, { useMemo } from 'react';
import { DropdownButton } from '../DropdownButton/DropdownButton';
import { ExternalUserShort } from '../../types/externalUser';
import { useClient } from '../../hooks/useClient';
import { PrimeIcons } from 'primereact/api';
import { useOrganizationContext } from '../../context/OrganizationContext';
import useSWR from 'swr';
import { UserDisplay } from '../UserDisplay/UserDisplay';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { getHashMap } from '../../utils/objectTransformers';

interface Props {
  onSelect: (user: ExternalUserShort) => void;
  excludedUserIds?: string[];
  disabled?: boolean;
}

interface UserSelectItem extends ExternalUserShort {
  label: string;
  value: string;
}

const getUserOptions = (users?: ExternalUserShort[]): UserSelectItem[] | undefined => {
  if (!users) return undefined;

  return users
    .sort((a, b) => {
      const nameA = `${a.name} ${a.surnames}`.toLowerCase();
      const nameB = `${b.name} ${b.surnames}`.toLowerCase();
      return nameA.localeCompare(nameB);
    })
    .map((user) => ({
      label: `${user.name} ${user.surnames}`,
      value: user.id,
      ...user,
    }));
};

const userTemplate = (option: UserSelectItem) => {
  return <UserDisplay fullName={option.label} avatarUrl={option.avatarUrl} />;
};

export const ExternalUsersDropdownButton = ({ onSelect, excludedUserIds, disabled }: Props) => {
  const { get } = useClient();
  const { organization } = useOrganizationContext() ?? {};

  const { data: externalUsers } = useSWR(
    organization ? `/organizations/${organization.id}/external-users?short=true` : null,
    get<ExternalUserShort[]>,
  );

  const filteredExternalUsers = useMemo(() => {
    if (!externalUsers) return [];
    return externalUsers.filter((user) => !excludedUserIds?.includes(user.id));
  }, [externalUsers, excludedUserIds]);

  const usersHashMap = useMemo(() => getHashMap(filteredExternalUsers ?? [], 'id'), [filteredExternalUsers]);

  const options = useMemo(() => getUserOptions(filteredExternalUsers), [filteredExternalUsers]);

  const handleChange = (e: DropdownChangeEvent) => {
    const newExternalUser = usersHashMap[e.value];
    if (!newExternalUser) return;
    onSelect(newExternalUser);
  };

  return (
    <DropdownButton
      small
      buttonProps={{
        label: 'Añadir cliente',
        icon: PrimeIcons.PLUS,
        disabled,
        text: true,
      }}
      dropdownProps={{
        options: options,
        filter: true,
        filterPlaceholder: 'Buscar cliente...',
        emptyFilterMessage: 'No se encontraron clientes',
        emptyMessage: 'No hay clientes disponibles',
        onChange: handleChange,
        itemTemplate: userTemplate,
      }}
    />
  );
};
