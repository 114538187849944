const css = `.progress-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2px;
}
.progress-container .progress-value {
  color: var(--primary500);
  font-weight: 600;
  font-size: 12px;
  justify-content: center;
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  top: -16px;
}
.progress-container .p-progressbar {
  width: 100%;
  height: 12px;
}
.progress-container.error .p-progressbar {
  background-color: var(--pink-100);
  border-color: var(--pink-500);
}
.progress-container.error .p-progressbar .p-progressbar-value {
  background: var(--pink-500);
}
.progress-container.error .p-progressbar .p-progressbar-label {
  color: var(--pink-500);
}
.progress-container.warning .p-progressbar {
  background-color: var(--orange-100);
  border-color: var(--orange-500);
}
.progress-container.warning .p-progressbar .p-progressbar-value {
  background: var(--orange-500);
}
.progress-container.warning .p-progressbar .p-progressbar-label {
  color: var(--orange-500);
}
.progress-container.success .p-progressbar {
  background-color: var(--green-100);
  border-color: var(--green-500);
}
.progress-container.success .p-progressbar .p-progressbar-value {
  background: var(--green-500);
}
.progress-container.success .p-progressbar .p-progressbar-label {
  color: var(--green-500);
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9yZW5kZXIvcHJvamVjdC9zcmMvc3JjL2NvbXBvbmVudHMvUHJvZ3Jlc3MiLCJzb3VyY2VzIjpbIlByb2dyZXNzLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBYUE7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUVBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUVGO0VBQ0U7RUFDQTs7QUFoQ0Y7RUFDRSxrQkFtQzRDO0VBbEM1QyxjQWtDMkI7O0FBakMzQjtFQUNFLFlBZ0N5Qjs7QUE5QjNCO0VBQ0UsT0E2QnlCOztBQXBDN0I7RUFDRSxrQkFzQzhDO0VBckM5QyxjQXFDMkI7O0FBcEMzQjtFQUNFLFlBbUN5Qjs7QUFqQzNCO0VBQ0UsT0FnQ3lCOztBQXZDN0I7RUFDRSxrQkF5QzZDO0VBeEM3QyxjQXdDMkI7O0FBdkMzQjtFQUNFLFlBc0N5Qjs7QUFwQzNCO0VBQ0UsT0FtQ3lCIiwic291cmNlc0NvbnRlbnQiOlsiQG1peGluIHByb2dyZXNzLXNldmVyaXR5KCRjb2xvciwgJGNvbG9yLWJnKSB7XG4gIC5wLXByb2dyZXNzYmFyIHtcbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiAkY29sb3ItYmc7XG4gICAgYm9yZGVyLWNvbG9yOiAkY29sb3I7XG4gICAgLnAtcHJvZ3Jlc3NiYXItdmFsdWUge1xuICAgICAgYmFja2dyb3VuZDogJGNvbG9yO1xuICAgIH1cbiAgICAucC1wcm9ncmVzc2Jhci1sYWJlbCB7XG4gICAgICBjb2xvcjogJGNvbG9yO1xuICAgIH1cbiAgfVxufVxuXG4ucHJvZ3Jlc3MtY29udGFpbmVyIHtcbiAgcG9zaXRpb246IHJlbGF0aXZlO1xuICBkaXNwbGF5OiBmbGV4O1xuICBmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuICBhbGlnbi1pdGVtczogY2VudGVyO1xuICBtYXJnaW4tdG9wOiAycHg7XG5cbiAgLnByb2dyZXNzLXZhbHVlIHtcbiAgICBjb2xvcjogdmFyKC0tcHJpbWFyeTUwMCk7XG4gICAgZm9udC13ZWlnaHQ6IDYwMDtcbiAgICBmb250LXNpemU6IDEycHg7XG4gICAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBwb3NpdGlvbjogYWJzb2x1dGU7XG4gICAgbGVmdDogMDtcbiAgICByaWdodDogMDtcbiAgICB0b3A6IC0xNnB4O1xuICB9XG4gIC5wLXByb2dyZXNzYmFyIHtcbiAgICB3aWR0aDogMTAwJTtcbiAgICBoZWlnaHQ6IDEycHg7XG4gIH1cblxuICAmLmVycm9yIHtcbiAgICBAaW5jbHVkZSBwcm9ncmVzcy1zZXZlcml0eSh2YXIoLS1waW5rLTUwMCksIHZhcigtLXBpbmstMTAwKSk7XG4gIH1cbiAgJi53YXJuaW5nIHtcbiAgICBAaW5jbHVkZSBwcm9ncmVzcy1zZXZlcml0eSh2YXIoLS1vcmFuZ2UtNTAwKSwgdmFyKC0tb3JhbmdlLTEwMCkpO1xuICB9XG4gICYuc3VjY2VzcyB7XG4gICAgQGluY2x1ZGUgcHJvZ3Jlc3Mtc2V2ZXJpdHkodmFyKC0tZ3JlZW4tNTAwKSwgdmFyKC0tZ3JlZW4tMTAwKSk7XG4gIH1cbn1cbiJdfQ== */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
