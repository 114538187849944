import { FormValidators, VALIDATION_MESSAGES } from '../../hooks/useForm';
import { EXERCISE_TYPE } from '../../types/Fisiofit/exercise';
import { validateLettersAndSpaces } from '../../utils/validationUtils';

const { NOT_NAME, NOT_DESCRIPTION, NOT_MUSCLE_GROUPS, NOT_EXERCISE_TYPE, ONLY_LETTERS_AND_SPACES } = VALIDATION_MESSAGES;

export interface ExerciseForm {
  name?: string;
  description?: string;
  muscleGroups?: Set<string>;
  image?: string;
  video?: string;
  exerciseType: EXERCISE_TYPE;
}

export const EXERCISE_FORM_VALIDATORS: FormValidators<ExerciseForm> = {
  name: (exercise) => {
    if (!exercise.name?.length) return NOT_NAME;
    if (!validateLettersAndSpaces(exercise.name)) return ONLY_LETTERS_AND_SPACES;
  },
  description: (exercise) => {
    if (!exercise.description?.length) return NOT_DESCRIPTION;
  },
  muscleGroups: (exercise) => {
    if (!exercise.muscleGroups?.size) return NOT_MUSCLE_GROUPS;
  },
  exerciseType: (exercise) => {
    if (!exercise.exerciseType) return NOT_EXERCISE_TYPE;
  },
};
